import { HighlightModule } from "ngx-highlight";
import { ToastrModule } from 'ngx-toastr';
import { CollapseModule, ModalModule, AccordionModule } from "ngx-bootstrap";
import { ExternalResourceComponent } from "./dependencies/components/external-resource.component";
import { SettingsComponent } from "./settings/settings.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilesComponent } from "./files/files.component";
import { DependenciesComponent } from "./dependencies/dependencies.component";
import { TreeModule } from "ng2-tree";
import { FileNamePipe } from "./filename.pipe";
import { NpmPackagesComponent } from "../project-explorer/dependencies/components/npm-packages/npm-packages.component";
import { HttpClientModule } from "@angular/common/http";
import { SearchComponent } from "./search/search.component";
import { TooltipModule } from "ngx-bootstrap";
import { FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { ImportGithubProjectComponent } from './import-github-project/import-github-project.component';
import { CollaborativeComponent } from './collaborative/collaborative.component';
@NgModule({
  imports: [
    CommonModule,
    TreeModule,
    HttpClientModule,
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary btn-min-w',
      cancelButtonClass: 'btn',
      position:'top'}),
      AccordionModule.forRoot(),
      ToastrModule.forRoot(),
  ],
  declarations: [
    FilesComponent,
    DependenciesComponent,
    SettingsComponent,
    ExternalResourceComponent,
    FileNamePipe,
    NpmPackagesComponent,
    SearchComponent,
    ImportGithubProjectComponent,
    CollaborativeComponent
  ],
  exports: [
    FilesComponent,
    DependenciesComponent,
    SettingsComponent,
    SearchComponent,
    CollaborativeComponent,
    ImportGithubProjectComponent
  ]
})
export class ProjectExplorerModule {}
