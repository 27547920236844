export const environment = {
  production: true,
  apiEndpoint: "https://codelabapi.kh3ira.com/api",
  packagerEndpoint: "https://codelabapi.kh3ira.com/v1",
  firebase: {
    apiKey: "AIzaSyCeInFCuEpbpYIX8Ktxg47UdN-6OGVvLR4",
    authDomain: "vismaya-kh.firebaseapp.com",
    databaseURL: "https://vismaya-kh.firebaseio.com",
    projectId: "vismaya-kh",
    storageBucket: "vismaya-kh.appspot.com",
    messagingSenderId: "681964168261"
  },
  iraEndpoint: "https://codelabapi.kh3ira.com/",
  socketURL: "https://run.khcodelab.com",
  mongo: "https://run.khcodelab.com",
  mysql: "https://run.khcodelab.com",
  compileUrl: "https://run.khcodelab.com",
  urlHttp: "codelab.kh3ira.com",
  serverUrl: "run.khcodelab.com",
  executionUrl: "https://javav8.knowledgehut.com/execute"
};
