import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { to } from 'await-to-js';
import * as languageMap from 'lang-map';
import _ from 'lodash';
import { TreeModel } from 'ng2-tree';
import { NgProgress } from 'ngx-progressbar';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { getIconForFile } from 'vscode-icons-js';

import { environment } from '../../environments/environment';

const TRASH_ICON = "far fa-trash-alt";
const EDIT_ICON = "fas fa-pencil-alt";
const MENU_ITMES = [
  {
    action: 1,
    name: "Create File",
    cssClass: "new-tag"
  },
  {
    action: 0,
    name: "create Directory",
    cssClass: "new-folder"
  },
  {
    action: 2,
    name: "Rename",
    cssClass: "rename"
  },
  {
    action: 4,
    name: "Delete",
    cssClass: "remove"
  },
  {
    action: 4,
    name: "Import",
    cssClass: "Import"
  }
];

@Injectable({
  providedIn: "root"
})
export class ProjectService {
  projectFileTree: TreeModel;
  project: any;
  fileid: number = 0;

  constructor(
    private http: HttpClient,
    public ngProgress: NgProgress // private socket: Socket
  ) {}

  //subject for code load to
  codeSubject = new Subject<any>();
  //subject for send Saved Code
  sendSavedSubject = new Subject<any>();
  //subject for pending changes
  pendingSubject = new Subject<any>();

  // send the initial project dependecies to depencies component
  projectDependenciesSubject = new Subject<any>();
  projectExternalResourcesSubject = new Subject<any>();

  forkSubject = new Subject<any>();

  isShowExplorer: Boolean = true;
  isPreviewShow: Boolean = true;
  isShowSideNavBar: Boolean = true;
  isShowEmbed: Boolean = true;
  isAuthor: Boolean = false;
  projectName: string;

  setExplorer(condition) {
    this.isShowExplorer = condition;
    return condition;
  }

  setPreview(condition) {
    this.isPreviewShow = condition;
    return condition;
  }

  setSideNavBar(condition) {
    this.isShowSideNavBar = condition;
    return condition;
  }

  setEmbed(condition) {
    this.isShowEmbed = condition;
    return condition;
  }

  getExplorer() {
    return this.isShowExplorer;
  }

  getPreview() {
    return this.isPreviewShow;
  }

  getSideNavBar() {
    return this.isShowSideNavBar;
  }

  getEmbed() {
    return this.isShowEmbed;
  }

  setForkSubject(data) {
    this.forkSubject.next(data);
  }

  getForkSubject() {
    return this.forkSubject.asObservable();
  }

  projectLiveSessionAlive: boolean = false;
  projectLiveSessionStatusSubject = new Subject<any>();

  joinProject(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("JOIN_PROJECT", obj, res => {});
  }

  leaveProject(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("LEAVE_PROJECT", obj, res => {});
  }

  sendEditorEvents(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("EDITOR_EVENTS", obj, res => {});
  }

  getProjectDependenciesFromSocket(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("GET_PROJECT_DEPENDENCIES", obj, res => {});
  }

  onNewProjectDependenciesFromSocket() {
    return; //this.socket.fromEvent("NEW_PROJECT_DEPENDENCIES");
  }

  onInitialProjectDependenciesFromSocket() {
    return; //this.socket.fromEvent("INITIAL_PROJECT_DEPENDENCIES");
  }

  sendNewProjectDependenciesToSocket(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("NEW_PROJECT_DEPENDENCIES", obj, res => {});
  }

  sendAuthorDetails(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("AUTHOR_DETAILS", obj, res => {});
  }

  sendProjectTreeEvents(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("PROJECT_TREE_EVENTS", obj, res => {});
  }

  sendRunOutputEventInSocket(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("CHECK_PREVIEW", obj, res => {});
  }

  sendProjectSavedEvent(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("PROJECT_SAVED", obj, res => {});
  }

  sendToGetLatestFileContent(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("GET_LATEST_FILE_CONTENT", obj, res => {});
  }

  loadProjectFilesFromSocket(obj) {
    if (!this.projectLiveSessionAlive) return;
    this.project["files"].map(file => {
      //this.socket.emit("GET_LATEST_FILE_CONTENT", { ...obj, file }, res => {});
    });
  }

  sendMessagesToLiveUsers(slug, user, message, key) {
    if (!this.projectLiveSessionAlive) return;
    let x = {
      slug: slug,
      user: user,
      message: message,
      key
    };
    //this.socket.emit("NEW_CHAT_MESSAGE", { ...x }, res => {});
  }

  getChatMessages(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("GET_CHAT_MESSAGES", obj, res => {});
  }

  getMessagesFromLiveUsers() {
    return; //this.socket.fromEvent("CHAT_MESSAGES");
  }

  onEditorEvents() {
    return; //this.socket.fromEvent("EDITOR_EVENTS");
  }

  onRunProjectPreview() {
    return; //this.socket.fromEvent("CHECK_PREVIEW");
  }

  onProjectTreeEvents() {
    return; //this.socket.fromEvent("PROJECT_TREE_EVENTS");
  }

  onFileSwitchFromSocket() {
    return; //this.socket.fromEvent("PROJECT_FILE_SWITCH");
  }

  sendFileSwitchEvent(obj) {
    if (!this.projectLiveSessionAlive) return;
    //this.socket.emit("PROJECT_FILE_SWITCH", obj, res => {});
  }

  onOnlineUsers() {
    return; //this.socket.fromEvent("ONLINE_USERS");
  }

  onAuthorDetails() {
    return; //this.socket.fromEvent("AUTHOR_DETAILS");
  }

  startLiveSession(obj) {
    this.setLiveSessionOn();
    //this.socket.emit("START_LIVE_SESSION", obj, res => {});
  }

  onStartLiveSession() {
    return; //this.socket.fromEvent("START_LIVE_SESSION");
  }

  stopLiveSession(obj) {
    this.setLiveSessionOff();
    //this.socket.emit("STOP_LIVE_SESSION", obj, res => {});
  }

  checkProjectSessionInLive(obj) {
    //this.socket.emit("CHECK_PROJECT_LIVE", obj, res => {});
  }

  onCheckProjectSessionInLive() {
    return; //this.socket.fromEvent("CHECK_PROJECT_LIVE");
  }

  setLiveSessionOn() {
    this.projectLiveSessionAlive = true;
    this.projectLiveSessionStatusSubject.next(true);
  }

  setLiveSessionOff() {
    this.projectLiveSessionAlive = false;
    this.projectLiveSessionStatusSubject.next(false);
  }

  isLiveSessionRunning() {
    return this.projectLiveSessionStatusSubject.asObservable();
  }

  onStopLiveSession() {
    return; //this.socket.fromEvent("STOP_LIVE_SESSION");
  }

  onProjectSavedFromSocket() {
    return; //this.socket.fromEvent("PROJECT_SAVED");
  }

  onGetLatestFileContent() {
    return; //this.socket.fromEvent("GET_LATEST_FILE_CONTENT");
  }

  getAllGitHubRepos() {
    let activeFireBaseUser = JSON.parse(
      localStorage.getItem("firebase-active-user")
    );
    let gitObject = {
      name: activeFireBaseUser.name,
      token: activeFireBaseUser.token
    };
    return this.http.post(
      `${environment.apiEndpoint}/githubs/getRepos`,
      gitObject
    );
  }

  getProjectsCount(filter?) {
    return this.http.get(
      `${environment.apiEndpoint}/Projects/count?where=${JSON.stringify(
        filter
      )}`
    );
  }

  getProjects(skip?, whereFilter?, limit?): Observable<any> {
    let filter = JSON.stringify({
      where: whereFilter,
      limit: limit,
      skip: skip,
      order: "updatedAt DESC",
      include: [
        { relation: "visits", scope: { fields: [] } },
        { relation: "forks", scope: { fields: [] } }
      ]
    });
    return this.http.get(`${environment.apiEndpoint}/Projects`, {
      params: { filter }
    });
  }

  visitProject(data) {
    return this.http.post(`${environment.apiEndpoint}/Visits`, data);
  }

  setCode(node) {
    let index = this.project["files"].findIndex(f => {
      if (node.parent.value != "FILES") {
        return (
          `${f.path}/${f.value}` === node.node.path + "/" + node.node.value
        );
      } else {
        return f.value === node.value;
      }
    });
    if (index >= 0) {
      this.codeSubject.next(this.project["files"][index]);
    }
  }

  getCode() {
    return this.codeSubject.asObservable();
  }

  getProjectFileTree() {
    this.sortByFileName(this.projectFileTree);
    return this.projectFileTree;
  }

  clearProjectFiles() {
    this.project["files"] = [];
  }

  sortByFileName(node) {
    if (node && node.children) {
      node.children = _.orderBy(node.children, "id");
      let temp = [];
      let onlyFiles = node.children.filter(child => {
        if (child.children) {
          temp.push(child);
          return false;
        }
        return true;
      });
      node.children = [...temp, ...onlyFiles];
      node.children.forEach(child => this.sortByFileName(child));
    }
  }

  emitSaveCodeNotification(code) {
    this.sendSavedSubject.next(code);
  }

  subscribeSaveCodeNotification() {
    return this.sendSavedSubject.asObservable();
  }

  saveCode(code, model) {
    let fileTree = this.projectFileTree.children.find(
      c => c.value === model["address"]
    );
    fileTree["code"] = code;
    fileTree["isDirty"] = false;
  }

  save(projectInfo?) {
    const userInfo = this.getLocalStorage("firebase-active-user");

    const { uid, isVismaya, isAnonymous } = userInfo;

    const headers: any = new HttpHeaders({
      uid: uid
    });
    if (projectInfo) {
      this.project["info"] = projectInfo;
    }

    // delete this.project["forks"];
    // delete this.project["visits"];
    return this.http.patch(
      `${environment.apiEndpoint}/Projects`,
      this.project,
      { headers }
    );
  }

  pendingChanges(code) {
    let findFile = this.projectFileTree.children.find(
      c => c.id === code["codeModelId"]
    );
    findFile["isDirty"] = true;
    findFile["code"] = code["code"];
    this.pendingSubject.next(this.projectFileTree);
  }

  skippedPendingChanges() {
    return this.pendingSubject.asObservable();
  }

  forkProjectFromTemplate(type: string) {
    let activeFireBaseUser = JSON.parse(
      localStorage.getItem("firebase-active-user")
    );
    return this.http.post(`${environment.apiEndpoint}/ProjectTemplates/fork`, {
      uid: `${activeFireBaseUser.uid}`,
      id: type
    });
  }

  remapProjectMeta(meta) {
    // this.project["map"] = meta["map"];
    // this.project["packages"] = meta["packages"];
    // this.project["versions"] = meta["versions"];
  }

  amendDependency(deps: any) {
    return this.http.post(
      `${environment.apiEndpoint}/Projects/depsMeta`,
      Object.assign({}, ...this.project["dependencies"])
    );
  }

  getProject(slug: string) {
    const userInfo = this.getLocalStorage("firebase-active-user");

    const { uid, isVismaya, isAnonymous } = userInfo;

    const headers = new HttpHeaders({
      uid,
      Expires: "0",
      Pragma: "no-cache",
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post- check=0, pre-check=0"
    });

    // let headers: any = new Headers({
    //   // "Cache-Control":
    //   //   "no-cache, no-store, must-revalidate, post- check=0, pre-check=0",
    //   // Pragma: "no-cache",
    //   // Expires: "0",
    //   uid: "1223335458"
    // });

    let filter = JSON.stringify({
      include: [
        { relation: "visits", scope: { fields: [] } },
        { relation: "forks", scope: { fields: [] } }
      ]
    });
    return this.http.get(`${environment.apiEndpoint}/Projects/${slug}`, {
      params: { filter },
      headers: headers
    });
  }

  getProjectDependenciesObservable() {
    return this.projectDependenciesSubject.asObservable();
  }
  getProjectExternalResourcesObservable() {
    return this.projectExternalResourcesSubject.asObservable();
  }
  setProjectDependencies(dependencies) {
    this.project["dependencies"] = dependencies;
  }

  setProjectDepsMeta(depsMeta) {
    this.project["dependencies"] = depsMeta["dependencies"];
    this.project["packages"] = depsMeta["packages"];
    this.project["map"] = depsMeta["map"];
    this.project["versions"] = depsMeta["versions"];
    this.project["peerDependencies"] = depsMeta["peerDependencies"];
    this.project["packagesUrl"] = depsMeta["packagesUrl"];
    console.log({ project: this.project });
  }

  setProjectExternalResources(externalResources) {
    this.project["externalResources"] = externalResources;
  }

  getProjectDependencies() {
    return this.project["dependencies"];
  }

  getProjectExternalResources() {
    return this.project["externalResources"] || [];
  }

  getProjectSystemJSMap() {
    return this.project["map"];
  }

  getProjectSystemJSPackages() {
    return this.project["packages"];
  }

  getProjectVersions() {
    return this.project["versions"];
  }

  reAssignProjectDeps(data) {
    this.project["dependencies"] = data["projectDependencies"];
    this.project["externalResources"] = data["projectExternalResources"];
    this.project["map"] = data["projectSystemJSMap"];
    this.project["packages"] = data["projectSystemJSPackages"];
    this.project["versions"] = data["projectVersions"];
  }

  getProjectFiles() {
    return this.project["files"];
  }

  getProjectData() {
    return this.project;
  }

  setProjectFileTree(project: object) {
    this.project = project;
    this.fileid = project["files"].length;
    this.projectFileTree = this.prepareProjectFileTree();
    this.configureFiles(project["files"], project["name"]);
    this.projectDependenciesSubject.next(project["dependencies"]);
    this.projectExternalResourcesSubject.next(project["externalResources"]);
  }

  updateCodeFile(filePath, content, activeFile?) {
    let index = this.project["files"].findIndex(
      f => `${f.path}${f.value}` === filePath
    );
    if (index >= 0) {
      this.project["files"][index].content = content;
      this.project["files"][index].updatedAt = new Date().getTime();
      this.project["files"][index].isDirty = activeFile.isDirty;
    }
  }

  getProTree = new Subject();

  ProTree$ = this.getProTree.asObservable();

  addFile(
    fileName: string,
    language: string,
    path: string,
    settings,
    content?,
    oldID?,
    oldFullPath?,
    isMoved?,
    id?
  ) {
    // console.log(this.project["files"]);

    let file: any = {};
    const isFile = settings.templates ? true : false;
    if (isFile) {
      file = {
        content: content || "",
        value: fileName,
        id:
          id ||
          this.project["files"].length +
            Math.floor(Math.min(10) * Math.max(1234)),
        language,
        settings,
        path,
        isDirty: false
      };
      this.project["files"].push(file);
    } else {
      file = {
        value: fileName,
        id:
          this.project["files"].length +
          Math.floor(Math.min(10) * Math.max(1234)),
        settings,
        path,
        isDirty: false
      };
    }
    // if(language === "nolanguage"){
    //   this.setProjectFileTree(this.project);
    //   this.getProTree.next(this.project);
    //   return;
    // }

    if (!isFile && isMoved) {
      this.project["files"].find((pFile, i) => {
        if (pFile.path === oldFullPath) {
          const newPath = this.pathJoin([path, fileName], "/");
          pFile.path = newPath;
        }
      });
    }
    // this.projectFileTree = this.prepareProjectFileTree();

    this.setProjectFileTree(this.project);
    this.getProTree.next(this.project);
  }

  reloadTreeWithoutAnyUpdate() {
    this.setProjectFileTree(this.project);
    this.getProTree.next(this.project);
  }

  removeFile(file) {
    this.ngProgress.start();
    if (file.children) {
      const filePath = this.pathJoin([file.node.path], "/");
      this.project["files"] = this.project["files"].filter(pFile => {
        const pFilePath = this.pathJoin([pFile.path], "/");
        return filePath !== pFilePath;
      });
    } else {
      const filePath = this.pathJoin([file.path, file.value], "/");
      this.project["files"] = this.project["files"].filter(pFile => {
        const pFilePath = this.pathJoin([pFile.path, pFile.value], "/");
        return filePath !== pFilePath;
      });
    }

    // if (!file.children) {
    //   let index = this.project["files"].findIndex(f => f.path + f.value == file.path + file.value);
    //   if (index > 0) {
    //     this.project["files"].splice(index, 1);
    //   }
    // } else {
    //   file.children.map(child => {
    //     console.log(child);
    //   });

    //   file.children.forEach(child => {
    //     if (child.children) {
    //       this.removeFileNodes(child);
    //     } else {
    //       let index = this.project["files"].findIndex(f => f.path + f.value == child.node.path + child.node.value);
    //       if (index > 0) {
    //         this.project["files"].splice(index, 1);
    //       }
    //     }
    //     // if(!child.children){
    //     //   this.removeFileNodes(child);
    //     // }
    //   });
    // }

    this.setProjectFileTree(this.project);
    this.getProTree.next(this.project);
    this.ngProgress.done();
  }

  removeFileNodes(file) {
    file.children.forEach(child => {
      if (child.children) {
        this.removeFileNodes(child);
      } else {
        let index = this.project["files"].findIndex(
          f => f.path + f.value == child.node.path + child.node.value
        );
        if (index > 0) {
          this.project["files"].splice(index, 1);
        }
      }
    });
  }

  renameFile(file, oldValue, newValue) {
    this.ngProgress.start();
    if (oldValue !== newValue) {
      //handled changes to reflect whenever oldvalue != newvalue
      // if we try to rename the node with children's only for path purpose

      if (file.children) {
        const oldPath = file.node.path.replace(newValue, oldValue);

        this.project["files"].map(pFile => {
          if (oldPath === pFile.path) {
            pFile.path = file.node.path;
          }
        });

        // file.children.forEach(child => {
        //   if (child.children) {
        //     // if we try to rename the node with children's only for path purpose
        //     this.renameNestedNodes(child, oldValue, newValue);
        //   } else {
        //     let index = this.project["files"].findIndex(f => f.path + f.id == child.node.path + child.node.id);
        //     let fPath = child.node.path.split("/");
        //     let fPathIndex = fPath.indexOf(oldValue);
        //     if (fPathIndex !== -1) {
        //       fPath[fPathIndex] = newValue;
        //     }
        //     this.project["files"][index].path = fPath.join("/");
        //   }
        // });
      } else {
        const index = this.project["files"].findIndex(f => f.id === file.id);

        // if (index !== 0) {
        //   this.setProjectFileTree(this.project),
        //     this.getProTree.next(this.project),
        //     this.ngProgress.done();
        //   return;
        // }

        this.project["files"][index].value = file.value;
        const fileExtension = file.value.split(".");
        if (fileExtension.length === 3) {
          if (fileExtension[2] === "js") {
            this.project["files"][index].language = "javascript";
            this.prepareSettings("javascript");
          } else if (fileExtension[2] === "ts") {
            this.project["files"][index].language = "typescript";
            this.prepareSettings("typeScript");
          } else {
            this.project["files"][index].language = fileExtension[2];
            this.prepareSettings(fileExtension[2]);
          }
        } else {
          if (fileExtension[1] === "js") {
            this.project["files"][index].language = "javascript";
            this.prepareSettings("javascript");
          } else if (fileExtension[1] === "ts") {
            this.project["files"][index].language = "typescript";
            this.prepareSettings("typeScript");
          } else {
            this.project["files"][index].language = fileExtension[1];
            this.prepareSettings(fileExtension[1]);
          }
        }
      }
    }
    this.setProjectFileTree(this.project);
    this.getProTree.next(this.project);
    this.ngProgress.done();
  }

  renameNestedNodes(file, oldValue, newValue) {
    file.children.forEach(child => {
      if (child.children) {
        this.renameNestedNodes(child, oldValue, newValue);
      } else {
        let index = this.project["files"].findIndex(
          f => f.path + f.id == child.node.path + child.node.id
        );
        let fPath = child.node.path.split("/");
        let fPathIndex = fPath.indexOf(oldValue);
        if (fPathIndex !== -1) {
          fPath[fPathIndex] = newValue;
        }
        this.project["files"][index].path = fPath.join("/");
      }
    });
  }

  getFolderSettings(isCollapsed) {
    return {
      rightMenu: true,
      isCollapsedOnInit: false,
      cssClasses: {
        expanded: "folder-open fa fa-caret-down fa-custom",
        collapsed: "folder-close fa fa-caret-right fa-custom",
        empty: "folder-close disabled fa fa-caret-right fa-custom"
      },

      selectionAllowed: true,

      menuItems: [
        {
          action: 1,
          name: "Create File",
          cssClass: "new-tag"
        },
        {
          action: 0,
          name: "Create Directory",
          cssClass: "new-folder"
        },
        {
          action: 2,
          name: "Rename",
          cssClass: "rename"
        },
        {
          action: 4,
          name: "Delete",
          cssClass: "remove"
        },
        {
          action: 4,
          name: "Import",
          cssClass: "Import"
        }
      ]
    };
  }

  /**
   * Prepare Project file tree based on the files array
   * @param files
   */
  prepareProjectFileTree() {
    return {
      value: "FILES",
      id: 0,
      settings: this.getFolderSettings(false),
      children: []
    };
  }

  /**
   * Prepare settings per tree node based on the language
   * @param language
   */
  prepareSettings(language: string, type?, file?) {
    return {
      rightMenu: true,
      leftMenu: true,
      rootIsVisible: false,
      templates: {
        leftMenu: this.getIconByLanguage(language, type, file)
      },
      menuItems: [
        {
          action: 2,
          name: "Rename",
          cssClass: "rename"
        },
        {
          action: 4,
          name: "Delete",
          cssClass: "remove"
        }
      ]
    };
  }

  async setAuthor(value) {
    this.isAuthor = value;
  }

  async configureFiles(files: any, type) {
    // for (const file of files) {
    //   file["settings"] = this.prepareSettings(file["language"], type, file);
    //   file["isDirty"] = false;
    //   this.traversePath(`${file.path}/${file.value}`.split("/"), file);
    // }
    const fileArrays = [];
    const arrayObject = Object.keys(files).map(async file => {
      const { value, language, content, id, path } = files[file];

      // if (!this.isAuthor) {
      if (
        !value.includes(".test.") &&
        !value.includes(".spec.") &&
        !value.includes("package.json")
      ) {
        const fileData = {} as any;
        let filePath = path ? path.split("/") : "/".split("/");
        filePath.push(value);
        filePath = filePath.join("/");
        filePath = filePath.replace("//", "/");
        fileData.path = filePath;
        fileData.content = content;
        fileData.language = language ? language : "txt";
        fileData.id = id;
        fileData.settings = this.prepareSettings(
          language ? language : "txt",
          type,
          file
        );
        fileArrays.push(fileData);
      }
      // } else {
      //   const fileData = {} as any;
      //   let filePath = path ? path.split("/") : "/".split("/");
      //   filePath.push(value);
      //   filePath = filePath.join("/");
      //   filePath = filePath.replace("//", "/");
      //   fileData.path = filePath;
      //   fileData.content = content;
      //   fileData.language = language;
      //   fileData.id = id;
      //   fileData.settings = this.prepareSettings(language, type, file);
      //   fileArrays.push(fileData);
      // }
    });

    await arrayObject.reduce((m, o) => m.then(() => o), Promise.resolve());

    const result = [];
    const level = { result };
    let folderId = 30000;

    fileArrays.map(file => {
      file.path.split("/").reduce((key, name, i, a) => {
        if (!key[name]) {
          key[name] = { result: [] };
          let filePath = file.path.substring(0, file.path.lastIndexOf("/") + 1);
          filePath = filePath.replace(/\/$/, "");
          if (i === file.path.split("/").length - 1) {
            const extension = name.substring(
              name.lastIndexOf(".") + 1,
              name.length
            );
            if (extension !== "empty") {
              // tslint:disable-next-line: max-line-length
              key.result.push({
                value: name,
                content: file.content,
                id: file.id,
                path: filePath,
                isDirty: false,
                language: file.language,
                settings: file.settings
              });
            }
          } else {
            folderId = folderId + 1;
            // tslint:disable-next-line: max-line-length
            key.result.push({
              value: name,
              children: key[name].result,
              id: folderId,
              settings: this.getFolderSettings(true),
              path: filePath,
              isDirty: false
            });
          }
        }
        return key[name];
      }, level);
    });

    this.projectFileTree.children = result[0] && result[0].children;
    // console.log(this.projectFileTree);
  }

  pathJoin(parts, sep) {
    const separator = sep || "/";
    const replace = new RegExp(separator + "{1,}", "g");
    return parts.join(separator).replace(replace, separator);
  }

  installPackages(packages) {
    const newPackages = Object.assign(
      {},
      ...this.project["dependencies"],
      ...packages
    );
    console.log({ newPackages });
    return this.http.post(
      `${environment.packagerEndpoint}/moduleManager/depsMeta`,
      newPackages
    );
  }

  getIconByLanguage(language, type?, file?) {
    const extension = languageMap.extensions(language)[0];
    const languageExtension = extension ? extension : "js";
    // console.log(languageMap.extensions(language)[0], "language-1");
    const icon = `<img src="../../assets/fileicons/${getIconForFile(
      languageExtension
    )}" height="16" width="16" class="file-type-icon" alt="Json">`;
    // switch (language) {
    //   case "javascript":
    //     language = "js";
    //     icon = `<img src="../../assets/fileicons/${getIconForFile(
    //       language
    //     )}" height="16" width="16" class="file-type-icon" alt="Json">`;
    //     break;
    //   case "typescript":
    //     language = "ts";
    //     icon = `<img src="../../assets/fileicons/${getIconForFile(
    //       language
    //     )}" height="16" width="16" class="file-type-icon" alt="Json">`;
    //     break;
    //   case "snap":
    //     language = "ico";
    //     icon = `<img src="../../assets/fileicons/${getIconForFile(
    //       language
    //     )}" height="16" width="16" class="file-type-icon" alt="Json">`;
    //     break;
    //   default:
    //     language = language;
    //     icon = `<img src="../../assets/fileicons/${language &&
    //       getIconForFile(
    //         language.toLowerCase()
    //       )}" height="16" width="16" class="file-type-icon" alt="Json">`;
    //     break;
    // }
    return icon;
  }
  // file-type-icon
  updateSlug(oldid: string, newid: string) {
    const formData = new FormData();
    formData.set("oldid", oldid);
    formData.set("newid", newid);
    this.project["id"] = newid;
    return this.http.post(
      `${environment.apiEndpoint}/Projects/updateslug`,
      this.toUrlEncoded({ oldid, newid }),
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
    );
  }

  forkProjectFromProject(id) {
    let activeFireBaseUser = JSON.parse(
      localStorage.getItem("firebase-active-user")
    );
    let data = {
      id: id,
      uid: activeFireBaseUser.uid
    };
    return this.http.post(`${environment.apiEndpoint}/Projects/fork`, data);
  }

  importProjectByUrl(data) {
    return this.http.post(`${environment.apiEndpoint}/github/forkRepo`, data);
  }

  toUrlEncoded(obj) {
    return Object.keys(obj)
      .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
      .join("&");
  }

  exportProject(id) {
    return this.http.get(`${environment.apiEndpoint}/Projects/export/${id}`, {
      responseType: "blob"
    });
  }

  addChildNode(node, parent, newNode) {
    if ("/" + node.value == node.path) {
      node.settings = this.getFolderSettings(true);
    }

    //find parent node
    if (node.value == parent) {
      // if node has children and append to existing else create new children(else part) id:new Date().getMilliseconds()}
      if (node.children) {
        newNode.value != "" &&
          !node.children.find(n => n.value == newNode.value) &&
          node.children.push(newNode);
      } else {
        newNode.value && (node.children = []);
        newNode.value && node.children.push(newNode);
      }
      return;
    }
    //recursion for all tree to find the right parent
    if (node.children) {
      node.settings = this.getFolderSettings(true);
      for (let cnode of node.children) {
        this.addChildNode(cnode, parent, newNode);
      }
    }
  }

  path = "/";
  traversePath(pathSegments, file) {
    if (pathSegments[0] === "") {
      this.addChildNode(this.projectFileTree, "FILES", {
        ...file,
        value: pathSegments[1]
      });
    } else {
      this.addChildNode(this.projectFileTree, pathSegments[0], {
        ...file,
        value: pathSegments[1],
        id: file.id
      });
    }
    if (pathSegments.length === 1) {
      return;
    } else {
      // console.log(pathSegments);
      this.traversePath(pathSegments.splice(1), file);
    }
  }

  getLanguage(code) {
    switch (code) {
      case "json":
        return "json";
      case "css":
        return "css";
      case "js":
        return "javascript";
      case "html":
        return "html";
      case "ts":
        return "typescript";
    }
  }

  loginSuccess = new Subject();
  loginSuccess$ = this.loginSuccess.asObservable();

  toLoginStatus(data) {
    this.loginSuccess.next(data);
  }

  newProjectChange = new Subject();
  newProjectChange$ = this.newProjectChange.asObservable();

  sendNewProjectCreationObject(d, pv?) {
    if (pv) {
      d.versions = pv;
    }
    this.newProjectChange.next(d);
  }

  // actions performed from menu bar observed in code-editor and files.component
  performAction = new Subject();
  performAction$ = this.performAction.asObservable();

  sendPerformedAction(a) {
    this.performAction.next(a);
  }

  //perform untitled file saving fires renaming in files
  performUntitledFileSave = new Subject();
  performUntitledFileSave$ = this.performUntitledFileSave.asObservable();

  sendUntitledFileName(n) {
    this.performUntitledFileSave.next(n);
  }

  updateSlugEvent = new Subject();
  updateSlugEvent$ = this.updateSlugEvent.asObservable();
  sendUpdateSlugEvent(data) {
    this.updateSlugEvent.next(data);
  }

  // install package dependencies from relay htm
  installDependenciesSubject = new Subject<any>();
  setInstallDependencies(data) {
    this.installDependenciesSubject.next(data);
  }

  getInstallDependencies() {
    return this.installDependenciesSubject.asObservable();
  }

  // server side docker

  // tslint:disable-next-line: member-ordering
  slug: string;

  setSlug(slug) {
    this.slug = slug;
  }

  setProjectName(name) {
    this.projectName = name;
  }

  getDockerInfo() {
    return this.getLocalStorageData("dockerInfo")[0];
  }

  createDocker() {
    const dockerInfo = this.getLocalStorageData("dockerInfo")[0];
    const { port = null, image = null } = this.project["dockerSettings"];
    dockerInfo.dockerImage = image;
    dockerInfo.port = port;
    if (dockerInfo.isShellOnly) {
      dockerInfo.projectFiles = this.project.files;
    }
    //this.socket.emit("createDocker", { dockerInfo });
  }

  dockerCreated() {
    return; //this.socket.fromEvent("socketConnected");
  }

  initiateTerminal(slug) {
    //this.socket.emit("initiateTerminal", {
    //   slug: slug,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  saveFilesOnDocker(slug) {
    //this.socket.emit("saveFilesById", {
    //   slug: slug,
    //   isFirstTime: true,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  saveFileOnDocker(obj) {
    //this.socket.emit("saveFile", {
    //   files: obj,
    //   isFirstTime: false,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  terminal(string) {
    //this.socket.emit("terminal", {
    //   command: string,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  onSaveFileData() {
    return; //this.socket.fromEvent("saveFileResponse");
  }

  onTerminalData() {
    return; //this.socket.fromEvent("data");
  }

  getTerminalOutput() {
    return; //this.socket.fromEvent("runTerminalOutput");
  }

  refreshIframe() {
    return; //this.socket.fromEvent("refreshIframe");
  }

  openExtraTerminal(id) {
    //this.socket.emit("openExtraTerminal", {
    //   id,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  openExtraTerminalWrite(id, string) {
    //this.socket.emit("terminal", {
    //   id,
    //   command: string,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  getPackageFile() {
    return; //this.socket.fromEvent("packageFile");
  }

  runInputOutputCode(files) {
    return this.http.post(environment.executionUrl, {
      files: files,
      input: "11\n1\n2\n3\n4\n-1\n5\n-1\n-1\n6\n-1\n7\n"
    });
  }

  runCode(entryPoint) {
    //this.socket.emit("runCode", {
    //   installPackages: false,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  runTest() {
    //this.socket.emit("testCode", {
    //   isTestRun: true,
    //   dockerInfo: this.getDockerInfo(),
    //   testName: "unittest"
    // });
  }

  async runSingleProblem(language, input) {
    const codelab = (await this._getCodelab()) as any;
    return this.http
      .post(`${codelab.runUrl}`, {
        slug: this.slug,
        language,
        input
      })
      .toPromise();
  }

  async _getCodelab() {
    if (this.project.testDocker) {
      return await this.http
        .post(`${environment.iraEndpoint}/codelab`, {
          where: { id: this.project.testDocker }
        })
        .toPromise();
    }
  }

  getTestResult() {
    return; //this.socket.fromEvent("testResult");
  }

  getMongoDatabases() {
    return this.http.post(environment.mongo, {
      database: this.slug,
      query: "db.admin().listDatabases()"
    });
  }

  getMongoCollectionByDatabse(db) {
    return this.http.post(environment.mongo, {
      database: this.slug,
      query: "db.listCollections().toArray()"
    });
  }

  getMongoDataByCollection(db, collectionName) {
    return this.http.post(environment.mongo, {
      database: this.slug,
      query: `db.collection('${collectionName}').find()`
    });
  }

  onMongoExecute(db, query) {
    return this.http.post(environment.mongo, {
      database: this.slug,
      query: query
    });
  }

  addPackage(addPackage) {
    //this.socket.emit("addPackage", {
    //   package: addPackage,
    //   dockerInfo: this.getDockerInfo()
    // });
  }

  // server side docker end

  // local storage

  // get localStorage data
  getLocalStorageData(strValue) {
    let localArray = JSON.parse(localStorage.getItem(strValue)) || [];
    localArray = localArray.filter(e => e.slug === this.slug);
    return localArray;
  }

  // set localStorage data
  setLocalStorageData(strValue, data) {
    let localArray;

    localArray = JSON.parse(localStorage.getItem(strValue)) || [];

    localArray = localArray.filter(e => e.slug !== this.slug);

    data["localStorageDate"] = Date.now();

    localArray.push(data);

    localStorage.setItem(strValue, JSON.stringify(localArray));

    this.removeLocalStorageData("dockerInfo");
  }

  updateLocalStorageData(strValue, data) {
    const localArray = JSON.parse(localStorage.getItem(strValue)) || [];
    const foundIndex = localArray.findIndex(x => x.slug === this.slug);
    data["localStorageDate"] = Date.now();
    localArray[foundIndex] = data;
    localStorage.setItem(strValue, JSON.stringify(localArray));
  }

  // remove localStorage data
  removeLocalStorageData(strValue) {
    let localArray = JSON.parse(localStorage.getItem(strValue));
    localArray = localArray.filter(
      e => Math.abs((Date.now() - e.localStorageDate) / 1000) < 1000 * 60 * 2
    );
    console.log(localArray);
    localStorage.setItem(strValue, JSON.stringify(localArray));
    // localStorage.removeItem(strValue);
  }

  getFileLanguage(fileName) {
    const lastIndexOfDot = fileName.lastIndexOf(".");
    const fileExtension = fileName.substring(lastIndexOfDot + 1);
    return languageMap.languages(fileExtension)[0];
  }

  runFrontEndTest(slug) {
    return this.http.post(`http://localhost:3011/runTestJs`, {
      slug
    });
  }

  async getDevDepFromCdn(packageDeps) {
    const [err, dependencies] = await to(
      this.http
        .post(
          `https://codelabapi.kh3ira.com/v1/moduleManager/depsMeta`,
          Object.assign({}, ...packageDeps)
        )
        .toPromise()
    );
    return dependencies;
  }

  getFileByPathAndValue(fileOpened) {
    const fileArray = fileOpened && fileOpened.split("/");

    const value = fileArray.pop();

    const path = `/${fileArray.join("")}`;

    const projectFiles: any = this.project;

    return projectFiles.files.find(f => f.path === path && f.value === value);
  }

  getLocalStorage(name) {
    return JSON.parse(localStorage.getItem(name));
  }

  setLocalStorage(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
    return JSON.parse(localStorage.getItem(name));
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem("firebase-active-user"));
  }

  setOrGetId(firebaseActiveUser) {
    const localUser = JSON.parse(localStorage.getItem("firebase-active-user"));
    if (localUser && !localUser.isVismaya) {
      return localUser.uid;
    } else {
      return firebaseActiveUser.uid;
    }
  }

  getContent() {
    const url = `https://d1jyvh0kxilfa7.cloudfront.net/v1/combinations/babel-runtime@6.26.0%2Breact@16.8.6%2Breact-dom@16.8.6.json`;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }
}
