import { Component, OnInit } from "@angular/core";
import { ProjectService } from "../../project/project.service";

@Component({
  selector: "zl-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"]
})
export class SearchComponent implements OnInit {
  isCollapsed = true;
  isExpanded = false;
  isSelected: any;
  match = [];
  constructor(private projectService: ProjectService) {}
  searchImplementation(event: Event) {
    let values = event.target["value"];
    let tree = this.projectService.getProjectFileTree();
    this.match = [];
    this.projectService.getProjectFileTree().children.forEach(c => {
      if (c["content"].includes(values)) {
        this.match.push(c);
      }
    });
  }
  
  ngOnInit() {}
}
