import { NgProgressModule, NgProgressInterceptor } from 'ngx-progressbar';
import { NgModule } from "@angular/core";
import { TreeModule } from "ng2-tree";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { ResizableModule } from "angular-resizable-element";
import { MenuModule } from "./menu/menu.module";
import { ProjectExplorerModule } from "./project-explorer/project-explorer.module";
import { HomeComponent } from "./home/home.component";
import { AppRoutingModule } from ".//app-routing.module";
import { ProjectModule } from "./project/project.module";
import { AngularDraggableModule } from "angular2-draggable";
import { ProjectService } from "./project/project.service";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { UserProjectsComponent } from './user-projects/user-projects.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxSpinnerModule } from "ngx-spinner";
import {PaginationModule, TooltipModule, BsDropdownModule} from "ngx-bootstrap";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { YourComponentDeactiveService } from './your-component-deactive.service';

// import { environment } from '../environments/environment';

@NgModule({
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    ResizableModule,
    TreeModule,
    FormsModule,
    MenuModule,
    ProjectExplorerModule,
    AngularDraggableModule,
    ProjectModule,
    AppRoutingModule,
    NgProgressModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary btn-min-w',
      cancelButtonClass: 'btn'}),
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule, // imports firebase/firestore, only needed for database features
      AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
      AngularFireStorageModule, // imports firebase/storage only needed for storage features
      CollapseModule.forRoot(),
      PaginationModule.forRoot(),
      TooltipModule.forRoot(),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      BsDropdownModule.forRoot()
  ],
  declarations: [AppComponent, HomeComponent, UserProjectsComponent, PageNotFoundComponent],
  providers: [ProjectService, YourComponentDeactiveService,{ provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
