import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ResizeEvent } from 'angular-resizable-element';
import to from 'await-to-js';
import JSONFormatter from 'json-formatter-js';
import { languages } from 'lang-map';
import _ from 'lodash';
import { TreeModel } from 'ng2-tree';
import { TabsetComponent } from 'ngx-bootstrap';
import { NgProgress } from 'ngx-progressbar';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

import { ProjectService } from '../project.service';

declare var BrowserFS: any;

//#region static data for initial files
const indexFiles = {
  react: "/src/index.js",
  angular: "/src/main.ts",
  typescript: "/src/index.ts",
  javascript: "src/index.js",
  html: "src/index.js",
  css: "src/index.js"
};
const indexHtml = {
  react: "/public/index.html",
  angular: "/src/index.html",
  typescript: "/public/index.html",
  javascript: "/public/index.html",
  html: "/public/index.html",
  css: "/public/index.html"
};

//#endregion
@Component({
  selector: "zl-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.css"]
})
export class ProjectComponent implements OnInit, AfterViewInit {
  // #region  Variables
  warningMsg;
  elements: any = [];
  previousHeight = 0;
  radioModel = "both";
  saveEditorCode;
  topicType = "theory";
  isGratis = false;
  isUserOwner = false;
  isBothShow = true;
  isConsoleHidden = true;
  explorerWidth = (window.innerWidth * 15) / 100;
  previewWidth = (window.innerWidth * 40) / 100;
  editorWidth = 0;
  isCollapsed = true;
  isFrameExplorer = false;
  toggleExplorer = true;
  toggleSettings = false;
  toggleSearch = false;
  toggleCollaborative = false;
  toggleImportGithubProject = false;
  toggleFiles = true;
  isProjectLoaded = true;
  firebaseActiveUser: any;
  previewBaseURLSanitized;
  previewBaseURL;
  previewRelayURLSanitized;
  expectedRelayURLSanitized;
  projectType;
  projectInfo = "";
  projectVersion = "";
  projectStructure: object;
  // scope variables
  slug;
  projectName;
  sub;
  projectFileTree: TreeModel;
  projectDependencies: Array<object>;
  projectExternalResources: Array<string> = [];
  projectSystemJSMap: object;
  projectSystemJSPackages: object;
  projectFiles: Array<any>;
  projectEntryPoint: string;
  projectNpmEntryPoint: string;
  style: object = {};
  action: any = "";
  enableSave = false;
  totalVisits: any;
  totalForks: any;
  gratisPort: any;
  navigationUrlForEmbedded;
  packagesUrl: string;

  showResponseView: boolean = false;
  relayMessageChannel: any;

  isShowExplorer: Boolean = true;
  previewLoaded: Boolean = false;
  isShowEditor: Boolean = true;
  isOnlyPreview: Boolean = true;
  isMongo: Boolean = false;
  isShowSideNavBar = true;
  isPreviewShow = true;
  isEditorShow = true;
  isEmbedded = false;
  isProjectInstalled = false;
  isLive = false;
  liveSessionKey = "";
  isDependenciesShow: Boolean = true;
  isFilesTreeCollapsed: Boolean = false;

  menuInputs = {
    isShowFile: true,
    isShowEdit: true,
    isShowTest: true,
    isShowRun: true,
    isShowSave: true
  };

  consoleOptions = {
    theme: "vs-dark",
    inherit: false,
    bracketSpacing: true,
    autoIndent: true,
    // formatOnType: true,
    // formatOnPaste: true,
    // lineNumbers: true,
    // showFoldingControls: "mouseover",
    matchBrackets: true,
    autoClosingBrackets: true,
    cursorBlinking: "blink",
    // showUnused: true,
    smoothScrolling: true,
    wordBasedSuggestions: true,
    // automaticLayout: true,
    // colorizeElement: true,
    insertSpaces: false,
    tabSize: 1,
    // glyphMargin: true,
    snippets: "inline",
    colorDecorators: true,
    // lightbulbEnabled: true,
    // iconsInSuggestions: true,
    minimap: { enabled: false },
    scrollbar: { verticalScrollbarSize: 11 }
  };
  isConsoleOpen = false;
  monacoEditor: any = {
    id: 1,
    currentCode: "",
    model: {
      value: "",
      language: "",
      uri: "ASWEDRFG"
    },
    src: {
      address: "",
      path: "",
      iconClass: "",
      id: "",
      isDirty: false
    }
  };
  consoleEditor: any = {
    model: {
      value: "",
      language: "javascript",
      uri: "CONSOLEAWX"
    }
  };
  intervalTimer = null;
  liveSessionUrl: any;

  navigationUrl: any = window.location.pathname;
  isOnlyEmbedded = false;
  isLiveSessionRunning = false;
  resizeEdges = { bottom: false, right: true, top: false, left: true };
  resizeEdges1 = { bottom: false, right: true, top: false, left: true };
  liveOwnerDetails: any;

  messageChannel: MessageChannel;
  vismayaPort: MessagePort;
  sdkPort: MessagePort;

  terminalSubscribe: Subscription;
  testResultSubscribe: Subscription;
  getTerminalSubscribe: Subscription;
  getRefreshIframe: Subscription;
  dockerSubscribe: Subscription;
  dockerOnSaveFile: Subscription;
  getPackageFile: Subscription;
  isFrontEnd: Boolean = false;
  isBackEnd: Boolean = false;
  hideUrlBar: Boolean = true;
  isShowUrlBar: Boolean = true;
  editorContent: any;
  // terminal: any;
  fitAddOn: any;
  terminalPreview: Boolean = false;
  consoleStorage = [];
  terminals = new Map();
  /* #endregion */

  pageLoading: Boolean = true;
  isTerminalOpen: number = 0;
  isShellOpened: Boolean = false;
  previewObj = { isShellOnly: false };
  /* #region View Child variables */

  @ViewChild("toggleIcon")
  toggleIcon: ElementRef;
  @ViewChild("outputFrame")
  iFrameEl: ElementRef;
  @ViewChild("iframe")
  iframe: ElementRef;
  @ViewChild("dependenciesSwal")
  dependenciesSwal;
  /* #endregion */

  @ViewChild("zlDependencies")
  zlDependencies;
  @ViewChild("codeEditor") codeEditorChild;
  mongoDatabases = [];
  mongoCode: string;
  collectionSelected: string;
  selectedCollection: any = {};
  isDataBaseCreated: Boolean = false;
  isCollectionOpened: Boolean = false;
  isConsoleBarHidden: Boolean = true;
  private user: Observable<firebase.User>;
  additinalGitUserData: any = {};
  templateUid: string;
  private userDetails: firebase.User = null;
  isInputOutputBased = false;

  fs = BrowserFS.BFSRequire("fs");
  path = BrowserFS.BFSRequire("path");
  process = BrowserFS.BFSRequire("process");
  fileFolder: string;
  dirName: string;

  editorOptions = {
    fontSize: "12px",
    lineHeight: "20px"
  };

  sideNavStyle: any = {
    position: "relative",
    width: `200px`,
    height: `100%`
  };
  editorNavSTyle: any = {
    position: "relative",
    height: `100%`,
    width: `calc(100% - 600px)`
  };

  sideNavStyle1: any = {
    position: "relative",
    width: `600px`,
    height: `100%`,
    minWidth: "600px"
  };

  editorNavSTyle1: any = {
    position: "relative",

    width: `calc(100% - 600px)`
  };

  sideDatabaseNavStyle: any = {
    position: "relative",
    width: `200px`,
    height: `100%`
  };

  sideDatabaseMainNavStyle: any = {
    position: "relative",
    height: `100%`,
    width: `calc(100% - 200px)`
  };

  databaseTopStyle: any = {
    position: "relative",
    width: `100%`,
    height: `163px`
  };

  databaseBottomStyle: any = {
    position: "relative",
    width: `100%`,
    height: `calc(100% - 163px)`
  };

  terminalList = [];
  terminalId: number = 0;
  isTerminalDivOpen = false;
  isOpenTerminalTab = 0;
  @ViewChild("staticTabs") staticTabs: TabsetComponent;

  @ViewChild("mainTabset") mainTabset: TabsetComponent;

  enableEditor: Boolean = true;
  enableBrowser: Boolean = true;
  enableDatabase: Boolean = false;
  enableTerminal: Boolean = false;
  enableMultipleTerminals: Boolean = true;
  terminalEditorModel: any = "";
  isProjectType: string;

  editorNavStyleWidth = 0;

  editorOptionsDatabase = {
    fontFamily: "'Fira Code',Consolas, 'Courier New', monospace",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "20px",
    cursorSmoothCaretAnimation: true,
    fixedOverflowWidgets: true,
    codeLens: true,
    theme: "vs-light",
    bracketSpacing: true,
    autoIndent: true,
    formatOnType: true,
    formatOnPaste: true,
    matchBrackets: true,
    autoClosingBrackets: true,
    cursorBlinking: "smooth",
    showUnused: true,
    snippetSuggestions: "top",
    smoothScrolling: true,
    wordBasedSuggestions: false,
    automaticLayout: true,
    colorizeElement: true,
    insertSpaces: false,
    tabSize: 4,
    glyphMargin: false,
    snippets: "inline",
    colorDecorators: false,
    lightbulbEnabled: false,
    iconsInSuggestions: false,
    wordWrap: true,
    minimap: { enabled: false },
    scrollbar: { verticalScrollbarSize: 11 },
    scrollBeyondLastLine: false,
    lineNumbers: "on",
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 3,
    renderIndentGuides: false,
    contextmenu: false,
    renderLineHighlight: "none"
  };

  editorFontSizeText = "small";
  runCodeSuccess = false;

  runCodeResponse = "Run code to compile...";
  runCodeHasErrors = false;

  testCaseForm: FormGroup;

  constructor(
    private projectService: ProjectService, //  for getting data from service
    private route: ActivatedRoute, // for routing purpose,
    private router: Router,
    private sanitizer: DomSanitizer,
    public afAuth: AngularFireAuth,
    private ngProgress: NgProgress,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.testCaseForm = this.fb.group({
      name: "",
      testCases: this.fb.array([])
    });

    this.testCases().push(
      this.fb.group({
        qty: "111",
        price: "22222"
      })
    );

    this.testCases().push(
      this.fb.group({
        qty: "2222",
        price: "33333"
      })
    );
    // console.log = function(msg) {
    //   if (this.isFrontEnd) {
    //     const consoleLogData = `<span>${msg}</span>`;
    //     document.getElementById("frontEndTerminal").innerHTML += consoleLogData;
    //   }
    // };
    // (function() {
    //   const oldLog = console.log;
    //   console.log = function(message) {
    //     const consoleLogData = `<span>${message}</span>`;
    //     // document.getElementById("frontEndTerminal").innerHTML += consoleLogData;
    //     document.getElementById("frontEndTerminal").innerHTML += consoleLogData;
    //     oldLog.apply(console, arguments);
    //   };

    //   const oldErr = console.error;
    //   console.error = function(message) {
    //     oldErr.apply(console, arguments);
    //   };
    // })();

    this.route.queryParams.subscribe(response => {
      let fData = JSON.parse(localStorage.getItem("firebase-active-user"));
      let fs = JSON.parse(localStorage.getItem("additional-fu-info"));
      if (fs == null) {
        this.additinalGitUserData = {};
      } else {
        this.additinalGitUserData = fs;
      }
      if (fData == null) {
        this.firebaseActiveUser = {};
        this.afAuth.auth.signInAnonymously().catch(error => {
          var errorMessage = error.message;
        });
      } else {
        this.firebaseActiveUser = fData;
        this.templateUid = JSON.parse(localStorage.getItem("template-uid"));
      }
    });

    const user = (this.templateUid = JSON.parse(
      localStorage.getItem("firebase-active-user")
    ));
    if (user) {
      this.firebaseActiveUser["isAnonymous"] = user.isAnonymous;
      this.firebaseActiveUser["uid"] = this.projectService.setOrGetId(user);
      this.firebaseActiveUser["displayName"] = user.displayName
        ? user.displayName
        : "";
      this.firebaseActiveUser["email"] = user.email
        ? user.email
        : this.additinalGitUserData["email"];
      this.firebaseActiveUser["photoURL"] = user.photoURL ? user.photoURL : "";
      this.firebaseActiveUser["name"] = this.additinalGitUserData["name"]
        ? this.additinalGitUserData["name"]
        : "";
      this.firebaseActiveUser["token"] = this.additinalGitUserData["token"]
        ? this.additinalGitUserData["token"]
        : "";
      // this.firebaseActiveUser["isVismaya"] = true;
      this.templateUid = JSON.parse(localStorage.getItem("template-uid"));
      localStorage.setItem(
        "firebase-active-user",
        JSON.stringify(this.firebaseActiveUser)
      );
    } else {
      this.firebaseActiveUser["isAnonymous"] = true;
      this.firebaseActiveUser["uid"] = this.makeid(28);
      this.firebaseActiveUser["displayName"] = "";
      this.firebaseActiveUser["email"] = "";
      this.firebaseActiveUser["photoURL"] = "";
      this.firebaseActiveUser["name"] = "";
      this.firebaseActiveUser["token"] = "";
      // this.firebaseActiveUser["isVismaya"] = true;
      localStorage.setItem(
        "firebase-active-user",
        JSON.stringify(this.firebaseActiveUser)
      );
    }

    // this.user = this.afAuth.authState;
    // this.user.subscribe(async user => {
    //   if (user) {
    //     // console.log(user, "new");
    //     this.userDetails = user;
    //     this.firebaseActiveUser["isAnonymous"] = user.isAnonymous;
    //     this.firebaseActiveUser["uid"] = this.projectService.setOrGetId(user);
    //     this.firebaseActiveUser["displayName"] = user.displayName
    //       ? user.displayName
    //       : "";
    //     this.firebaseActiveUser["email"] = user.email
    //       ? user.email
    //       : this.additinalGitUserData["email"];
    //     this.firebaseActiveUser["photoURL"] = user.photoURL
    //       ? user.photoURL
    //       : "";
    //     this.firebaseActiveUser["name"] = this.additinalGitUserData["name"]
    //       ? this.additinalGitUserData["name"]
    //       : "";
    //     this.firebaseActiveUser["token"] = this.additinalGitUserData["token"]
    //       ? this.additinalGitUserData["token"]
    //       : "";
    //     // this.firebaseActiveUser["isVismaya"] = true;
    //     localStorage.setItem(
    //       "firebase-active-user",
    //       JSON.stringify(this.firebaseActiveUser)
    //     );
    //     this.templateUid = JSON.parse(localStorage.getItem("template-uid"));
    //     // try {
    //     //   this.projectService.assignUidToSocket();
    //     // } catch (error) {}
    //     // if (this.projectFileTree === undefined) {
    //     //   this.projectService.setProjectFileTree({ files: [] });
    //     //   this.getProjectDetails();
    //     // } else {
    //     //   console.log("Called projectFileTree", 1);
    //     // }
    //   } else {
    //     this.firebaseActiveUser["token"] = "";
    //     this.firebaseActiveUser["name"] = "";
    //     if (this.firebaseActiveUser) {
    //       this.afAuth.auth.signInAnonymously().catch(error => {
    //         var errorMessage = error.message;
    //       });
    //       // try {
    //       //   this.projectService.assignUidToSocket();
    //       // } catch (error) {}
    //     }
    //   }
    // });

    this.projectService.ProTree$.subscribe(res => {
      this.projectFileTree = this.projectService.getProjectFileTree();
      this.projectType = res["name"];
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navigationUrl = event["url"];
        if (!this.navigationUrl.includes("isLive")) {
          this.liveSessionUrl = `${window.location.origin}${this.navigationUrl}&isLive=${this.liveSessionKey}`;
        } else {
          this.liveSessionUrl = `${window.location.origin}${this.navigationUrl}`;
        }
      }
    });
    // this.previewWidth = window.innerWidth/4;
    const embeddedUrls = window.location.href.split("&");
    this.navigationUrlForEmbedded = embeddedUrls[0];
    this.editorWidth =
      window.innerWidth - (this.explorerWidth + this.previewWidth);
    this.previewBaseURL = `${this.getPreviewUrl()}`;

    this.previewBaseURLSanitized = sanitizer.bypassSecurityTrustResourceUrl(
      `${this.getPreviewUrl()}`
    );
    this.previewRelayURLSanitized = sanitizer.bypassSecurityTrustResourceUrl(
      `${this.getPreviewUrl()}${environment.production === false ? "" : "/preview"
      }/relay.html`
    );
    // this.projectService
    //   .onProjectSavedFromSocket()
    //   .subscribe(({ slug, user }) => {
    //     if (this.slug === slug) {
    //       this.getProjectDetails();
    //     }
    //   });

    // this.projectService
    //   .onCheckProjectSessionInLive()
    //   .subscribe(({ slug, response }) => {
    //     if (this.slug === slug) {
    //       const { key, slug, user } = response
    //         ? JSON.parse(response)
    //         : { key: undefined, user: undefined, slug: undefined };
    //       this.isLiveSessionRunning = key ? true : false;
    //       if (this.isLiveSessionRunning) {
    //         this.liveSessionKey = key;
    //         this.liveOwnerDetails = user;
    //         if (!this.navigationUrl.includes("isLive")) {
    //           this.liveSessionUrl = `${window.location.origin}${this.navigationUrl}&isLive=${this.liveSessionKey}`;
    //         } else {
    //           this.liveSessionUrl = `${window.location.origin}${this.navigationUrl}`;
    //         }
    //         this.projectService.setLiveSessionOn();
    //       } else {
    //         this.projectService.setLiveSessionOff();
    //         // show pop up, like live session ended.
    //         console.log("LIVE SESSION ENDED");
    //       }
    //     }
    //   });

    // this.projectService.onStopLiveSession().subscribe(({ slug }) => {
    //   if (this.slug === slug && !this.isUserOwner) {
    //     this.isLiveSessionRunning = false;
    //     this.liveSessionKey = "";
    //     this.projectService.setLiveSessionOff();
    //     // show pop up, like live session ended.
    //     console.log("LIVE SESSION ENDED");
    //   }
    // });
    // this.projectService
    //   .onInitialProjectDependenciesFromSocket()
    //   .subscribe(({ slug, user, data }) => {
    //     if (this.slug === slug && data) {
    //       this.projectDependencies = data["projectDependencies"];
    //       this.projectExternalResources = data["projectExternalResources"];
    //       this.projectSystemJSMap = data["projectSystemJSMap"];
    //       this.projectSystemJSPackages = data["projectSystemJSPackages"];
    //       this.projectService.reAssignProjectDeps(data);
    //       setTimeout(() => {
    //         this.reloadProject(this.slug);
    //       }, 2000);
    //     }
    //   });
    // this.projectService
    //   .onNewProjectDependenciesFromSocket()
    //   .subscribe(({ slug, user, data }) => {
    //     if (this.slug === slug && !this.userIsAdmin() && data) {
    //       const data_deps = data["projectDependencies"];
    //       const newDependencies = data_deps.map(d => {
    //         return `${Object.keys(d)[0]}@${Object.values(d)[0]}`;
    //       });
    //       const currentDeps = this.projectDependencies.map(d => {
    //         return `${Object.keys(d)[0]}@${Object.values(d)[0]}`;
    //       });
    //       const filteredDependencies = newDependencies.filter(
    //         nd => !currentDeps.includes(nd)
    //       );
    //       this.projectService.setInstallDependencies(filteredDependencies);

    //       currentDeps
    //         .filter(nd => !newDependencies.includes(nd))
    //         .map(d => {
    //           const [key, value] = d.split("@");
    //           this.onNpmPackageRemoved(
    //             {
    //               [key]: value
    //             },
    //             null
    //           );
    //         });
    //     }
    //   });

    this.route.queryParams.subscribe(response => {
      if (response["hideExplorer"] && response["view"]) {
        this.isLive = false;
        this.isEmbedded = true;
        this.isShowExplorer = false;
        this.isBothShow = false;
        this.toggleExplorer = false;
        this.hideUrlBar = false;
        this.isDependenciesShow = false;
        if (response["view"] === "preview") {
          this.isEditorShow = false;
          this.isPreviewShow = true;
          this.radioModel = "preview";
          this.isDependenciesShow = false;
          this.hideUrlBar = false;
          this.previewWidth = window.innerWidth;
        } else {
          this.isPreviewShow = false;
          this.isEditorShow = true;
          this.radioModel = "editor";
          this.editorWidth = window.innerWidth;
          this.previewWidth = 0;
        }
      } else if (response["view"]) {
        this.isLive = false;
        this.isBothShow = false;
        this.isEmbedded = true;
        this.isDependenciesShow = false;
        this.hideUrlBar = false;
        this.toggleExplorer = false;
        if (response["view"] === "preview") {
          this.isEditorShow = false;
          this.isPreviewShow = true;
          this.radioModel = "preview";
          this.previewWidth = window.innerWidth;
          this.hideUrlBar = true;
          this.toggleExplorer = false;
        } else {
          this.isPreviewShow = false;
          this.isEditorShow = true;
          this.radioModel = "editor";
          this.editorWidth = window.innerWidth;
          this.previewWidth = 0;
          this.toggleExplorer = false;
        }
      } else if (response["hideExplorer"]) {
        this.isLive = false;
        this.isEmbedded = true;
        this.isShowExplorer = false;
        this.toggleExplorer = false;
        this.isDependenciesShow = false;
        this.editorWidth = window.innerWidth - this.previewWidth;
      } else if (response["isEmbedded"]) {
        this.isLive = false;
        this.isOnlyEmbedded = true;
        this.isEmbedded = true;
        this.toggleExplorer = false;
        // this.toggleExplorer = false;
        if (this.isEditorShow && this.isPreviewShow) {
          this.previewWidth = window.innerWidth / 2;
          this.editorWidth = window.innerWidth / 2;
        } else if (this.isEditorShow) {
          this.previewWidth = 0;
          this.editorWidth = window.innerWidth;
        } else {
          this.editorWidth = 0;
          this.previewWidth = window.innerWidth;
        }
        //if is live
      } else if (response["isLive"]) {
        this.isLive = true;
        this.liveSessionKey = response["isLive"];
        this.isOnlyEmbedded = false;
        this.isDependenciesShow = false;
        this.isEmbedded = false;
        // this.toggleExplorer = false;
        if (this.isEditorShow && this.isPreviewShow) {
          this.previewWidth = window.innerWidth / 2;
          this.editorWidth = window.innerWidth / 2;
        } else if (this.isEditorShow) {
          this.previewWidth = 0;
          this.editorWidth = window.innerWidth;
        } else {
          this.editorWidth = 0;
          this.previewWidth = window.innerWidth;
        }
      } else {
        // this.isEmbedded = false;
        // this.isShowExplorer = true;
        // this.isPreviewShow = true;
        // this.resizeEdges =  { bottom: false, right: true, top: false, left: true };
      }

      if (response["isProjectType"] === "handson") {
        this.isProjectType = "handson";
      }

      this.onResizeEdgeChanges();
    });

    // setTimeout(() => {
    //   if (this.isEmbedded) {
    //     this.onFilesToggle("file");
    //   }
    // }, 5000);
  } // /constructor

  onInit(editor) {
    const line = editor.getPosition();
    monaco.editor.create(editor, {
      lineNumbers: this.getLineNumbers(line.lineNumber)
    });
  }

  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getLineNumbers(originalLineNumber) {
    const map = ["O", ">"];
    if (originalLineNumber <= map.length) {
      return map[originalLineNumber];
    }
    return originalLineNumber;
  }

  getPreviewUrl() {
    if (environment.production === false) {
      return `http://localhost:4202/preview`;
    } else {
      return `https://${this.slug}.${environment.urlHttp}`;
    }
  }

  yourCustomLog() {
    console.log("dd");
  }

  /**
   * Check if the user is admin
   */
  userIsAdmin() {
    const projectOwnerUid = JSON.parse(localStorage.getItem("template-uid"));
    const currentUser = JSON.parse(
      localStorage.getItem("firebase-active-user")
    );
    const currentUserUid = currentUser ? currentUser.uid : null;
    this.isUserOwner = projectOwnerUid === currentUserUid;
    return projectOwnerUid === currentUserUid;
  }

  onStartLiveSession() {
    if (this.isUserOwner) {
      this.liveSessionKey = this.makeLiveKey();
      if (!this.firebaseActiveUser) {
        this.firebaseActiveUser = JSON.parse(
          localStorage.getItem("firebase-active-user")
        );
      }
      this.projectService.startLiveSession({
        slug: this.slug,
        user: this.firebaseActiveUser,
        key: this.liveSessionKey
      });
      this.isLiveSessionRunning = true;
      this.liveOwnerDetails = this.firebaseActiveUser;
      this.router.navigate([], {
        queryParams: {
          file: this.route.snapshot.queryParams["file"],
          isLive: this.liveSessionKey
        }
      });
    }
  }

  onStopLiveSession() {
    if (this.isUserOwner) {
      if (!this.firebaseActiveUser) {
        this.firebaseActiveUser = JSON.parse(
          localStorage.getItem("firebase-active-user")
        );
      }
      this.projectService.stopLiveSession({
        slug: this.slug,
        user: this.firebaseActiveUser,
        key: this.liveSessionKey
      });
      this.isLiveSessionRunning = false;
      this.liveSessionKey = "";
      this.router.navigate([], {
        queryParams: { file: this.route.snapshot.queryParams["file"] }
      });
    }
  }

  makeLiveKey(length = 6) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onResizeEnd(event: ResizeEvent) {
    if (event.edges.left) {
      this.explorerWidth = +event.rectangle.left;
    }
    if (event.edges.right) {
      if (this.isPreviewShow) {
        this.previewWidth = window.innerWidth - +event.rectangle.right;
      }
    }
    // console.log(event.rectangle.left , event.rectangle.right);
    if (event.rectangle.left && event.rectangle.right) {
      if (this.isPreviewShow) {
        this.editorWidth = Math.abs(
          +event.rectangle.left - +event.rectangle.right
        );
      } else {
        this.editorWidth = window.innerWidth - this.explorerWidth;
      }
    }
    // if (this.explorerWidth <= 200) {
    //   this.explorerWidth = this.explorerWidth - 200;
    // }
    if (this.editorWidth < 150) {
      this.editorWidth = 150;
    }

    // console.log(this.editorWidth)
  }

  modelStorage(event) {
    this.elements.push(this.monacoEditor.currentCode);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.isFilesTreeCollapsed) {
      this.editorNavSTyle = {
        width: `calc( ${window.innerWidth}px - ${40}px)`
      };
    } else {
      if (this.editorNavStyleWidth) {
        this.editorNavSTyle = {
          width: `calc( ${window.innerWidth}px - ${this.editorNavStyleWidth -
            100}px)`
        };
      } else {
        this.editorNavSTyle = {
          width: `calc( 100% - ${185}px)`
        };
      }
    }
  }
  ngOnInit() {
    this.showHideSideNav();
    // setTimeout(() => {
    //   this.getData();
    // }, 1500);
    // this.availHeight = window.innerHeight - 80;
    // this.iframePreviewPane = (this.availHeight * 100) / 100;
    // this.consolePreviewPane = (this.availHeight * 0) / 100;

    // const myJSON = {
    //   ans: 42,
    //   test: { new: { test: "vasista test" } },
    //   testNew: { new: { test: "test@gmail.com" } },
    //   ans1: "vasista test"
    // };

    // const myArray = [
    //   {
    //     ans: 42,
    //     test: { new: { test: 67 } },
    //     testNew: { new: { test: "67" } },
    //     ans1: 42
    //   },
    //   {
    //     ans: 42,
    //     test: { new: { test: "67" } },
    //     testNew: { new: { test: 67 } },
    //     ans1: 42
    //   }
    // ];

    // const formatter = new JSONFormatter(myJSON, 0, {
    //   hoverPreviewEnabled: true
    // });

    // const formatter1 = new JSONFormatter(myArray, 0, {
    //   hoverPreviewEnabled: true
    // });

    // console.log(formatter.render());
    // setTimeout(() => {
    //   document.getElementById("consoleId").appendChild(formatter.render());
    //   document.getElementById("consoleId").appendChild(formatter1.render());
    // }, 5000);

    this.projectService.newProjectChange$.subscribe((res: any) => {
      let isVersion = false;
      let x = ["react", "@angular/animations", "typescript"];
      if (res["name"] === "angular") {
        x = ["react", "@angular/animations"];
      }
      var keys = Object.keys(res["versions"]);
      x.forEach(value => {
        for (var i = 0; i < keys.length; i++) {
          if (value == keys[i]) {
            isVersion = true;
            this.projectVersion = res.versions[keys[i]];
            console.log("match: ", keys[i]);
          }
        }
      });

      if (isVersion == false) {
        this.projectVersion = "";
      }

      this._setProjectVitals();
      this.totalForks = Object.assign([], res["forks"]);
      this.totalVisits = Object.assign([], res["visits"]);
      this.projectType = res["ptype"];
      this.projectInfo = res["info"];
      delete res["forks"];
      delete res["visits"];
      this.visitProject(res);
      this.reloadProject(this.slug);
      this.firebaseActiveUser = JSON.parse(
        localStorage.getItem("firebase-active-user")
      );
      let templateUid = localStorage.getItem("firebase-active-user");
      // if (firebaseActiveUser["uid"] == templateUid) {
      //   this.projectService.sendAuthorDetails({
      //     slug: this.slug,
      //     user: firebaseActiveUser
      //   });
      // }
    });

    this.sub = this.route.params.subscribe(params => {
      this.isProjectInstalled = false;
      this.projectName = params["project"];
      this.slug = params["type"];

      if (this.projectName === "mongo") {
        this.enableBrowser = false;
        this.enableEditor = false;
        this.enableDatabase = true;
        this.enableTerminal = true;
        this.enableMultipleTerminals = false;
      }

      this.projectService.setProjectName(this.projectName);

      this.projectService.setSlug(this.slug);

      this.userIsAdmin();
      if (!this.firebaseActiveUser) {
        this.firebaseActiveUser = JSON.parse(
          localStorage.getItem("firebase-active-user")
        );
      }

      if (this.route.snapshot["queryParams"]["isLive"]) {
        this.projectService.checkProjectSessionInLive({
          slug: this.slug,
          user: this.firebaseActiveUser,
          key: this.liveSessionKey
        });
      }

      this.previewBaseURL = `${this.getPreviewUrl()}`;
      this.previewBaseURLSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.getPreviewUrl()}`
      );
      this.previewRelayURLSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.getPreviewUrl()}${environment.production === false ? "" : "/preview"
        }/relay.html`
      );
      if (this.projectFileTree) {
        this.sortByFileName(this.projectFileTree);
        this.projectDependencies = this.projectService.getProjectDependencies();
        this._setProjectVitals();
      }
      if (this.projectFileTree === undefined) {
        this.projectService.setProjectFileTree({ files: [] });
        this.getProjectDetails();
      } else {
        console.log("Called projectFileTree", 1);
      }
    });
  }

  testCases(): FormArray {
    return (
      this.testCaseForm && (this.testCaseForm.get("testCases") as FormArray)
    );
  }

  newTestCase(): FormGroup {
    return this.fb.group({
      qty: "",
      price: ""
    });
  }

  addTestCase() {
    this.testCases().push(this.newTestCase());
  }

  removeTestCase(i: number) {
    this.testCases().removeAt(i);
  }

  onTestCaseSubmit() {
    console.log(this.testCaseForm.value);
  }

  getProjectDetails() {
    this.projectService.getProject(this.slug).subscribe(
      project => {
        const projectType = project["frontEndSettings"]["type"];
        if (projectType && projectType === "input-output") {
          this.isInputOutputBased = true;
          this.isProjectType = "handson";
        }
        this.projectStructure = project;
        let x = ["react", "@angular/animations", "typescript"];
        if (project["name"] === "angular") {
          x = ["react", "@angular/animations"];
        }
        var keys = Object.keys(project["versions"]);
        let isVersion = false;
        x.forEach(value => {
          for (var i = 0; i < keys.length; i++) {
            if (value == keys[i]) {
              isVersion = true;
              this.projectVersion = project["versions"][keys[i]];
            }
          }
        });
        if (isVersion == false) {
          this.projectVersion = "";
        }

        this.projectEntryPoint = project["entryPoint"];
        this.projectNpmEntryPoint = project["npmEntryPoint"];

        this.totalForks = Object.assign([], project["forks"]);
        this.totalVisits = Object.assign([], project["visits"]);
        delete project["forks"];
        delete project["visits"];
        this.projectType = project["name"];
        this.projectInfo = project["info"];
        localStorage.setItem("template-uid", JSON.stringify(project["uid"]));
        this.projectService.setProjectFileTree(project);
        this.projectFileTree = this.projectService.getProjectFileTree();
        const params = this.route.snapshot["queryParams"]["file"];
        // alert(params);
        // console.log(project);
        const indexFile = project["files"].findIndex(
          f => `${f.path}/${f.value}` == `${params}`
        );
        // console.log(project["files"][indexFile]);

        const {
          params: { project: projectName, type: projectSlug },
          queryParams: { file }
        } = this.route.snapshot;
        if (indexFile === -1) {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() =>
              this.router.navigateByUrl(
                `/${projectName}/${projectSlug}?file=${encodeURI(
                  `${project["files"][0].path}/${project["files"][0].value}`
                )}`
              )
            );
        } else {
          this.projectService.codeSubject.next(
            project["files"][indexFile === -1 ? 1 : indexFile]
          );
        }
        // this.projectService.setCode()
        this._setProjectVitals();
        this.visitProject(project);
        if (this.isLive) {
          this.projectService.getProjectDependenciesFromSocket({
            key: this.liveSessionKey,
            slug: this.slug,
            user: true,
            initialLoad: true
          });
        }
      },
      err => {
        // this.router.navigate(
        //   [`${r["name"]}/${r["id"]}`],
        //   { queryParams: { file: params["file"] } }
        // );
        this.router.navigateByUrl("/page-not-found");
      }
    );
  }
  // visit count code starts here
  visitProject(project) {
    const userDetails = JSON.parse(
      localStorage.getItem("firebase-active-user")
    );
    if (userDetails && userDetails.uid !== project["uid"]) {
      this.projectService
        .visitProject({ uid: userDetails.uid, projectId: project["id"] })
        .subscribe(
          response => { },
          err => {
            console.log(err);
          }
        );
    }
  }
  // visit count code ends here

  sortByFileName(node) {
    if (node && node.children) {
      node.children = _.orderBy(node.children, "id");
      const temp = [];
      const onlyFiles = node.children.filter(child => {
        if (child.children) {
          temp.push(child);
          return false;
        }
        return true;
      });
      node.children = [...temp, ...onlyFiles];
      node.children.forEach(child => this.sortByFileName(child));
    }
  }

  saveButtonEnabled(event) {
    console.log(event);
  }

  checkReadyState() {
    alert("checkReadyState");
  }

  ngAfterViewInit() {
    // xterm

    // this.dockerSubscribe = this.projectService
    //   .dockerCreated()
    //   .subscribe(data => {
    //     this.loadFilesToDocker();

    //     // setTimeout(() => {
    //     //   this.projectService.runTest();
    //     // }, 7000);
    //   });

    // this.testResultSubscribe = this.projectService.getTestResult().subscribe(
    //   data => {
    //     console.log(data);
    //     this.previewLoaded = true;
    //     if (this.vismayaPort) {
    //       this.vismayaPort.postMessage({
    //         action: "TEST_PROJECT_SUCCESS",
    //         payload: { success: true, data }
    //       });
    //     }
    //   },
    //   err => {
    //     console.log("err");
    //   }
    // );

    // this.getRefreshIframe = this.projectService
    //   .refreshIframe()
    //   .subscribe(data => {
    //     clearTimeout(this.timeoutForIframe);

    //     // TODO: still have to update
    //     data["iframeUrl"] = `https://${this.slug}.run.${environment.urlHttp}`;

    //     this.timeoutForIframe = setTimeout(() => {
    //       console.log("testing commented code");
    //       // this.installProject();

    //       this.previewBaseURL = data["iframeUrl"];
    //       if (this.vismayaPort) {
    //         this.vismayaPort.postMessage({
    //           action: "IFRAME_REFRESH",
    //           payload: { success: true, url: data["iframeUrl"] }
    //         });
    //       }
    //       this.previewBaseURLSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
    //         `${data["iframeUrl"]}`
    //       );

    //       setTimeout(() => {
    //         this.previewLoaded = true;
    //       }, 200);

    //       // setTimeout(() => {
    //       //   this.projectService.openExtraTerminal(1);
    //       // }, 100);

    //       // setTimeout(() => {
    //       //   this.projectService.openExtraTerminal(2);
    //       // }, 300);
    //       // setTimeout(() => {
    //       //   this.projectService.openExtraTerminalWrite(1, "s\n");
    //       // }, 2000);

    //       // setTimeout(() => {
    //       //   this.projectService.openExtraTerminalWrite(
    //       //     2,
    //       //     "fgdgsssssssssssssssssssssssssssssssssssssssssssss\n"
    //       //   );
    //       // }, 2500);

    //       // setTimeout(() => {
    //       //   this.projectService.openExtraTerminalWrite(1, "rs\n");
    //       // }, 3000);
    //     }, 500);
    //   });

    // this.getPackageFile = this.projectService
    //   .getPackageFile()
    //   .subscribe(data => {
    //     this.projectFiles.map(file => {
    //       if (
    //         file.value.includes("package.json") &&
    //         file.path == data["path"]
    //       ) {
    //         file.content = JSON.stringify(data["packageFile"]);
    //       }
    //     });
    //   });

    this.onSaveFileData();
    // xterm end
    window.addEventListener("message", async evt => {
      //#region sdk.
      // sdk communication
      if (evt.data.action === "INIT") {
        const target = evt.currentTarget as Window;
        console.log('INIT')
        // create a message channel to enable SDK communication
        this.messageChannel = new MessageChannel();
        this.vismayaPort = this.messageChannel.port1;
        this.sdkPort = this.messageChannel.port2;
        console.log(this.vismayaPort)
        setTimeout(() => {
          this.pageLoading = false;
        }, 1000);
        // listen to messages being sent to vismaya
        this.vismayaPort.onmessage = async e => {
          const {
            data: {
              payload: { input }
            }
          } = e;
          switch (e.data.action) {
            case "GET_FILE_TREE":
              this.vismayaPort.postMessage({
                action: "GET_FILE_TREE_SUCCESS",
                payload: {
                  files: this.projectFiles,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SET_FILE_TREE":
              this.projectFiles = e.data.payload.files;
              this.vismayaPort.postMessage({
                action: "SET_FILE_TREE_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "RUN_PROJECT":
              this.onRun();
              this.vismayaPort.postMessage({
                action: "RUN_PROJECT_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SAVE_PROJECT":
              this.onSave(this.projectInfo);
              this.vismayaPort.postMessage({
                action: "SAVE_PROJECT_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "TEST_PROJECT":
              this.onTestSubmit();
              break;
            case "HIDE_EXPLORER":
              this.isShowExplorer = false;
              this.projectService.setExplorer(false);
              this.vismayaPort.postMessage({
                action: "HIDE_EXPLORER_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "HIDE_PREVIEW":
              this.isPreviewShow = false;
              this.projectService.setPreview(false);
              this.onNewWindowPreview();
              this.vismayaPort.postMessage({
                action: "HIDE_PREVIEW_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SHOW_EXPLORER":
              this.isShowExplorer = true;
              this.projectService.setExplorer(true);
              this.vismayaPort.postMessage({
                action: "SHOW_EXPLORER_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SHOW_PREVIEW":
              this.isPreviewShow = true;
              this.projectService.setPreview(true);
              this.onNewWindowPreview();
              this.vismayaPort.postMessage({
                action: "SHOW_PREVIEW_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SHOW_ONLY_PREVIEW":
              this.isPreviewShow = true;
              this.isShowExplorer = false;
              this.isShowSideNavBar = false;
              this.isEmbedded = false;
              this.isEditorShow = false;
              this.previewWidth = window.innerWidth;
              this.projectService.setPreview(true);
              this.onNewWindowPreview();
              this.vismayaPort.postMessage({
                action: "SHOW_ONLY_PREVIEW",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "HIDE_SIDE_NAV":
              this.isShowSideNavBar = false;
              this.projectService.setSideNavBar(true);
              this.vismayaPort.postMessage({
                action: "HIDE_SIDE_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SHOW_SIDE_NAV":
              this.isShowSideNavBar = false;
              this.projectService.setSideNavBar(false);
              this.vismayaPort.postMessage({
                action: "SHOW_SIDE_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "HIDE_MENU":
              this.isEmbedded = true;
              this.projectService.setEmbed(true);
              this.vismayaPort.postMessage({
                action: "HIDE_MENU_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SHOW_MENU":
              this.isEmbedded = false;
              this.projectService.setEmbed(false);
              this.vismayaPort.postMessage({
                action: "SHOW_MENU_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "HIDE_EDITOR":
              this.isEditorShow = false;
              this.vismayaPort.postMessage({
                action: "HIDE_EDITOR_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SHOW_EDITOR":
              this.isEditorShow = true;
              this.vismayaPort.postMessage({
                action: "SHOW_EDITOR_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "GET_TEST_PARSER":
              const testParseResult = await this.testParse();
              this.vismayaPort.postMessage({
                action: "TEST_PARSER_SUCCESS",
                payload: {
                  success: true,
                  data: testParseResult,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "GET_PROJECT":
              this.vismayaPort.postMessage({
                action: "GET_PROJECT_SUCCESS",
                payload: {
                  files: this.projectService.getProjectData(),
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SET_WIDTH":
              this.editorWidth = window.innerWidth - this.explorerWidth;
              // this.previewWidth = window.innerWidth - this.editorWidth;
              this.vismayaPort.postMessage({
                action: "SET_WIDTH_SUCCESS",
                payload: {
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SET_AUTHOR":
              this.projectService.setAuthor(e.data.payload.author);
              this.vismayaPort.postMessage({
                action: "SET_AUTHOR_SUCCESS",
                payload: {
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "RUN_CODE":
              const result = await this._runSingleProblem(input);
              this.vismayaPort.postMessage({
                action: "RUN_CODE_SUCCESS",
                payload: {
                  success: true,
                  data: result,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SELECT_FILE":
              if (this.projectName === "input-output") {
                const file = await this._findFileByType("java");
                const { path, value } = file;
                this.codeEditorChild.onNodeSelected(file, false, true);
              }
              this.vismayaPort.postMessage({
                action: "SELECT_FILE_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "SET_USER_DETAILS":
              await this._setUserInfo(input);
              this.vismayaPort.postMessage({
                action: "SET_USER_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "REFRESH":
              await this.reInstallProject();
              this.vismayaPort.postMessage({
                action: "REFRESH_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;

            case "SET_ANSWER_URL":
              const { url } = input;
              this.expectedRelayURLSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
                `${url}`
              );
              this.vismayaPort.postMessage({
                action: "SET_ANSWER_URL_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;

            case "SET_PRESENT_CONFIGURATION":
              const {
                enableEditor,
                enableBrowser,
                enableDatabase,
                enableTerminal,
                enableMultipleTerminals
              } = input;

              this.enableEditor = enableEditor;
              this.enableBrowser = enableBrowser;
              this.enableDatabase = enableDatabase;
              this.enableTerminal = enableTerminal;
              this.enableMultipleTerminals = enableMultipleTerminals;

              this.vismayaPort.postMessage({
                action: "SET_PRESENT_CONFIGURATION_SUCCESS",
                payload: {
                  success: true,
                  __reqid: e.data.payload.__reqid,
                  __success: true
                }
              });
              break;
            case "VALIDATE_TEST_CASES":
              console.log('GET_TEST_RESPONSE')
              const testResponse = await this.runTestCases();
              window.parent.postMessage({
                action: "VALIDATE_TEST_CASES_SUCCESS",
                payload: {
                  success: true,
                  data: testResponse
                }
              }, "*", [
                this.sdkPort
              ]);
              break;
            case "READY":
              this.menuInputs.isShowFile = true;
              this.menuInputs.isShowEdit = true;
              this.menuInputs.isShowTest = true;
              this.menuInputs.isShowRun = true;
              this.menuInputs.isShowSave = true;
              this.showResponseView = true;
              // console.log("checkTypeOfProjectToInstall - READY");
              this.checkTypeOfProjectToInstall();
          }
        };
        window.parent.postMessage({ action: "INIT_SUCCESS" }, "*", [
          this.sdkPort
        ]);
        return;
      } else if (evt.data.action === "VALIDATE_TEST_CASES") {
        const testResponse = await this.runTestCases();
        window.parent.postMessage({
          action: "VALIDATE_TEST_CASES_SUCCESS",
          payload: {
            success: true,
            data: testResponse
          }
        }, '*');
        // window.parent && window.parent.postMessage({ action: "INIT_SUCCESS" }, "*",);
      } else if (evt.data.action === "SET_EDITOR_ONLY") {
        this.isEmbedded = true;
        this.isPreviewShow = false;
        this.hideUrlBar = true;
        this.isEditorShow = true
        this.projectService.setPreview(true);

        window.parent.postMessage({
          action: "SET_EDITOR_ONLY_SUCCESS",
          payload: {
            success: true,
            data: this.previewBaseURLSanitized
          }
        }, "*");
      } else if (evt.data.action === "HIDE_PREVIEW_ONLY") {

        this.isPreviewShow = false;


        window.parent.postMessage({
          action: "HIDE_PREVIEW_ONLY_SUCCESS",
          payload: {
            success: true,
            data: this.previewBaseURLSanitized
          }
        }, "*");
      } else if (evt.data.action === "SHOW_PREVIEW_ONLY") {

        this.isPreviewShow = true;


        window.parent.postMessage({
          action: "SHOW_PREVIEW_ONLY_SUCCESS",
          payload: {
            success: true,
            data: this.previewBaseURLSanitized
          }
        }, "*");
      } else if (evt.data.action === "SET_PREVIEW_ONLY") {
        this.isEmbedded = true;
        this.isPreviewShow = true;
        this.hideUrlBar = true;
        this.isEditorShow = false
        this.isInputOutputBased = true
        // console.log(this.isEmbedded && this.isPreviewShow && this.hideUrlBar && !this.isEditorShow);
        // this.showResponseView = true
        // this.previewObj.isShellOnly = false
        // this.projectService.setPreview(true);
        this.vismayaPort && this.vismayaPort.postMessage({
          action: "SET_PREVIEW__ONLY_SUCCESS",
          payload: {
            success: true,
            data: this.previewBaseURLSanitized
          }
        });
      }
      //#endregion

      if (evt.ports && evt.ports.length) {
        this.gratisPort = evt.ports[0];
        this.gratisPort.onmessage = event => {
          try {
            this.isGratis = true;

            var data = JSON.parse(event.data);
            if (data.action && data.action === "submitAnswer") {
              this.onSave(this.projectInfo);
              this.relayMessageChannel.postMessage(
                JSON.stringify({ from: "GRATIS", action: "submitAnswer" })
              );
            } else if (data.action && data.action === "fork") {
              this.gratisForkProject();
            } else if (data.action && data.action === "topic_type") {
              this.topicType = data.topicType;
            }
          } catch (e) { }
        };
      }
    });

    //#region relay communication
    const relayIframe = document.getElementById("relayIframe");
    // Wait for the relayIframe to load
    relayIframe.addEventListener("load", () => {
      this.pageLoading = false;
      const messageChannel = new MessageChannel();
      this.relayMessageChannel = messageChannel.port1;
      this.relayMessageChannel.onmessage = relayReply => {
        // getting events from engine GRATIS_CHANNEL code starts here
        let relayReplyToGratis = false;
        try {
          const gratisData = JSON.parse(relayReply.data);
          if (gratisData.from === "GRATIS_CHANNEL") {
            relayReplyToGratis = true;
            this.gratisPort && this.gratisPort.postMessage(relayReply.data);
          }
        } catch (e) { }

        if (relayReplyToGratis) {
          return;
        }
        // getting events from engine GRATIS_CHANNEL code ends here
        try {
          if (typeof relayReply.data === "object") {
            const { eventName, data } = relayReply.data;
            switch (eventName) {
              case "ROUTE_CHANGE":
                // console.log("relayReply", data);
                this.previewBaseURL = data;
                break;
              case "INSTALL_DEPENDENCIES":
                console.log("INSTALL_DEPENDENCIES_REQUEST", data);
                this.projectService.setInstallDependencies(data);
                break;
            }
            return;
          }
        } catch (e) {
          console.error("relayReply", e);
          return;
        }

        this.menuInputs.isShowFile = true;
        this.menuInputs.isShowEdit = true;
        this.menuInputs.isShowTest = true;
        this.menuInputs.isShowRun = true;
        this.menuInputs.isShowSave = true;

        this.showResponseView = true;

        // alert(relayReply.data);
        // console.log("checkTypeOfProjectToInstall - relayMessageChannel");
        // this.checkTypeOfProjectToInstall();
        if (relayReply.data === "READY") {
          // console.log(
          //   "checkTypeOfProjectToInstall - relayMessageChannel + READY "
          // );

          this.checkTypeOfProjectToInstall();
        } else if (relayReply.data === "NOT_INSTALLED") {
          this.isProjectInstalled = false;
        }
      };
      relayIframe["contentWindow"].postMessage("RELY_RUNNING", "*", [
        messageChannel.port2
      ]);

      // getting request from Gratis project code starts here
      window.addEventListener("message", evt => {
        if (evt.ports && evt.ports.length) {
          this.gratisPort = evt.ports[0];
          this.gratisPort.onmessage = event => {
            try {
              this.isGratis = true;
              console.log("gratis-message-in-vismaya-evt", event);
              const data = JSON.parse(event.data);
              if (data.action && data.action === "submitAnswer") {
                this.onSave(this.projectInfo);
                this.relayMessageChannel.postMessage(
                  JSON.stringify({ from: "GRATIS", action: "submitAnswer" })
                );
              } else if (data.action && data.action === "fork") {
                this.gratisForkProject();
              } else if (data.action && data.action === "topic_type") {
                this.topicType = data.topicType;
              }
            } catch (e) { }
          };
        }
      });
      // getting request from Gratis project code ends here
    });
  }

  async _findFileByType(type): Promise<any> {
    return Promise.resolve(
      this.projectFiles.find(
        file => file.value === `Solution.${type.toLowerCase()}`
      )
    );
  }

  reInstallProject() {
    this._setProjectVitals();
    this.refreshProject(this.slug);

    // this.testParse();
  }

  reInstallProjectOnUpload() {
    this._setProjectVitals();
    if (this.isPreviewShow) {
      console.log("reInstallProjectOnUpload requesting install");
      this.installProject();
    }
  }

  reloadProject(file) {
    const message = {
      action: "HOT-RELOAD",
      previewBaseURL: this.previewBaseURL,
      map: this.projectSystemJSMap,
      dependencies: this.projectDependencies,
      packages: this.projectSystemJSPackages,
      files: JSON.stringify(this.projectFiles),
      file,
      indexHtml: `<div id="root"></div>`,
      templateType: this.projectType,
      INDEX_JS: indexFiles[this.projectType],
      INDEX_HTML_PATH: indexHtml[this.projectType],
      externalResources: this.projectExternalResources
    };
    this.relayMessageChannel.postMessage(JSON.stringify(message));
  }

  refreshProject(file) {
    const { entryPoint, entryFile } = this.projectStructure["frontEndSettings"];
    const message = {
      action: "INSTALL",
      previewBaseURL: this.previewBaseURL,
      map: this.projectSystemJSMap,
      dependencies: this.projectDependencies,
      packages: this.projectSystemJSPackages,
      files: JSON.stringify(this.projectFiles),
      indexHtml: `<div id="root"></div>`,
      templateType: this.projectType,
      INDEX_JS: entryPoint || indexFiles[this.projectType],
      INDEX_HTML_PATH: entryFile || indexHtml[this.projectType],
      frontEndSettings: this.projectStructure["frontEndSettings"],
      backEndSettings: this.projectStructure["backEndSettings"],
      externalResources: this.projectExternalResources,
      packagesUrl: this.projectStructure["packagesUrl"]
    };
    console.info("refreshProject sending INSTALL action");
    this.relayMessageChannel.postMessage(JSON.stringify(message));
  }

  handleUpdatedSlugEvent(event) {
    this.reloadProject(this.slug);
  }

  testProject(file) {
    const message = {
      action: "TEST",
      map: this.projectSystemJSMap,
      previewBaseURL: this.previewBaseURL,
      packages: this.projectSystemJSPackages,
      files: JSON.stringify(this.projectFiles),
      file,
      test: `Vismaya welcomes you!!

This is a react project template using version:16.5.2

Start editing to see magic happen :-)

`,
      indexHtml: `<div id="root"></div>`,
      templateType: this.projectType,
      INDEX_JS: indexFiles[this.projectType],
      INDEX_HTML_PATH: indexHtml[this.projectType],
      externalResources: this.projectExternalResources
    };
    this.relayMessageChannel.postMessage(JSON.stringify(message));
  }

  _setProjectVitals() {
    this.projectDependencies = this.projectService.getProjectDependencies();
    this.projectExternalResources = this.projectService.getProjectExternalResources();
    this.projectSystemJSMap = this.projectService.getProjectSystemJSMap();
    this.projectSystemJSPackages = this.projectService.getProjectSystemJSPackages();
    this.projectFiles = this.projectService.getProjectFiles();
    this.isProjectLoaded = true;
    this.projectService.loadProjectFilesFromSocket({
      key: this.liveSessionKey,
      slug: this.slug,
      user: JSON.parse(localStorage.getItem("firebase-active-user"))
    });
  }

  onRunCode() {
    this.runCodeResponse = "compiling...";
    this.runCodeHasErrors = false;
    this.runCodeSuccess = false;
    console.log("files");
    console.log(this.projectFiles);

    const files = this.projectFiles.map(file => {
      return { file: file.value, value: file.content };
    });

    this.projectService.runInputOutputCode(files).subscribe((res: any) => {
      // console.log(res);
      if (res && res.compileError) {
        this.runCodeResponse = res.compileError;
        this.runCodeHasErrors = true;
      } else if (res && res.data) {
        if (res && res.data.includes("Main method not found ")) {
          this.runCodeResponse = res.data;
          this.runCodeHasErrors = true;
        } else {
          this.runCodeResponse = res.data;
          this.runCodeHasErrors = false;
        }
      }
      this.runCodeSuccess = true;
    });
  }

  onFilesToggle(data) {
    const frameHidden = this.iframe["nativeElement"]["hidden"];
    switch (data) {
      case "file":
        if (this.toggleExplorer === true && this.toggleFiles === true) {
          this.toggleExplorer = false;
          this.editorWidth = this.editorWidth + this.explorerWidth;
          if (this.isPreviewShow) {
            this.previewWidth = window.innerWidth - this.editorWidth;
          }
        } else if (this.toggleExplorer === true || this.toggleFiles === false) {
          this.toggleExplorer = true;
          this.toggleFiles = true;
          this.toggleSearch = false;
          this.toggleCollaborative = false;
          this.toggleImportGithubProject = false;
          this.toggleSettings = false;
          // this.explorerWidth = (window.screen.width * 20)/ 100;
          // this.previewWidth = (window.screen.width * 40)/ 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          }
        } else {
          this.toggleExplorer = true;
          this.toggleSearch = false;
          this.toggleCollaborative = false;
          this.toggleImportGithubProject = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        }
        if (frameHidden === true) {
          this.editorWidth = this.editorWidth + this.previewWidth;
        }
        if (!this.isPreviewShow) {
          if (this.toggleExplorer) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth = window.innerWidth;
          }
          // when editor showing only
          this.previewWidth = 0;
        }
        if (!this.isEditorShow) {
          if (this.toggleExplorer) {
            this.previewWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.previewWidth = window.innerWidth;
          }
          // when preview only visible
          this.editorWidth = 0;
        }
        break;
      case "search":
        if (this.toggleExplorer === true && this.toggleSearch === true) {
          this.toggleExplorer = false;
          this.editorWidth = this.editorWidth + this.explorerWidth;
          this.previewWidth =
            this.previewWidth + (this.explorerWidth * 40) / 100;
        } else if (
          this.toggleExplorer === true ||
          this.toggleSearch === false
        ) {
          this.toggleExplorer = true;
          this.toggleSearch = true;
          this.toggleCollaborative = false;
          this.toggleImportGithubProject = false;
          this.toggleFiles = false;
          this.toggleSettings = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        } else {
          this.toggleExplorer = true;
          this.toggleFiles = false;
          this.toggleCollaborative = false;
          this.toggleImportGithubProject = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        }
        if (frameHidden === true) {
          this.editorWidth = this.editorWidth + this.previewWidth;
        }
        break;
      case "gitHubImport":
        if (
          this.toggleExplorer === true &&
          this.toggleImportGithubProject === true
        ) {
          this.toggleExplorer = false;
          this.editorWidth = this.editorWidth + this.explorerWidth;
          this.previewWidth =
            this.previewWidth + (this.explorerWidth * 40) / 100;
        } else if (
          this.toggleExplorer === true ||
          this.toggleImportGithubProject === false
        ) {
          this.toggleExplorer = true;
          this.toggleImportGithubProject = true;
          this.toggleSearch = false;
          this.toggleCollaborative = false;
          this.toggleFiles = false;
          this.toggleSettings = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        } else {
          // if explorer toggled-off and trying to toggle-on from 'github icon'
          this.toggleExplorer = true;
          this.toggleImportGithubProject = true;
          this.toggleFiles = false;
          this.toggleSearch = false;
          this.toggleCollaborative = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        }
        if (frameHidden === true) {
          this.editorWidth = this.editorWidth + this.previewWidth;
        }
        if (!this.isPreviewShow) {
          if (this.toggleExplorer) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth = window.innerWidth;
          }
        }
        if (!this.isEditorShow) {
          if (this.toggleExplorer) {
            this.previewWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.previewWidth = window.innerWidth;
          }
        }
        break;
      case "settings":
        if (this.toggleExplorer === true && this.toggleSettings === true) {
          this.toggleExplorer = false;
          this.editorWidth = this.editorWidth + this.explorerWidth;
          this.previewWidth =
            this.previewWidth + (this.explorerWidth * 40) / 100;
        } else if (
          this.toggleExplorer === true ||
          this.toggleSettings === false
        ) {
          this.toggleExplorer = true;
          this.toggleFiles = false;
          this.toggleSearch = false;
          this.toggleCollaborative = false;
          this.toggleImportGithubProject = false;
          this.toggleSettings = true;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        } else {
          this.toggleExplorer = true;
          this.toggleFiles = false;
          this.toggleSearch = false;
          this.toggleCollaborative = false;
          this.toggleImportGithubProject = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;

          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        }
        if (frameHidden === true) {
          this.editorWidth = this.editorWidth + this.previewWidth;
        }
        break;
      case "collaborative":
        if (this.toggleExplorer === true && this.toggleCollaborative === true) {
          this.toggleExplorer = false;
          this.editorWidth = this.editorWidth + this.explorerWidth;
          this.previewWidth =
            this.previewWidth + (this.explorerWidth * 40) / 100;
        } else if (
          this.toggleExplorer === true ||
          this.toggleCollaborative === false
        ) {
          this.toggleExplorer = true;
          this.toggleCollaborative = true;
          this.toggleSearch = false;
          this.toggleFiles = false;
          this.toggleSettings = false;
          this.toggleImportGithubProject = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        } else {
          this.toggleExplorer = true;
          this.toggleFiles = false;
          this.toggleSearch = false;
          this.toggleImportGithubProject = false;
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
          if (this.isPreviewBottom) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth =
              window.innerWidth - (this.explorerWidth + this.previewWidth);
          }
        }
        if (frameHidden === true) {
          this.editorWidth = this.editorWidth + this.previewWidth;
        }
        if (!this.isPreviewShow) {
          if (this.toggleExplorer) {
            this.editorWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.editorWidth = window.innerWidth;
          }
        }
        if (!this.isEditorShow) {
          if (this.toggleExplorer) {
            this.previewWidth = window.innerWidth - this.explorerWidth;
          } else {
            this.previewWidth = window.innerWidth;
          }
        }
        break;
    }
    this.onResizeEdgeChanges();
  }

  onResizeEdgeChanges() {
    if (this.toggleExplorer) {
      this.resizeEdges = { bottom: false, right: true, top: false, left: true };
      if (this.isEditorShow && !this.isPreviewShow) {
        this.resizeEdges = {
          bottom: false,
          right: false,
          top: false,
          left: true
        };
      }
    } else {
      this.resizeEdges = {
        bottom: false,
        right: true,
        top: false,
        left: false
      };
      if (this.isEditorShow && !this.isPreviewShow) {
        this.resizeEdges = {
          bottom: false,
          right: false,
          top: false,
          left: false
        };
      }
    }
  }

  onExternalResourceAdded(refUrl) {
    this.enableSave = true;
    this.ngProgress.start();
    this.projectService.setProjectExternalResources(
      this.projectExternalResources
    );
    const message = {
      type: refUrl.match(/css/) ? "externalcss" : "externaljs",
      value: refUrl,
      previewBaseURL: this.previewBaseURL,
      action: "ADD"
    };
    this.relayMessageChannel.postMessage(JSON.stringify(message));
    this.toastr.success("external resource added successfully", "", {
      timeOut: 5000,
      toastClass: "vismaya-toastr toast",
      positionClass: "toast-bottom-right"
    });
    this.ngProgress.done();
  }

  onExternalResourceRemoved(refUrl) {
    this.enableSave = true;
    this.ngProgress.start();
    this.projectService.setProjectExternalResources(
      this.projectExternalResources
    );
    const message = {
      type: refUrl.match(/css/) ? "externalcss" : "externaljs",
      value: refUrl,
      previewBaseURL: this.previewBaseURL,
      action: "REMOVE"
    };
    this.relayMessageChannel.postMessage(JSON.stringify(message));
    this.ngProgress.done();
    this.toastr.success("external resource removed successfully", "", {
      timeOut: 5000,
      toastClass: "vismaya-toastr toast",
      positionClass: "toast-bottom-right"
    });
  }

  onFrameHide(frame) {
    if (frame === true) {
      if (this.toggleExplorer === true) {
        this.iframe["nativeElement"]["hidden"] = false;
        this.explorerWidth = (window.innerWidth * 15) / 100;
        this.previewWidth = (window.innerWidth * 40) / 100;
        this.editorWidth =
          window.innerWidth - (this.explorerWidth + this.previewWidth);
      } else {
        this.explorerWidth = (window.innerWidth * 15) / 100;
        this.previewWidth = (window.innerWidth * 40) / 100;
        this.editorWidth = this.explorerWidth + this.previewWidth;
        this.previewWidth = this.previewWidth + (this.editorWidth * 45) / 100;
        this.iframe["nativeElement"]["hidden"] = false;
        return;
      }
    } else if (frame === false) {
      this.iframe["nativeElement"]["hidden"] = true;
      this.explorerWidth = (window.innerWidth * 15) / 100;
      this.previewWidth = (window.innerWidth * 40) / 100;
      this.editorWidth = this.editorWidth + this.previewWidth;
    }
  }

  onFileMovedHandler(event) {
    this.reloadProject(this.slug);
  }

  onCodeChange(file) {
    // console.log("code change");
    this.enableSave = true;
    if (typeof file !== "string") {
      return;
    }
    this.editorContent = file;
    if (!this.isProjectInstalled) {
      if (this.projectStructure["frontEndSettings.entryPoint"]) {
        if (this.isPreviewShow) {
          console.log("onCodeChange requesting install");
          this.installProject();
        }
      }
      this.isProjectInstalled = true;
    } else {
      const message = {
        action: "HOT-RELOAD",
        previewBaseURL: this.previewBaseURL,
        map: this.projectSystemJSMap,
        dependencies: this.projectDependencies,
        packages: this.projectSystemJSPackages,
        slug: this.slug,
        templateType: this.projectType,
        INDEX_JS: indexFiles[this.projectType],
        INDEX_HTML_PATH: indexHtml[this.projectType],
        files: JSON.stringify(this.projectFiles),
        file,
        indexHTML: `<div id="root"></div>`,
        frontEndSettings: this.projectStructure["frontEndSettings"],
        backEndSettings: this.projectStructure["backEndSettings"]
      };
      this.relayMessageChannel.postMessage(JSON.stringify(message));
    }
  }

  installProject() {
    const message = {
      action: "INSTALL",
      previewBaseURL: this.previewBaseURL,
      map: this.projectSystemJSMap,
      dependencies: this.projectDependencies,
      packages: this.projectSystemJSPackages,
      files: JSON.stringify(this.projectFiles),
      indexHtml: `<div id="root"></div>`,
      templateType: this.projectType,
      INDEX_JS: indexFiles[this.projectType],
      INDEX_HTML_PATH: indexHtml[this.projectType],
      frontEndSettings: this.projectStructure["frontEndSettings"],
      backEndSettings: this.projectStructure["backEndSettings"],
      externalResources: this.projectExternalResources,
      packagesUrl: this.projectStructure["packagesUrl"]
    };
    // console.info("installProject sending INSTALL action");
    this.relayMessageChannel.postMessage(JSON.stringify(message));
  }

  onProjectInfoChanges(event) {
    this.projectInfo = event;
    this.onSave(event);
  }

  performAction(action) {
    this.projectService.performAction.next(action);
  }

  onPerformAction(performedEvent) {
    console.log("at project component==>", performedEvent);
    this.action = performedEvent;
    performedEvent = "";
  }
  onSave(projectInfo?) {
    // this.codeEditorChild.formatCode();
    if (this.isEmbedded) {
      this.saveProject(projectInfo);
    } else {
      this.saveProject(projectInfo);
    }
  }

  saveProject(projectInfo) {
    this.ngProgress.start();
    this.projectFiles.forEach(file => {
      file.isDirty = false;
    });
    this.projectService.save(projectInfo).subscribe(
      r => {
        this.ngProgress.done();
        this.enableSave = false;
        try {
          this.projectService.sendProjectSavedEvent({
            key: this.liveSessionKey,
            slug: this.slug,
            user: JSON.parse(localStorage.getItem("firebase-active-user")),
            localPath: this.route.snapshot["queryParams"]["file"]
          });
        } catch (e) { }
      },
      err => {
        console.log(err);
        this.ngProgress.done();
        this.toastr.error(err.message, "", {
          timeOut: 5000,
          toastClass: "vismaya-toastr toast",
          positionClass: "toast-bottom-right"
        });
      }
    );
  }

  onNpmPackageAdded(packageName, swal) {
    console.log(packageName);
    this.enableSave = true;
    this.updateDependencies(
      this.projectDependencies,
      swal,
      { action: "ADD" },
      true
    );
  }

  npmServerPackageAdded(packageName, swa) {
    console.log(packageName);
    this.projectService.addPackage(packageName);
    // this.updateDependencies(this.projectDependencies, swal, { action: "ADD" }, true);
  }

  onNpmPackageRemoved(packageInfo, swal) {
    console.log(packageInfo);
    this.enableSave = true;
    this.ngProgress.start();
    const filteredDependencies = this.projectDependencies.filter(
      d => Object.keys(d)[0] !== Object.keys(packageInfo)[0]
    );
    this.projectService.setProjectDependencies(filteredDependencies);
    this.updateDependencies(
      filteredDependencies,
      swal,
      { action: "DELETE" },
      true
    );
  }

  updateDependencies(filteredDependencies, swal, { action }, isReload?) {
    this.resolveDependencies(null, null, swal, { action });

    this.installProject();

    this.ngProgress.done();
    // this.projectService
    //   .amendDependency(Object.assign({}, ...filteredDependencies))
    //   .subscribe(
    //     meta => {
    //       this.resolveDependencies(null, meta, swal, { action });
    //       if (isReload) {
    //         this.reloadProject(this.slug);
    //       }
    //       this.ngProgress.done();
    //     },
    //     error => {
    //       this.resolveDependencies(error, null, swal, { action });
    //     }
    //   );
  }

  resolveDependencies(error, meta = null, swal, { action }) {
    if (error) {
      const message = error.error.error.error;
      switch (message) {
        case "MISSING_PEERS":
          const data = error.error.error.data;
          const missings = Object.keys(data);
          const versions = {};

          missings.map(missing => {
            this.warningMsg = `${Object.keys(data[missing]).join(
              ","
            )} required ${missing}`;
          });
          setTimeout(() => {
            // tslint:disable-next-line: no-unused-expression
            swal && swal.show();
          });

          this.projectService
            .installPackages(this.projectDependencies)
            .subscribe(installInfo => {
              const dependencies = [];
              for (const v of Object.keys(installInfo["versions"])) {
                dependencies.push({ [v]: installInfo["versions"][v] });
              }
              this.projectDependencies = dependencies;
              this.projectService.setProjectDependencies(dependencies);
              this.updateDependencies(this.projectDependencies, swal, {
                action: "RESOLVED"
              });
            });
          break;
        default:
          break;
      }
    } else {
      switch (action) {
        case "ADD":
          // console.log("meta",meta)
          setTimeout(() => {
            this.addPackagesToPackageJson();
          });
          this.toastr.success("Dependencies added successfully", "", {
            timeOut: 5000,
            toastClass: "vismaya-toastr toast",
            positionClass: "toast-bottom-right"
          });
          break;
        case "DELETE":
          this.toastr.success("Dependencies removed successfully", "", {
            timeOut: 5000,
            toastClass: "vismaya-toastr toast",
            positionClass: "toast-bottom-right"
          });
          // console.log("meta",meta)
          setTimeout(() => {
            this.addPackagesToPackageJson();
          });
          break;
        default:
          break;
      }

      // if(action === "ADD") {
      //   this.toastr.success("Dependencies added")
      // }
      // this.projectService.remapProjectMeta(meta);
      this._setProjectVitals(); // reload project vitals
    }
  }

  addPackagesToPackageJson() {
    let packageJsonIndex = this.projectFiles.findIndex(
      file => file.value === "package.json"
    );
    if (packageJsonIndex !== -1) {
      let content = JSON.parse(this.projectFiles[packageJsonIndex].content);
      content.dependencies = {};
      this.projectDependencies.forEach(dependency => {
        let packageName = Object.keys(dependency)[0];
        content.dependencies[packageName] = dependency[packageName];
      });
      setTimeout(() => {
        this.projectFiles[packageJsonIndex].content = JSON.stringify(content);
      });
    }
    if (this.userIsAdmin() && this.isLive) {
      const data = {};
      data[
        "projectDependencies"
      ] = this.projectService.getProjectDependencies();
      data[
        "projectExternalResources"
      ] = this.projectService.getProjectExternalResources();
      data["projectSystemJSMap"] = this.projectService.getProjectSystemJSMap();
      data[
        "projectSystemJSPackages"
      ] = this.projectService.getProjectSystemJSPackages();

      this.projectService.sendNewProjectDependenciesToSocket({
        key: this.liveSessionKey,
        data,
        slug: this.slug,
        user: JSON.parse(localStorage.getItem("firebase-active-user"))
      });
    }
  }
  topeditorWidth = 0;
  bottomSidebarWidth = 0;
  isPreviewRight = true;
  isPreviewBottom = false;
  onPreviewRight() {
    this.isPreviewRight = true;
    this.isPreviewBottom = false;
    if (this.toggleExplorer) {
      this.editorWidth =
        window.innerWidth - (this.explorerWidth + this.previewWidth);
    } else {
      this.editorWidth = window.innerWidth - this.previewWidth;
    }
  }

  onPreviewBottom() {
    this.isPreviewRight = false;
    this.isPreviewBottom = true;
    if (this.toggleExplorer) {
      this.editorWidth = window.innerWidth - this.explorerWidth;
      this.bottomSidebarWidth = window.innerWidth - (this.explorerWidth + 42);
    } else {
      this.editorWidth = window.innerWidth;
      this.bottomSidebarWidth = window.innerWidth - 42;
    }
  }

  isTogglePreview = true;

  onTogglePreview(event) {
    this.isTogglePreview = event;
    if (event) {
      if (!this.isPreviewBottom) {
        if (this.toggleExplorer) {
          this.editorWidth =
            window.innerWidth - (this.explorerWidth + this.previewWidth);
        } else {
          this.editorWidth = window.innerWidth - this.previewWidth;
        }
      } else {
        if (this.toggleExplorer) {
          this.bottomSidebarWidth = this.editorWidth - 43;
        } else {
          this.bottomSidebarWidth = this.editorWidth - 51;
        }
      }
    } else {
      if (this.toggleExplorer) {
        this.editorWidth = window.innerWidth - this.explorerWidth;
      } else {
        this.editorWidth = window.innerWidth;
      }
    }
  }

  onNewWindowPreview() {
    this.onTogglePreview(!this.isTogglePreview);
  }

  onEmbeddedOptions() {
    switch (this.radioModel) {
      case "both":
        this.isPreviewShow = true;
        this.isEditorShow = true;
        this.toggleExplorer = false;
        if (this.toggleExplorer) {
          this.explorerWidth = (window.innerWidth * 15) / 100;
          this.previewWidth = (window.innerWidth * 40) / 100;
        } else {
          this.explorerWidth = 0;
          this.previewWidth = (window.innerWidth * 50) / 100;
        }
        this.editorWidth =
          window.innerWidth - (this.explorerWidth + this.previewWidth);

        // if(window.screen.width < 1100){
        //   this.explorerWidth = 100;
        //   this.previewWidth = 500;
        //   this.editorWidth = 500;
        // } else {
        // let totalWidth = (window.screen.width * 60) / 100;
        // console.log("total width", totalWidth);
        // if(this.toggleExplorer){
        //   this.explorerWidth = (totalWidth * 20) / 100;
        //   this.previewWidth = (totalWidth * 40) / 100;
        // } else {
        //   this.explorerWidth = 0;
        //   this.previewWidth = (totalWidth * 60) / 100;
        // }
        // this.editorWidth = (totalWidth * 40) / 100;
        // }

        // this.reloadProject(this.slug);
        break;
      case "preview":
        this.isPreviewShow = true;
        this.isEditorShow = false;
        this.toggleExplorer = false;
        this.explorerWidth = 0;
        this.editorWidth = 0;
        this.previewWidth = window.innerWidth;
        this.explorerWidth = 0;
        this.editorWidth = 0;
        // this.reloadProject(this.slug);
        break;
      case "editor":
        this.isPreviewShow = false;
        this.isEditorShow = true;
        this.toggleExplorer = false;
        this.explorerWidth = 0;
        this.previewWidth = 0;
        this.editorWidth = window.innerWidth;
        // this.reloadProject(this.slug);
        break;
    }
    this.onResizeEdgeChanges();
  }
  onShareChanges(event) {
    this.isCollapsed = event;
  }

  closeShare() {
    if (!this.isCollapsed) {
      this.isCollapsed = true;
    }
  }

  // fork project for Gratis usage only
  gratisForkProject() {
    this.projectService.forkProjectFromProject(this.slug).subscribe(
      response => {
        if (response["status"] == 200) {
          localStorage.setItem(
            "template-uid",
            JSON.stringify(response["project"]["uid"])
          );
          let params = this.route.snapshot["queryParams"]["file"];
          this.projectType = response["project"]["name"];
          this.router.navigate(
            [`${response["project"]["name"]}/${response["project"]["id"]}`],
            { queryParams: { file: params } }
          );
          setTimeout(() => {
            this.relayMessageChannel.postMessage(
              JSON.stringify({
                from: "GRATIS",
                action: "SEND_FORK_URL",
                url: `${window.location.href}`
              })
            );
            // window.location.reload();
            // this.router.routeReuseStrategy.shouldReuseRoute = function () {
            //   return false;
            // };
            // this.ngProgress.done();
          }, 1000);
        } else {
          window.alert(response["error"]);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line: member-ordering
  timeoutForIframe = null;
  // server side code changes
  installServerProject() {
    this.terminalPreview = true;
    const localStorageObject = this.projectService.getLocalStorageData(
      "dockerInfo"
    )[0];

    if (!localStorageObject) {
      const localObject = {
        dockerId: this.makeCode(25),
        slug: this.slug,
        projectName: this.projectName,
        isShellOnly: this.projectStructure["settings"].isShellOnly || false,
        isMongo: this.projectName === "mongo" ? true : false
      };
      this.projectService.setLocalStorageData("dockerInfo", localObject);
    } else if (
      localStorageObject &&
      Math.abs((Date.now() - localStorageObject.localStorageDate) / 1000) >
      1000 * 60 * 2
    ) {
      const localObject = {
        dockerId: this.makeCode(25),
        slug: this.slug,
        projectName: this.projectName,
        isShellOnly: this.projectStructure["settings"].isShellOnly || false,
        isMongo: this.projectName === "mongo" ? true : false
      };
      this.projectService.setLocalStorageData("dockerInfo", localObject);
    } else {
      this.projectService.updateLocalStorageData(
        "dockerInfo",
        localStorageObject
      );
    }

    const settings: any =
      (this.projectStructure && this.projectStructure["settings"]) || {};

    if (settings && !settings.isShellOnly) {
      this.projectService.createDocker();
    }
  }

  /**
   * makeCode
   * generate the @num digit unique code
   */
  makeCode(num) {
    let text = "";
    const possibleChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < num; i++) {
      text += possibleChars.charAt(
        Math.floor(Math.random() * possibleChars.length)
      );
    }
    return text;
  }

  loadFilesToDocker() {
    console.log("loadedDocker");
    this.projectService.saveFilesOnDocker(this.slug);
  }

  async onRun() {
    // this._setUserInfo({
    //   firstName: "vasista",
    //   lastName: "reddy",
    //   id: "1234567890"
    // });
    const settings: any =
      (this.projectStructure && this.projectStructure["settings"]) || {};

    this.onSave(this.projectInfo);

    if (settings && settings.isShellOnly) {
      this.terminals.get(0).terminal.clear();
      this.projectService.createDocker();
    } else {
      setTimeout(() => {
        this.previewLoaded = false;
        this.projectService.saveFileOnDocker(this.projectFiles);
      }, 500);
    }
  }

  onTestSubmit() {
    // this.projectService.runTest();
    // this.testParse();
    this.previewLoaded = false;
    if (this.isBackEnd) {
      this.projectService.runTest();
    } else {
      this.projectService.runFrontEndTest(this.slug).subscribe(
        res => {
          console.log(res);
          this.previewLoaded = true;
        },
        err => {
          console.log("no test");
        }
      );
    }
  }

  onSaveFileData() {
    // this.dockerOnSaveFile = this.projectService
    //   .onSaveFileData()
    //   .subscribe(data => {
    //     console.log(data);
    //     if (data["isFirstTime"] === false) {
    //       this.projectService.runCode(this.projectEntryPoint);
    //     }
    //   });
  }

  async testParse() {
    const testCases = [];
    await this.projectService.getProjectFiles().map(async f => {
      if (
        (f.value.includes(".test.") || f.value.includes(".spec.")) &&
        !f.value.includes(".js.snap")
      ) {
        const tests = await this.matchTests(f.content);
        testCases.push(tests);
      }
    });
    console.log(testCases);
    return testCases;
    // this.testParser(content);
  }

  matchTests(content) {
    const language: string = "javascript";
    const tests = [];

    let regex;
    let regex2;
    let regexParent;
    let regexParent2;
    switch (language) {
      case "javascript":
        regex = /it\s[(].*/g;
        regex2 = /it\('\s*(.*?)\s*'/g;
        regexParent = /describe.*$/gm;
        regexParent2 = /([^']+)'([^']+)/g;
        content = content.replace(/"+/g, "'");
        break;

      case "python":
        regex = /def.*$/gm;
        regex2 = /def\s*(.*?)\s*\(/g;
        break;

      case "ruby":
        regex = /def.*$/gm;
        regex2 = /\sdef\s*(.*?)\s*\s/g;
        break;

      case "php":
        regex = /test.*$/gm;
        regex2 = /test([a-zA-Z])+/g;
        break;
    }
    const arrays = [];
    const allLines = content.toString().split("\n");
    let testParent = 0;
    allLines.map(line => {
      const newline = line.replace(/it+/, "it ");
      const lineOfMatch = regex.exec(newline);
      const parentMatch = regexParent.exec(newline);
      if (parentMatch) {
        const parentTestName = /([^']+)'([^']+)/g.exec(parentMatch[0]);
        if (parentTestName) {
          tests.push({ parent: parentTestName[2], tests: [] });
          // parentName = parentTestName[2];
          testParent = testParent + 1;
        }
      }

      if (lineOfMatch) {
        const testName = /([^']+)'([^']+)/g.exec(lineOfMatch[0]);

        if (testName) {
          arrays.push({
            suitName: tests[testParent - 1].parent,
            name: testName[2],
            marks: 0
          });
        }
      }
    });
    // allLines.map(line => {
    //   const lineOfMatch = regex.exec(line);
    //   const parentMatch = regexParent.exec(line);

    //   if (lineOfMatch) {
    //     const testName = /([^']+)'([^']+)/g.exec(lineOfMatch[0]);

    //     if (testName) {
    //       tests.push({ name: testName[2], weightage: 0 });
    //     }
    //   }
    //   if (parentMatch) {
    //     const parentTestName = /([^']+)'([^']+)/g.exec(parentMatch[0]);
    //     if (parentTestName) {
    //       parentName = parentTestName[2];
    //     }
    //   }
    // });
    return arrays;
  }

  async runTestCases() {

    const files = this.projectService.getProjectFiles() as [any];
    const testCaseFile = files.find(file => file.value.includes('test.spec.json'))
    if (!testCaseFile) {
      return []
    }
    const testCases = await this._testParse(testCaseFile, files);
    console.log(testCases)
    return testCases
    // this._openModal('TEST_CASES', testCases);
  }

  async _testParse(testCaseFile, files) {
    const testCasesOutput = [];
    const { value, content } = testCaseFile;
    const testCases = JSON.parse(content);

    testCases.suites.map((testCase) => {
      const fileToGetContent = testCase.fileToCheck;

      const fileToTest = files.find(
        (f) => `${f.path === '/' ? '' : f.path}/${f.value}` === fileToGetContent
      );

      const { content: fileToTestContent = '' } = fileToTest || {} as { content: string };

      const isPassed = this.checkIfTagExists(
        fileToTestContent && fileToTestContent.replace(/\s/g, ''),
        testCase.spec[0].replace(/\s/g, '')
      );
      testCasesOutput.push({ suiteName: testCase.suiteName, name: testCase.case, status: isPassed ? "passed" : 'failed', failureMessage: isPassed ? null : (testCase.hint ? testCase.hint : testCase.case) });

    })
    return testCasesOutput;
  }

  checkIfTagExists(str, tag) {
    return str && str.indexOf(tag) > -1 ? true : false;
  }


  onFrontEndTerminalClick() {
    document.getElementById("frontEndTerminalInput").focus();
  }

  installDependencies() {
    console.log("eventTriggered");
    this.zlDependencies.installPack();
  }

  // terminalToConsole(e) {
  //   eval(e.value);
  // }

  // server side code change end

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    const relayIframe = document.getElementById("relayIframe");
    // Wait for the relayIframe to load
    relayIframe.removeEventListener("load", () => { });
    window.removeEventListener("load", () => { });

    this.unsubscribeDockerEvents();
  }

  resizeEnd() {
    setTimeout(() => {
      this.terminals.get(this.isTerminalOpen).fitAddOn.fit();
    }, 10);
  }

  terminalOpen(id) {
    this.isTerminalOpen = id;
  }

  addTerminal(id) {
    setTimeout(() => {
      if (id < 3) {
        this.isTerminalOpen = id;
        const container = document.getElementById(`terminal${id}`);
        const terminal = new Terminal({
          theme: {
            background: "#262626"
          }
        });
        const fitAddOn = new FitAddon();
        this.terminals.set(id, { terminal, fitAddOn, id, loaded: false });
        this.terminals
          .get(id)
          .terminal.loadAddon(this.terminals.get(id).fitAddOn);
        this.terminals.get(id).terminal.open(container);
        this.terminals.get(id).fitAddOn.fit();

        this.terminals.get(id).terminal.writeln(`Welcome to IRA ${id}`);
        this.projectService.openExtraTerminal(id);
        this.terminals.get(id).terminal.onData(data => {
          this.projectService.openExtraTerminalWrite(
            this.terminals.get(id).id,
            data
          );
        });
      }
    }, 500);
    setTimeout(() => {
      const terminalObj = this.terminals.get(id);
      console.log(terminalObj.loaded);
      if (terminalObj && terminalObj.loaded === false) {
        console.log(this.terminals.get(id).terminal);
        terminalObj.loaded = true;
        this.terminals.get(id).terminal.write("\n\r Please hit enter");
      }
    }, 1000);
  }

  checkTypeOfProjectToInstall() {
    // if (!this.isProjectInstalled) {
    this.pageLoading = false;
    let dockerAlreadyLoaded;
    const frontEndSettings: any =
      (this.projectStructure && this.projectStructure["frontEndSettings"]) ||
      {};
    const backEndSettings: any =
      (this.projectStructure && this.projectStructure["backEndSettings"]) || {};

    // TODO:vasista fix issue related to isProjectInstalled
    if (frontEndSettings && frontEndSettings.entryPoint) {
      if (this.isPreviewShow) {
        // console.log("checkTypeOfProjectToInstall1 requesting install");
        this.installProject();
      }
    }

    if (!this.isProjectInstalled) {
      if (frontEndSettings && frontEndSettings.entryPoint) {
        this.isFrontEnd = true;
        this.isOnlyPreview = false;
        // this.pageLoading = false;
        this.isMongo = false;
        // if (this.isPreviewShow) {
        //   console.log("checkTypeOfProjectToInstall2 requesting install");
        //   this.installProject();
        // }
        this.isConsoleBarHidden = false;
        // console.log("ready started");
      } else if (frontEndSettings && frontEndSettings.isSingleProblem) {
        // if (

        //   this.projectName === "c" ||
        //   this.projectName === "cpp" ||
        //   this.projectName === "java" ||
        //   this.projectName === "python"
        // ) {
        this.isConsoleBarHidden = true;
        // this.pageLoading = false;
        this.isFrontEnd = true;
        this.isPreviewShow = false;
        this.isTogglePreview = false;
        // }
      }
      setTimeout(() => {
        this.pageLoading = false;
      }, 1000);
      if (backEndSettings && backEndSettings.entryPoint) {
        this.isBackEnd = true;
        dockerAlreadyLoaded = this.projectService.getLocalStorageData(
          "dockerInfo"
        )[0];
        this.installServerProject();
        this.isProjectInstalled = true;
      }

      if (backEndSettings && backEndSettings.isSingleProblem) {
        // this.isPreviewShow = false;
        // this.isTogglePreview = false;
      }

      let container;

      const settings: any =
        (this.projectStructure && this.projectStructure["settings"]) || {};

      if (this.isBackEnd) {
        if (settings.isShellOnly === true) {
          this.previewObj.isShellOnly = true;
          container = document.getElementById("terminal-full");
          // this.menuInputs.isShowFile = false;
          // this.menuInputs.isShowEdit = false;
          // this.menuInputs.isShowTest = true;
          // this.menuInputs.isShowRun = false;
          // this.menuInputs.isShowSave = false;
          // this.isShellOpened = true;
          this.isShowUrlBar = false;
        } else if (backEndSettings.isOnlyShell === true) {
          this.isOnlyPreview = true;
          this.isMongo = true;
          this.isShowExplorer = false;
          this.isShowEditor = false;
          this.isTogglePreview = false;
          this.showResponseView = false;
          container = document.getElementById("terminal-full");
          this.menuInputs.isShowFile = false;
          this.menuInputs.isShowEdit = false;
          this.menuInputs.isShowTest = true;
          this.menuInputs.isShowRun = false;
          this.menuInputs.isShowSave = false;
          this.getMongoInitialData();
        } else {
          this.isOnlyPreview = false;
          container = document.getElementById("terminal");
          this.isMongo = false;
        }
        setTimeout(() => {
          this.pageLoading = false;
        }, 1000);

        const terminal = new Terminal({
          theme: {
            background: "#262626"
          },
          fontWeight: "700",
          fontSize: 14,
          lineHeight: 1.86,
          letterSpacing: 0.5,
          cursorStyle: "bar",
          cursorBlink: true
        });
        const fitAddOn = new FitAddon();
        this.terminals.set(0, { terminal, fitAddOn });
        this.terminals.get(0).terminal.loadAddon(fitAddOn);
        const activeTab = this.mainTabset.tabs.filter(tab => tab.active);
        this.terminals.get(0).terminal.open(container);
        if (activeTab[0].id === "terminalTab") {
          this.terminals.get(0).fitAddOn.fit();
        }

        this.terminals.get(0).terminal.writeln("Welcome to IRA");

        this.terminals.get(0).terminal.onData(data => {
          this.projectService.terminal(data);
        });
        this.previewLoaded = false;
        let terminalOutputLoaded = 0;
        // this.getTerminalSubscribe = this.projectService
        //   .getTerminalOutput()
        //   .subscribe(data => {
        //     const terminalOutput = data.toString().match(/[^\r\n]+/g);
        //     if (terminalOutput) {
        //       terminalOutput.map(t =>
        //         this.terminals.get(0).terminal.writeln(t)
        //       );
        //     }
        //     terminalOutputLoaded = 1;
        //   });

        if (dockerAlreadyLoaded) {
          const waitingForTerminal = setInterval(() => {
            if (terminalOutputLoaded === 1) {
              clearInterval(waitingForTerminal);
            } else {
              // this.terminals.get(0).terminal.writeln("\n\rloaded");
              const settings: any =
                (this.projectStructure && this.projectStructure["settings"]) ||
                {};
              if (!backEndSettings.isOnlyShell && !settings.isShellOnly) {
                this.onRun();
              }
              clearInterval(waitingForTerminal);
              // this.projectService.terminal("\n");
            }
          }, 2000);
        }

        // this.terminalSubscribe = this.projectService
        //   .onTerminalData()
        //   .subscribe((data: any) => {
        //     if (data.id) {
        //       const terminalObj = this.terminals.get(data.id);
        //       if (!terminalObj.loaded) {
        //         terminalObj.loaded = true;
        //         this.terminals.set(data.id, terminalObj);
        //       }
        //       if (data.shellData !== "ENDED") {
        //         this.terminals.get(data.id).terminal.write(data.shellData);
        //       }
        //     } else {
        //       if (data !== "ENDED") {
        //         this.terminals.get(0).terminal.write(data);
        //       }
        //     }
        //     // terminalOutputLoaded = 1;
        //   });
      }

      if (
        frontEndSettings &&
        frontEndSettings.entryPoint &&
        !(backEndSettings && backEndSettings.entryPoint)
      ) {
        this.previewLoaded = true;
      }

      this.isProjectInstalled = true;
    }
    // }
  }

  terminalFit() {
    console.log("vasista");
    setTimeout(() => {
      this.terminals.get(0).fitAddOn.fit();
    }, 10);
  }

  @HostListener("window:beforeunload", ["$event"]) beforeUnloadHander(
    event: any
  ) {
    this.unsubscribeDockerEvents();
  }

  getMongoInitialData() {
    // const toggler = document.getElementsByClassName("treecaret");
    // let i;

    // for (i = 0; i < toggler.length; i++) {
    //   toggler[i].addEventListener("click", function() {
    //     this.parentElement
    //       .querySelector(".treenested")
    //       .classList.toggle("active");
    //     this.classList.toggle("caret-down");
    //   });
    // }

    this.getMongoCollectionByDatabse(this.slug);
    // this.getMongoDatabases();
  }

  getMongoDatabases() {
    this.projectService.getMongoDatabases().subscribe((res: any) => {
      this.mongoDatabases =
        res.databases.filter(e => e.name === this.slug) || [];
      let id = 0;

      this.isDataBaseCreated = true;
      this.mongoDatabases.map(e => {
        id = id + 1;
        e.id = id;
        e.isActive = false;
        this.getMongoCollectionByDatabse(e.name);
      });
    });
  }

  getMongoCollectionByDatabse(db) {
    this.projectService
      .getMongoCollectionByDatabse(db)
      .subscribe((res: any) => {
        this.mongoDatabases = res;
        this.isDataBaseCreated = true;
        if (this.isCollectionOpened === false) {
          this.isCollectionOpened = true;
          this.openCollection(db, res[0] && res[0].name);
        }

        // this.mongoDatabases.map(d => {
        //   if (d.name === db) {
        //     if (this.isCollectionOpened === false) {
        //       this.isCollectionOpened = true;
        //       this.openCollection(db, res[0].name);
        //     }
        //     d.collections = res;
        //   }
        // });
      });
  }

  openDatabase(db) {
    this.mongoDatabases.map(d => {
      if (d.name === db) {
        d.isActive = !d.isActive;
      }
    });
  }

  openCollection(db, collection) {
    this.collectionSelected = collection;
    this.selectedCollection = { db, collection };
    document.getElementById("mongo-data").innerHTML = "";

    this.projectService
      .getMongoDataByCollection(db, collection)
      .subscribe((res: any) => {
        res.map(e => {
          const formatter = new JSONFormatter(e, 1, {
            hoverPreviewEnabled: false
          });

          const format = formatter.render();

          if (document.getElementById("mongo-data")) {
            document.getElementById("mongo-data").appendChild(format);
          }
        });
      });
  }

  async onMongoExecute(mongoTerminalForm) {
    let mongoCode = mongoTerminalForm
      .replace(/getCollection/gi, "collection")
      .split("\n");

    mongoCode = mongoCode.filter(entry => {
      return entry.trim() !== "";
    });

    const [err, result] = (await to(this.execiuteMongoQuery(mongoCode))) as any;
    if (err) {
      console.log(err);
    }

    if (this.isDataBaseCreated === false) {
      this.getMongoDatabases();
    } else {
      if (
        !mongoCode.toString().includes("find") &&
        !mongoCode.toString().includes("findOne")
      ) {
        this.getMongoCollectionByDatabse(this.slug);
      } else {
        const res = Array.isArray(result) ? result : [result];
        console.log(res);
        res.map(e => {
          const formatter = new JSONFormatter(e, 1, {
            hoverPreviewEnabled: false
          });

          const format = formatter.render();
          document.getElementById("mongo-data").innerHTML = "";
          if (document.getElementById("mongo-data")) {
            document.getElementById("mongo-data").appendChild(format);
          }
        });
      }
      // const collection = mongoCode
      //   .toString()
      //   .match(/\(([^)]+)\)/)[1]
      //   .replace(/"/g, "");
      // alert(collection);
      // this.openCollection(this.slug, collection);
    }

    // if (this.isDataBaseCreated === false) {
    //   this.getMongoDatabases();
    // } else {
    //   this.getMongoCollectionByDatabse(this.slug);
    // }

    // mongoCode.map(code => {
    //   if (code && code !== "") {
    //     console.log(code);
    //     this.projectService
    //       .onMongoExecute(this.selectedCollection.db, code)
    //       .subscribe((res: any) => {
    //         // mongoTerminalForm.reset();
    //         if (this.isDataBaseCreated === false) {
    //           this.getMongoDatabases();
    //         } else {
    //           this.getMongoCollectionByDatabse(this.slug);
    //         }
    //       });
    //   }
    // });
  }

  async execiuteMongoQuery(mongoCode) {
    return Promise.all(
      mongoCode.map(async code => {
        if (code) {
          const [err, res] = await to(
            this.projectService
              .onMongoExecute(this.selectedCollection.db, code)
              .toPromise()
          );
          if (err) {
            console.log(err);
          }
          return Promise.resolve(res);
        }
      })
    );
  }

  shellOpenLink() {
    this.isShellOpened = !this.isShellOpened;
  }

  async _runSingleProblem(input): Promise<any> {
    const fileName = this.route.snapshot["queryParams"]["file"].replace(
      /^.*[\\\/]/,
      ""
    );

    const extension = fileName.substring(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    const language = languages(extension)[0];
    return await this.projectService.runSingleProblem(language, input);
  }

  _setUserInfo(userData) {
    const { id, firstName, lastName } = userData;
    const firebaseUser = this.projectService.getLocalStorage(
      "firebase-active-user"
    ) as any;
    firebaseUser.uid = id.includes("AUTH") ? id : `${id}-IRA`;
    firebaseUser.name = `${firstName} ${lastName}`;
    firebaseUser.isVismaya = false;
    this.projectService.setLocalStorage("firebase-active-user", firebaseUser);
    return true;
  }

  async recursiveFileCreation(arr) {
    return Promise.all(
      arr.map((el, index) => {
        const count = index + 1;
        const newDir = arr.slice(0, count).join("/");
        if (!this.fs.existsSync(newDir)) {
          this.fs.mkdir(newDir, err => {
            if (err) {
              if (err.code !== "EEXIST") {
                console.log(err);
              }
            }
          });
        }
        return Promise.resolve(count);
      })
    );
  }

  async loadFilesToBrowserFs(data) {
    return Promise.all(
      Object.keys(data).map(async content => {
        const directory = content.substring(0, content.lastIndexOf("/") + 1);
        const arr = directory.split("/").filter(Boolean);

        const [err, createdDirectory] = await to(
          this.recursiveFileCreation(arr)
        );

        if (err) {
          console.log(err);
        }

        this.fs.writeFileSync(content, data[content].content);

        return Promise.resolve(1);
      })
    );
  }

  async getData(): Promise<any> {
    this.projectService.getContent().subscribe(
      async (data: any) => {
        const [err, success] = await to(
          this.loadFilesToBrowserFs(data.contents)
        );
        if (err) {
          console.log(err);
        }
        this.fs.readdir("/node_modules", (err, files) => {
          if (err) {
            console.log(err);
          }
          console.log(files);
        });
        // this.fs.readFile("node_modules", (err, files) => {
        //   console.log(files);
        // });
      },
      error => {
        console.log("No package Found");
      }
    );
  }

  onResizeSidenavEnd(event) {
    console.log(event.rectangle);
    this.sideNavStyle = {
      width: `${event.rectangle.width}px`,
      minWidth: `${event.rectangle.width}px`
    };
    this.editorNavSTyle = {
      width: `calc( ${window.innerWidth}px - ${event.rectangle.width}px)`
    };
    // this.editorNavStyleWidth = event.rectangle.width;

    // if (this.isInputOutputBased) {
    //   this.sideNavStyle1 = {
    //     width: `calc(100% - ${event.rectangle.width * 2}px)`
    //   };
    // }
  }

  onResizeSidenavEnd1(event) {
    this.sideNavStyle1 = {
      width: `${event.rectangle.width}px`,
      minWidth: `${event.rectangle.width}px`
    };
    this.editorNavSTyle = {
      width: `calc( ${window.innerWidth}px - ${event.rectangle.width}px)`
    };
    this.editorNavStyleWidth = event.rectangle.width;
  }

  onResizingSidenav(event) {
    this.sideNavStyle = {
      width: `${event.rectangle.width}px`,
      minWidth: `${event.rectangle.width}px`
    };
    this.editorNavSTyle = {
      width: `calc( ${window.innerWidth}px - ${event.rectangle.width}px)`
    };
    // this.editorNavStyleWidth = event.rectangle.width;
    if (event.rectangle.width < 100) {
      this.isFilesTreeCollapsed = true;
    } else {
      this.isFilesTreeCollapsed = false;
    }
  }

  onResizingSidenav1(event) {
    this.sideNavStyle1 = {
      width: `${event.rectangle.width}px`,
      minWidth: `${event.rectangle.width}px`
    };

    this.editorNavSTyle = {
      width: `calc( ${window.innerWidth}px - ${event.rectangle.width}px)`
    };
    // if (event.rectangle.width < 100) {
    //   this.isFilesTreeCollapsed = true;
    // } else {
    //   this.isFilesTreeCollapsed = false;
    // }
  }



  showHideSideNav() {
    if (!this.isFilesTreeCollapsed) {
      this.isFilesTreeCollapsed = true;
      this.sideNavStyle = {
        width: `34px`
      };
      if (this.editorNavStyleWidth) {
        // this.editorNavSTyle = {
        //   width: `calc( 100% - ${200}px)`
        // };
      } else {
        this.editorNavSTyle = {
          width: `calc( ${window.innerWidth}px - ${40}px)`
        };
      }
    } else {
      this.isFilesTreeCollapsed = false;
      this.sideNavStyle = {
        width: `200px`,
        minWidth: `200px`
      };
      if (this.editorNavStyleWidth) {
        this.editorNavSTyle = {
          width: `calc( ${window.innerWidth}px - ${this.editorNavStyleWidth -
            100}px)`
        };
      } else {
        this.editorNavSTyle = {
          width: `calc( 100% - ${185}px)`
        };
      }
    }
  }

  onResizingSideDatabasenav(event) {
    this.sideDatabaseNavStyle = {
      width: `${event.rectangle.width}px`
    };
    this.sideDatabaseMainNavStyle = {
      width: `calc( 100% - ${event.rectangle.width + 10}px)`
    };
  }

  onResizeSidenavDatabaseEnd(event) {
    this.sideDatabaseNavStyle = {
      width: `${event.rectangle.width}px`
    };
    this.sideDatabaseMainNavStyle = {
      width: `calc( 100% - ${event.rectangle.width}px)`
    };
  }

  onResizingDatabaseTop(event) {
    this.databaseTopStyle = {
      height: `${event.rectangle.height}px`
    };
    this.databaseBottomStyle = {
      height: `calc( 100% - ${event.rectangle.height + 10}px)`
    };
  }

  onResizingDatabaseTopEnd(event) {
    this.databaseTopStyle = {
      height: `${event.rectangle.height}px`
    };
    this.databaseBottomStyle = {
      height: `calc( 100% - ${event.rectangle.height}px)`
    };
  }
  tabOpen(value) {
    this.isOpenTerminalTab = value;
    this.terminalOpen(this.isTerminalOpen);
    if (this.isOpenTerminalTab > 0) {
      this.resizeEnd();
    }

    // if (!this.isTerminalDivOpen) {
    //   this.openCloseFooterPop();
    // }
  }

  terminal(id) {
    this.isTerminalOpen = id;

    this.terminalOpen(id);
  }

  addTerminals() {
    this.terminalId = this.terminalId + 1;
    this.isTerminalOpen = this.terminalId;
    this.terminalList.push({ id: this.terminalId });
    this.addTerminal(this.terminalId);
  }

  editorFontSize(size) {
    this.editorFontSizeText = size;
    switch (size) {
      case "small":
        this.editorOptions.fontSize = "12px";
        this.editorOptions.lineHeight = "20px";
        break;
      case "medium":
        this.editorOptions.fontSize = "14px";
        this.editorOptions.lineHeight = "24px";
        break;
      case "large":
        this.editorOptions.fontSize = "16px";
        this.editorOptions.lineHeight = "28px";
        break;
      default:
        this.editorOptions.fontSize = "12px";
        this.editorOptions.lineHeight = "20px";
    }
    this.codeEditorChild.editorFontSize(this.editorOptions);
  }

  unsubscribeDockerEvents() {
    if (this.dockerSubscribe) {
      this.dockerSubscribe.unsubscribe();
    }
    if (this.terminalSubscribe) {
      this.terminalSubscribe.unsubscribe();
    }
    if (this.testResultSubscribe) {
      this.testResultSubscribe.unsubscribe();
    }
    if (this.getTerminalSubscribe) {
      this.getTerminalSubscribe.unsubscribe();
    }
    if (this.getRefreshIframe) {
      this.getRefreshIframe.unsubscribe();
    }
    if (this.dockerOnSaveFile) {
      this.dockerOnSaveFile.unsubscribe();
    }
    if (this.getPackageFile) {
      this.getPackageFile.unsubscribe();
    }
    // if (this.terminal) {
    //   this.terminal.dispose();
    // }
  }
}
