import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {
  // loginCallbackCalled: boolean = false;
  // userInfo = {};
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let currentUser = JSON.parse(localStorage.getItem("firebase-active-user"));
    if (currentUser["isAnonymous"] == false) {
      return true;
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
