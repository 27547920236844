import { AuthGuard } from './auth.guard';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ProjectComponent } from "./project/project/project.component";
import { UserProjectsComponent } from "./user-projects/user-projects.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { YourComponentDeactiveService } from "./your-component-deactive.service";



const appRoutes: Routes = [
  { path: "", component: HomeComponent, canDeactivate: [YourComponentDeactiveService] },
  { path: ":project/:type", pathMatch: "full", component: ProjectComponent,  canDeactivate: [YourComponentDeactiveService] },
  { path: "projects", component: UserProjectsComponent,canActivate: [AuthGuard] },
  { path: "page-not-found", component: PageNotFoundComponent },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "",
    // canDeactivate: [YourComponentDeactiveService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  declarations: [],
  providers:[AuthGuard]
})
export class AppRoutingModule {}
