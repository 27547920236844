import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MenuComponent } from "./menu.component";
import { CollapseModule } from "ngx-bootstrap";
import { TooltipModule } from "ngx-bootstrap";
import { NgProgressInterceptor, NgProgressModule } from 'ngx-progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
@NgModule({
  imports: [
    CommonModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    RouterModule,
    NgProgressModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    FormsModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-success btn-min-w',
      cancelButtonClass: 'btn-warning btn-min-w',
      position:'top'}),
  ],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  providers:[{provide:HTTP_INTERCEPTORS, useClass:NgProgressInterceptor, multi:true}]
})
export class MenuModule {}
