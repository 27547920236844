import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ProjectService } from '../project/project.service';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'zl-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.css']
})
export class UserProjectsComponent implements OnInit {
  firebaseActiveUser: any = {};
  total = 0;
  totalItemsPerPage: number = 10;
  currentPage = 1;
  skip = 0;
  filter: any = {};
  isPagination: boolean = false;
  isServiceCallEnded: boolean = false;
  searchProjectTitle = "";
  selectedLanguage = "all";
  isProfileCollapsed = false;
  constructor(public afAuth: AngularFireAuth,
    private router: Router,
    private projectService: ProjectService,
    public ngProgress: NgProgress,
    private spinner: NgxSpinnerService) { 
    this.firebaseActiveUser = JSON.parse(localStorage.getItem('firebase-active-user'));
    localStorage.setItem('template-uid', JSON.stringify(this.firebaseActiveUser['uid']));
    }
  allProsData: any = [];
  ngOnInit() {
    this.firebaseActiveUser = JSON.parse(localStorage.getItem('firebase-active-user'));
    let fs = JSON.parse(localStorage.getItem('additional-fu-info'));
    this.firebaseActiveUser["bio"] = fs.bio;
    this.getProjectsCount();
  }

  gitHubSignOut() {
    this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem("firebase-active-user");
      localStorage.removeItem("template-uid");
      this.router.navigate(['/']);
    }, (error) => {
      // An error happened.
      console.log(error);
    });
  }
  
  onSearchPressIcon(event){
    this.onSearchByTitle(event);
  }

  // on search by title
  onSearchByTitle(event) {
    this.allProsData = [];
    if (event.length !== 0) {
      this.skip = 0;
      this.currentPage = 1;
      this.filter["id"] ={ like: event,options:"i" };
      this.getProjectsCount(this.filter);
    } else {
      this.getProjectsCount();
    }
    this.isPagination = false;
  }
  searchFieldData:any="";

  onSearchBlur(inputData){
    if(inputData.length !==0){
      this.searchFieldData = inputData;
      console.log('searchFieldData===>',this.searchFieldData)
      console.log(inputData)
    }
    else{
      this.searchFieldData = "";
      this.filter["id"] = {like:"",options:"i"}
      console.log('empty searchFieldData==>',this.searchFieldData)
      console.log('empty field event',inputData)
    }
  }
  getProsByLanguage(event) {
    this.allProsData = [];
    this.selectedLanguage = event;
    if (event.length !== 0) {
      this.skip = 0;
      this.currentPage = 1;
      this.filter["name"]= { like: event, options:"i" } ;
      this.getProjectsCount(this.filter);
    } else {
      this.getProjectsCount();
    }
    this.isPagination = false;
  }


  // page changed
  pageChanged(data) {
    this.allProsData = [];
    this.currentPage = data.page;
    
    if (this.currentPage === 1) {
      this.skip = 0;
    } else {
      this.skip = (this.currentPage - 1) * this.totalItemsPerPage;
    }
    this.getProjects(
      this.filter
    );
  }


  // getting projects count
  getProjectsCount(filter?, swal?) {
    this.spinner.show();
    let activeFireBaseUser = JSON.parse(localStorage.getItem('firebase-active-user'));
    if (filter) {
      filter["uid"] = activeFireBaseUser.uid;
      if(filter["name"]){
        if((filter.name.like == "all")){
          filter.name.like = ""
        }
      }
    } else {
      filter = { uid: activeFireBaseUser.uid };
    }
    this.projectService.getProjectsCount(filter).subscribe(
      data => {
        this.spinner.hide();
        this.total = data["count"];
        this.getProjects(
          filter,
          swal
        );
      },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  // get Projects
  getProjects(filter?, swal?) {
    this.spinner.show();
    let activeFireBaseUser = JSON.parse(localStorage.getItem('firebase-active-user'));
    if (filter) {
      filter["uid"] = activeFireBaseUser.uid;
    } else {
      filter = { uid: activeFireBaseUser.uid };
    }
    if (this.selectedLanguage.length > 0) {
      filter["name"] = { "like": this.selectedLanguage == "all"? "":this.selectedLanguage };
    }
    // filter["id"] = { like: this.selectedLanguage };
    this.projectService
      .getProjects(this.skip, filter, this.totalItemsPerPage)
      .subscribe(
        res => {
          // try {
          //   this.projectService.assignUidToSocket();
          // } catch (error) {

          // }
          let allPros: any = res;
          allPros.forEach(ar => {
            let languages = [
              { l: 'react', icon: './assets/images/react.png' },
              { l: 'angular', icon: './assets/images/angular.png' },
              { l: 'typescript', icon: './assets/images/languages/typescript2.png' },
              { l: 'javascript', icon: 'assets/images/languages/javascript.png' },
              { l: 'html', icon: 'assets/images/languages/html-red.png' },
              { l: 'css', icon: 'assets/images/languages/css-red.png' },
            ];

            let ind = languages.findIndex(l => l.l == ar.name);

            let modifiedObj = {
              name: ar.id,
              forks: ar.forks,
              visits: ar.visits,
              language: ar.name,
              createdAt: new Date(ar.updatedAt).toUTCString(), // ar.updatedAt,
              src: languages[ind].icon,
              uid:ar.uid
            }
            this.allProsData.push(modifiedObj);
          });


          this.isServiceCallEnded = true;
          if (this.allProsData.length === 0 && this.currentPage !== 1) {
            this.currentPage = this.currentPage - 1;
            if (this.currentPage === 1) {
              this.skip = 0;
            } else {
              this.skip = (this.currentPage - 1) * this.totalItemsPerPage;
            }
          }

          this.isPagination = true;
          this.spinner.hide();

          if (swal) {
            swal.show();
            this.spinner.hide();
          }
        },
        err => {
          console.log(err);
          this.spinner.hide();
        }
      );
  }

  navigateEditorBasedOnProId(pro) {
    let selectedFileName = "";
    if (pro.language === 'angular') {
      selectedFileName = "/src/app/app.component.ts";
    } else if(pro.language === 'javascript'){
      selectedFileName = "/src/index.js";
    } else if(pro.language === 'react'){
      selectedFileName = "/src/index.js";
    } else if(pro.language === 'html'){
      selectedFileName = "/public/index.html";
    } else if(pro.language === 'css'){
      selectedFileName = "/src/style.css";
    } else {
      selectedFileName = "/src/index.ts";
    }
    this.router.navigate(
      [`/${pro.language}/${pro.name}`],
      { queryParams: { file: selectedFileName} }
    );
  }

  projectType;
  createTemplate(type: string, navigateAnotherProject) {
      this.ngProgress.start();
      this.projectService.forkProjectFromTemplate(type).subscribe(r => {
        let params: any = {};
        this.projectType = r["name"];
        localStorage.setItem("template-uid", JSON.stringify(r["uid"]));
        let selectedFileName = "";
        if (this.projectType === "angular") {
          selectedFileName = "/src/app/app.component.ts";
        } else if (this.projectType === "javascript") {
          selectedFileName = "/src/index.js";
        } else if (this.projectType === "react") {
          selectedFileName = "/src/index.js";
        } else if (this.projectType === "html") {
          selectedFileName = "/public/index.html";
        } else if (this.projectType === "css") {
          selectedFileName = "/src/style.css";
        } else {
          selectedFileName = "/src/index.ts";
        }
        this.projectService.setProjectFileTree(r);
        let pt = this.projectService.getProjectFileTree();
        pt.ptype = type;
        this.projectService.sendNewProjectCreationObject(pt);
        this.router.navigate([`${r["name"]}/${r["id"]}`], {
          queryParams: { file: selectedFileName }
        });
        this.ngProgress.done();
      });
    }

}
