import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResizeEvent } from 'angular-resizable-element';
import { css_beautify } from 'js-beautify/js';
import { TreeController, TreeModel } from 'ng2-tree';
import { TabsetComponent } from 'ngx-bootstrap';
import { NgxEditorModel, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ProjectService } from '../project.service';
import { EditorContentManager } from './live/EditorContentManager';
import RemoteCursorManager from './live/RemoteCursorManager';
import { RemoteSelectionManager } from './live/RemoteSelectionManager';

declare let console: any;
@Component({
  selector: "zl-code-editor",
  templateUrl: "./code-editor.component.html",
  styleUrls: ["./code-editor.component.css"]
  // encapsulation: ViewEncapsulation.None
})
export class CodeEditorComponent implements OnInit, OnDestroy {
  editorInstance: any = undefined;

  @Input()
  enableSave;
  @Input()
  isLive;
  @Input()
  isUserOwner;
  @Input()
  isLiveSessionRunning;
  @Input()
  liveOwnerDetails;
  @Input()
  liveSessionKey;
  @Input()
  liveSessionUrl;
  @Input() isEmbedded;

  @Input() isInputOutputBased;

  @Input() runCodeSuccess;

  @Input() toggleExplorer;

  @Output()
  filesToggle: EventEmitter<any> = new EventEmitter();

  @Output()
  onRunCode: EventEmitter<any> = new EventEmitter();

  counter: any = 1;
  splitEditorPane;
  editor: any;
  icon: any;
  activeFile: TreeModel;
  x: NgxMonacoEditorConfig;
  selectedWindowIndex = 0;
  /* default styles that are applied to editor*/

  editorOptions = {
    fontFamily: "'Fira Code',Consolas, 'Courier New', monospace",
    fontSize: "12px",
    fontWeight: "100",
    lineHeight: "20px",
    cursorSmoothCaretAnimation: true,
    fixedOverflowWidgets: true,
    codeLens: true,
    theme: "vs-dark",
    bracketSpacing: true,
    autoIndent: true,
    formatOnType: true,
    formatOnPaste: true,
    // showFoldingControls: "mouseover",
    matchBrackets: true,
    autoClosingBrackets: true,
    cursorBlinking: "smooth",
    showUnused: true,
    snippetSuggestions: "top",
    smoothScrolling: true,
    wordBasedSuggestions: true,
    automaticLayout: true,
    colorizeElement: true,
    insertSpaces: false,
    tabSize: 4,
    glyphMargin: false,
    snippets: "inline",
    colorDecorators: true,
    lightbulbEnabled: true,
    iconsInSuggestions: true,
    // wordWrap: true,
    minimap: { enabled: false },
    scrollbar: { verticalScrollbarSize: 11 },
    scrollBeyondLastLine: false,
    lineNumbers: "on",
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 3,
    // wordWrap: "wordWrapColumn",
    // wordWrapColumn: 60,
    // wrappingIndent: "same",
    renderIndentGuides: false
  };
  consoleLogEditorOptions = {
    fontFamily: "'Fira Code',Consolas, 'Courier New', monospace",
    fontSize: "13px",
    fontWeight: "100",
    lineHeight: "30px",
    background: "#fff",
    language: "javascript",
    cursorSmoothCaretAnimation: true,
    fixedOverflowWidgets: true,
    codeLens: true,
    bracketSpacing: true,
    autoIndent: true,
    formatOnType: true,
    formatOnPaste: true,
    // showFoldingControls: "mouseover",
    matchBrackets: false,
    autoClosingBrackets: true,
    cursorBlinking: "smooth",
    showUnused: true,
    snippetSuggestions: "top",
    smoothScrolling: true,
    wordBasedSuggestions: true,
    automaticLayout: true,
    colorizeElement: true,
    insertSpaces: false,
    tabSize: 4,
    glyphMargin: false,
    snippets: "inline",
    colorDecorators: true,
    lightbulbEnabled: true,
    iconsInSuggestions: true,
    wordWrap: true,
    minimap: { enabled: false },
    scrollbar: { verticalScrollbarSize: 11 },
    scrollBeyondLastLine: false,
    lineNumbers: "off",
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 1,
    renderIndentGuides: false,
    accessibilityPageSize: 1
  };
  htmlFormatOptions = {
    indent_size: 4,
    indent_char: "",
    max_preserve_newline: -1,
    break_chained_methods: false,
    indent_scripts: "separate",
    brace_style: "collapse",
    space_before_conditional: false,
    preserve_newlines: false,
    keep_array_indentation: false,
    unescape_strings: false,
    jslint_happy: false,
    wrap_line_length: 0,
    indent_inner_html: false,
    comma_first: false,
    es5: true,
    space_in_paren: false,
    keep_collapsed_whitespace: false
  };
  jsFormatOptions = {
    indent_size: 4,
    preserve_newlines: true,
    space_in_empty_paren: true
  };
  cssFormatOptions = {
    indent_size: 1
  };
  selectedSplitWindow: any;
  monocoEditors: any = [
    {
      id: 1,
      currentCode: "",
      model: {
        value: "",
        language: "",
        uri: Math.ceil(Math.random() * 100000)
      },
      src: {
        address: "",
        path: "",
        iconClass: "",
        id: "",
        isDirty: false
      },
      isActive: true
    }
  ];

  keyUp = new Subject<string>();

  src: any = {
    address: "",
    path: "",
    iconClass: "",
    id: "",
    isDirty: false
  };
  setOfValue: { code: string; codeModel: any; codeModelId: any };
  //model used for code that loads onto editor
  model: NgxEditorModel = {
    value: "",
    language: "",
    uri: Math.ceil(Math.random() * 100000)
  };
  //clear property
  clear: NgxEditorModel = {
    value: "",
    language: "",
    uri: Math.ceil(Math.random() * 100000)
  };
  //view child's

  @ViewChild("srcHeader")
  srcHeader: ElementRef;
  @ViewChild("EditorPane")
  EditorPane: ElementRef;
  activeNodeController: TreeController;
  //inputs
  @Input()
  systemJSMap;
  @Input()
  systemJSPackages;
  previousLength: any;

  codeRunning: Boolean = false;

  @Input()
  set removedValue(tree) {
    if (tree) {
      this.src = "";
      // this.model = this.clear;
      this.monocoEditors[this.selectedWindowIndex].model = this.clear;
    }
  }

  @Input()
  set saveCodefromMenu(data) {
    if (data == true) {
      this.contentSave(data);
      data = "";
    }
  }

  @Input()
  projectId;

  //outputs
  @Output()
  toIframe: EventEmitter<any> = new EventEmitter();
  @Output()
  isBtnEnabled: EventEmitter<any> = new EventEmitter();
  @Output()
  save: EventEmitter<any> = new EventEmitter();
  @Output()
  codeChange: EventEmitter<string> = new EventEmitter();
  subscriptionKeyUp: Subscription;
  subscriptionKeyUpForSave: Subscription;

  subscriptionConsoleKeyUp: Subscription;

  templateUid = JSON.parse(localStorage.getItem("template-uid"));
  firebaseActiveUser = JSON.parse(localStorage.getItem("firebase-active-user"));
  localPath: any;
  lineNumber: any;
  curCode: any;
  // user id => filename, line number
  userLineNumbersMap = {};
  editorDiv = null;
  edtr: any;
  ctrlsPressed: Boolean = false;
  isCodeLoaded: Boolean = false;
  openedTabs = [];
  codingProblem: Boolean = false;
  isNotEditable: Boolean = false;
  @Output()
  resizeEnd: EventEmitter<string> = new EventEmitter();

  public style: object = {};
  public editorStyle: object = {};
  isTerminalDivOpen = false;
  isTerminalOpen: Number = 0;
  isOpenTerminalTab = 0;

  @Input()
  isBackEnd: Boolean;

  @Input()
  isFrontEnd: Boolean;

  @Input()
  isConsoleBarHidden: Boolean;

  @Output()
  addTerminal: EventEmitter<any> = new EventEmitter();

  @Output()
  terminalOpen: EventEmitter<any> = new EventEmitter();

  @Input()
  editorOptionsSizes: any;

  terminalList = [];
  terminalId: number = 0;

  consoleEditor: string;
  consoleCommands = [];
  messagesLog = [];

  @ViewChild("staticTabs") staticTabs: TabsetComponent;
  constructor(
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) {
    this.subscriptionKeyUp = this.keyUp
      .pipe(
        map(event => event),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(this.onKeyUp);

    this.subscriptionKeyUpForSave = this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1500),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (this.activeFile.isDirty) {
          this.save.emit();
        }
      });

    // this.projectService.getSocketCodeInfo().subscribe(res => {
    //   if (this.templateUid !== this.firebaseActiveUser.uid) {
    //     if (this.localPath === res["localPath"]) {
    //       this.monocoEditors[this.selectedWindowIndex].currentCode =
    //         res["code"];
    //     }
    //     this.projectService.updateCodeFile(
    //       res["localPath"],
    //       res["code"],
    //       this.activeFile
    //     );
    //   }
    // });
    this.projectService.performAction$.subscribe(response => {
      switch (response) {
        case "save":
          //closing find widget model type if opened
          this.editorInstance.trigger("closeWidget", "closeFindWidget");
          console.log("save");
          this.save.emit();
          break;
        case "undo":
          this.editorInstance.trigger("triggerUndo", "undo");
          // this.editorInstance.focus();
          break;
        case "commentLine":
          this.editorInstance.trigger(
            "triggerLineComment",
            "editor.action.commentLine"
          );
          break;
        case "redo":
          this.editorInstance.trigger("triggerRedo", "redo");
          // this.editorInstance.focus();
          break;
        case "cut":
          this.editorInstance.trigger("triggerCut", "cut");
          // this.editorInstance.focus();
          break;
        case "copy":
          // this.editorInstance.trigger("triggerCopy", "copy");
          this.editorInstance.trigger(
            "source",
            "editor.action.clipboardCopyAction"
          );
          console.log("copied from clipboard");
          break;
        case "paste":
          break;
        case "find":
          this.editorInstance.trigger("triggerFind", "actions.find");
          break;
        case "replace":
          this.editorInstance.trigger(
            "triggerReplace",
            "editor.action.startFindReplaceAction"
          );
          break;
      }
    });
  }

  /**
   * Sync the code from user broadcast.
   * @param filename
   * @param fileContents
   */
  syncCurrentCodeFromBroadcast(fileContents: String) {
    this.monocoEditors[this.selectedWindowIndex].currentCode = fileContents;
  }

  socketSub: Subscription;
  ngOnInit() {
    console.stdlog = console.log.bind(console);
    console.logs = [];
    // console.log = function() {
    //   console.logs.push(Array.from(arguments));
    //   console.stdlog.apply(console, arguments);
    //   const e = Array.from(arguments);
    //   const formatter = new JSONFormatter(e[0], 0, {
    //     hoverPreviewEnabled: false
    //   });
    //   if (document.getElementById("consoleId")) {
    //     document.getElementById("consoleId").appendChild(formatter.render());
    //   }

    //   setTimeout(() => {
    //     const lastChatBox = document.querySelector(".divHeightAuto");
    //     const children = lastChatBox.querySelector("[heading='Console']");

    //     children.scrollTop = children.scrollHeight;
    //   }, 10);
    // };

    // console.errors = [];
    // console.error = function() {
    //   console.errors.push(Array.from(arguments));
    //   console.stdlog.apply(console, arguments);
    //   const e = Array.from(arguments);
    //   const formatter = new JSONFormatter(e[0], 0, {
    //     hoverPreviewEnabled: false
    //   });

    //   const format = formatter.render();
    //   const classArray = format.getElementsByClassName("json-formatter-string");

    //   Object.keys(classArray).forEach(element => {
    //     classArray[element].className += " json-formatter-string-error";
    //   });
    //   if (document.getElementById("consoleId")) {
    //     document.getElementById("consoleId").appendChild(format);
    //   }

    //   setTimeout(() => {
    //     const lastChatBox = document.querySelector(".divHeightAuto");
    //     const children = lastChatBox.querySelector("[heading='Console']");

    //     children.scrollTop = children.scrollHeight;
    //   }, 10);
    // };

    // console.warns = [];
    // console.warn = function() {
    //   console.warns.push(Array.from(arguments));
    //   console.stdlog.apply(console, arguments);
    //   const e = Array.from(arguments);
    //   const formatter = new JSONFormatter(e[0], 0, {
    //     hoverPreviewEnabled: false
    //   });

    //   const format = formatter.render();
    //   const classArray = format.getElementsByClassName("json-formatter-string");

    //   Object.keys(classArray).forEach(element => {
    //     classArray[element].className += " json-formatter-string-warn";
    //   });
    //   if (document.getElementById("consoleId")) {
    //     document.getElementById("consoleId").appendChild(format);
    //   }

    //   setTimeout(() => {
    //     const lastChatBox = document.querySelector(".divHeightAuto");
    //     const children = lastChatBox.querySelector("[heading='Console']");

    //     children.scrollTop = children.scrollHeight;
    //   }, 10);
    // };

    let loadForFirstTime = 0;
    this.templateUid = JSON.parse(localStorage.getItem("template-uid"));
    this.projectService.subscribeSaveCodeNotification().subscribe(response => {
      console.log(response);
      if ((response = "code saved")) {
        this.isBtnEnabled.emit(false);
      }
      this.projectService.saveCode(this.currentCode, this.src);
    });

    this.editorStyle = {
      height: `calc(100vh - ${this.isEmbedded ? 35 : 75}px)`
    };
    // if (this.isConsoleBarHidden) {
    //   this.style = {
    //     height: `0px`
    //   };
    //   this.editorStyle = {
    //     height: `calc(100vh - 130px)`
    //   };
    // } else {
    //   this.editorStyle = {
    //     height: `calc(100vh  - 40px)`
    //   };
    // }

    this.projectService.getCode().subscribe(response => {
      this.src["id"] = response["id"];
      this.onNodeSelected(response);
      if (loadForFirstTime === 0) {
        loadForFirstTime = 1;
        let opened = this.route.snapshot.queryParams["opened[]"] || [];
        if (!Array.isArray(opened)) {
          opened = [opened];
        }

        opened.map(f => {
          const file: any = this.projectService.getFileByPathAndValue(f);
          if (file) {
            const index = this.fileArray.findIndex(
              e => e.path === file.path && e.value === file.value
            );
            if (index < 0) {
              file.isClicked = 2;
              file.isActive = false;
              this.fileArray.push(file);
            } else {
              this.fileArray[index].isClicked = 2;
            }
          }
        });
      }
    });

    this.projectService.newProjectChange$.subscribe((res: any) => {
      this.selectedWindowIndex = 0;
      this.monocoEditors = [
        {
          id: 1,
          currentCode: "",
          model: {
            value: "",
            language: "",
            uri: Math.ceil(Math.random() * 100000)
          },
          src: {
            address: "",
            path: "",
            iconClass: "",
            id: "",
            isDirty: false
          },
          isActive: true
        }
      ];

      this.projectService.getCode().subscribe(response => {
        this.src["id"] = response["id"];
        this.onNodeSelected(response);
      });
    });
  }

  ngAfterViewInit() {
    this.editorDiv = this.elementRef.nativeElement;

    this.projectService.isLiveSessionRunning().subscribe((status: any) => {
      if (status) {
        this.socketCommunication();
      } else {
      }
    });
  }

  ngOnChanges(changes: SimpleChange) {
    const runCodeSuccessState =
      changes &&
      changes["runCodeSuccess"] &&
      changes["runCodeSuccess"].currentValue;
    if (runCodeSuccessState) {
      this.codeRunning = false;
      this.runCodeSuccess = false;
    }
  }

  displayFullDescription(data) {}

  joinedLiveSession: boolean = false;
  authorDetails: any;
  authorCursor: any;
  collabRemoteCursorManager: any;
  collabRemoteSelectionManager: any;
  collabTargetContentManager: any;
  socketCommunication() {
    if (this.firebaseActiveUser) {
      if (this.isUserOwner && this.isLiveSessionRunning) {
        this.projectService.sendAuthorDetails({
          slug: this.projectId,
          user: this.firebaseActiveUser,
          localPath: this.localPath,
          key: this.liveSessionKey
        });
        this.editorInstance.onDidChangeCursorPosition(e => {
          const offset = this.editorInstance.getModel().getOffsetAt(e.position);
          this.projectService.sendEditorEvents({
            key: this.liveSessionKey,
            localPath: this.localPath,
            event: "AUTHOR_CURSOR_POSITION",
            slug: this.projectId,
            user: this.firebaseActiveUser,
            data: { offset }
          });
        });
        this.editorInstance.onDidChangeCursorSelection(e => {
          const startOffset = this.editorInstance
            .getModel()
            .getOffsetAt(e.selection.getStartPosition());
          const endOffset = this.editorInstance
            .getModel()
            .getOffsetAt(e.selection.getEndPosition());
          this.projectService.sendEditorEvents({
            key: this.liveSessionKey,
            localPath: this.localPath,
            event: "AUTHOR_SELECTION_POSITION",
            slug: this.projectId,
            user: this.firebaseActiveUser,
            data: { id: this.firebaseActiveUser.uid, startOffset, endOffset }
          });
        });
        new EditorContentManager({
          editor: this.editorInstance,
          onInsert: (index, text) => {
            this.projectService.sendEditorEvents({
              key: this.liveSessionKey,
              localPath: this.localPath,
              event: "AUTHOR_CONTENT_INSERT",
              slug: this.projectId,
              user: this.firebaseActiveUser,
              data: { index, text }
            });
          },
          onReplace: (index, length, text) => {
            this.projectService.sendEditorEvents({
              key: this.liveSessionKey,
              localPath: this.localPath,
              event: "AUTHOR_CONTENT_REPLACE",
              slug: this.projectId,
              user: this.firebaseActiveUser,
              data: { index, length, text }
            });
          },
          onDelete: (index, length) => {
            this.projectService.sendEditorEvents({
              key: this.liveSessionKey,
              localPath: this.localPath,
              event: "AUTHOR_CONTENT_DELETE",
              slug: this.projectId,
              user: this.firebaseActiveUser,
              data: { index, length }
            });
          }
        });
      } else if (this.isLiveSessionRunning) {
        this.editorInstance.updateOptions({ readOnly: true });
        this.collabRemoteCursorManager = new RemoteCursorManager({
          editor: this.editorInstance,
          tooltips: true,
          tooltipDuration: 0.5
        });
        this.collabRemoteSelectionManager = new RemoteSelectionManager({
          editor: this.editorInstance
        });
        this.collabTargetContentManager = new EditorContentManager({
          editor: this.editorInstance
        });
      }
      // if (!this.joinedLiveSession && this.isLiveSessionRunning) {
      //   this.joinedLiveSession = true;
      //   this.projectService
      //     .onGetLatestFileContent()
      //     .subscribe(({ key, slug, user, file }) => {
      //       try {
      //         if (slug == this.projectId && key == this.liveSessionKey) {
      //           const localPath = `${file.path}/${file.value}`;
      //           if (this.activeFile && file) {
      //             this.localPath = `${this.activeFile.path}/${this.activeFile.value}`;
      //             if (this.localPath == localPath) {
      //               this.onNodeSelected(file, true);
      //             }
      //           }
      //           const curCode = file.content;
      //           file.isDirty = true;
      //           this.projectService.updateCodeFile(
      //             `${file.path}${file.value}`,
      //             curCode,
      //             file
      //           );
      //           this.codeChange.emit(localPath);
      //         }
      //       } catch (e) {
      //         console.log(e);
      //       }
      //     });
      //   if (!this.isUserOwner) {
      //     this.projectService
      //       .onAuthorDetails()
      //       .subscribe(({ user, slug, localPath }: any) => {
      //         this.updateAuthorDetails({ user, slug, localPath });
      //       });
      //     this.projectService
      //       .onRunProjectPreview()
      //       .subscribe(({ user, slug, file }) => {
      //         if (slug == this.projectId) {
      //           const localPath = `${file.path}/${file.value}`;
      //           if (localPath != this.localPath) {
      //             this.onNodeSelected(file, true);
      //           }
      //           const curCode = file.content;
      //           file.isDirty = false;
      //           this.projectService.updateCodeFile(
      //             `${file.path}${file.value}`,
      //             curCode,
      //             file
      //           );
      //           this.codeChange.emit(localPath);
      //         }
      //       });
      //     this.subscribeEditorEvent();
      //     this.projectService
      //       .onFileSwitchFromSocket()
      //       .subscribe(({ slug, localPath }) => {
      //         if (slug == this.projectId) {
      //           setTimeout(() => {
      //             // this.subscribeEditorEvent();
      //           }, 1000);
      //         }
      //       });
      //   }
      // }
    }
  }
  updateAuthorDetails({ user, slug, localPath }) {
    if (
      user &&
      this.editorInstance &&
      this.collabRemoteCursorManager &&
      this.collabRemoteSelectionManager
    ) {
      this.editorInstance.updateOptions({ readOnly: false });
      try {
        this.collabRemoteCursorManager.removeCursor(this.authorDetails.uid);
      } catch (e) {}
      try {
        this.collabRemoteSelectionManager.removeSelection(
          this.authorDetails.uid
        );
      } catch (e) {}
      this.authorDetails = user;
      this.authorCursor = this.collabRemoteCursorManager.addCursor(
        user.uid,
        "white",
        user.name,
        "#0f4870"
      );
      this.collabRemoteSelectionManager.addSelection(user.uid, "#0f4870");
      this.editorInstance.updateOptions({ readOnly: true });
    } else {
      console.log({ user, slug, localPath, c: this.localPath });
    }
  }
  subscribeEditorEvent() {
    // this.projectService
    //   .onEditorEvents()
    //   .subscribe(({ key, event, user, slug, data, localPath }: any) => {
    //     try {
    //       if (
    //         this.liveSessionKey == key &&
    //         this.projectId == slug &&
    //         this.authorCursor &&
    //         this.editorInstance &&
    //         this.collabRemoteSelectionManager &&
    //         this.collabRemoteCursorManager &&
    //         this.collabTargetContentManager &&
    //         this.localPath == localPath
    //       ) {
    //         this.editorInstance.updateOptions({ readOnly: false });
    //         switch (event) {
    //           case "AUTHOR_CURSOR_POSITION":
    //             {
    //               try {
    //                 const { offset } = data;
    //                 const position = this.editorInstance
    //                   .getModel()
    //                   .getPositionAt(offset);
    //                 this.authorCursor.setPosition(position);
    //               } catch (e) {
    //                 setTimeout(() => {
    //                   this.updateAuthorDetails({ slug, user, localPath });
    //                 }, 1000);
    //               }
    //             }
    //             break;
    //           case "AUTHOR_SELECTION_POSITION":
    //             {
    //               try {
    //                 const { id, startOffset, endOffset } = data;
    //                 this.collabRemoteSelectionManager.setSelectionOffsets(
    //                   id,
    //                   startOffset,
    //                   endOffset
    //                 );
    //               } catch (e) {
    //                 setTimeout(() => {
    //                   this.updateAuthorDetails({ slug, user, localPath });
    //                 }, 1000);
    //               }
    //             }
    //             break;
    //           case "AUTHOR_CONTENT_INSERT":
    //             {
    //               const { index, text } = data;
    //               this.collabTargetContentManager.insert(index, text);
    //             }
    //             break;
    //           case "AUTHOR_CONTENT_REPLACE":
    //             {
    //               const { index, length, text } = data;
    //               this.collabTargetContentManager.replace(index, length, text);
    //             }
    //             break;
    //           case "AUTHOR_CONTENT_DELETE":
    //             {
    //               const { index, length } = data;
    //               this.collabTargetContentManager.delete(index, length);
    //             }
    //             break;
    //         }
    //         this.editorInstance.updateOptions({ readOnly: true });
    //       }
    //     } catch (e) {}
    //   });
  }
  onInit(editor) {
    if (editor) {
      const user = this.projectService.getLocalStorage("firebase-active-user");
      const uid = user && user.uid;
      if (!this.isNotEditable) {
        if (this.templateUid && this.templateUid.includes("IRA")) {
          if (this.templateUid !== uid) {
            setTimeout(() => {
              editor.updateOptions({ readOnly: false });
            }, 1000);
          } else {
            setTimeout(() => {
              editor.updateOptions({ readOnly: false });
            }, 1000);
          }
        }
      }

      if (this.isNotEditable) {
        editor.updateOptions({ readOnly: true });
      }

      // monaco.editor.setTheme("vs-light");
      // const fileName = this.route.snapshot.queryParams["file"];
      // const language = this.projectService.getFileLanguage(fileName);

      // monaco.editor.setModelLanguage(editor.getModel(), language);

      const angularCall = this;
      editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S,
        function() {
          setTimeout(() => {
            angularCall.save.emit();
          }, 200);
        }
      );
      this.editorInstance = editor;
      if (this.editorInstance) {
        this.socketCommunication();
        //adding keybindings to monaco editor to save via  ' ctrl+s '
        // if(this.firebaseActiveUser.uid == this.templateUid && this.isEmbedded){
        if (
          this.firebaseActiveUser &&
          this.firebaseActiveUser.uid == this.templateUid
        ) {
          this.editorInstance.addAction({
            id: "",
            // A label of the action that will be presented to the user.
            label: "",
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
            run: SAVE => {
              if (this.activeFile.language) {
                setTimeout(() => {
                  // console.log("ctrl S func in progress.......");
                  //closing 'find widget model type if opened'
                  this.editorInstance.trigger("closeWidget", "closeFindWidget");

                  this.save.emit();
                  this.activeFile.isDirty = false;
                }, 1000);
              } else {
                this.customFileName = "Untitled";
                this.projectService.performUntitledFileSave.next(
                  this.customFileName
                );
              }
            }
          });
        }
        // var myCondition1 = editor.createContextKey(/*key name*/'myCondition1', /*default value*/false);
        // editor.addAction({
        //   id: 'my-unique-id',
        //   label: 'Transform To UpperCase',
        //   keybindings: [
        //     monaco.KeyMod.CtrlCmd | monaco.KeyCode.F10
        //   ],
        //   precondition: myCondition1.set(true),
        //   keybindingContext: null,
        //   contextMenuGroupId: 'modification',
        //   contextMenuOrder: 2.5,
        //   run: function(ed) {
        //  editor.trigger('action','editor.action.transformToUppercase')
        //     return null;
        //  }
        // });
        this.editorInstance.addCommand(
          monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_Z,
          function() {
            editor.trigger("FILE BEING UNDO", "undo");
            console.log("ctrl Z func in progress.......");
          }
        );
        this.editorInstance.addCommand(
          monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_Y,
          function() {
            editor.trigger("FILE BEING REDO", "redo");
            console.log("ctrl Y func in progress.......");
          }
        );
        if (!this.isEmbedded) {
          setTimeout(() => {
            editor.focus();
          }, 200);
        }
      }

      // monaco.editor.defineTheme("myTheme", {
      //   base: "vs-dark",
      //   inherit: true,
      //   rules: [
      //     { token: "comment", foreground: "4a90e2" },
      //     { background: "262626" },
      //     {
      //       token: "Variable.name",
      //       foreground: "262626"
      //     },
      //     { token: "variable.other", foreground: "ffffff" },
      //     { token: "storage.modifier", foreground: "ffffff" }
      //   ] as any,
      //   colors: {
      //     "editor.foreground": "#262626",
      //     "editor.background": "#262626",
      //     "editorCursor.foreground": "#fff",
      //     "editorSuggestWidget.foreground": "#fff",
      //     "editorWarning.foreground": "#fff",
      //     "editorError.foreground": "#fff",
      //     "dropdown.foreground": "#fff"
      //   }
      // });
      // monaco.editor.setTheme("myTheme");
    }
  }

  editorFontSize(size) {
    this.editorOptions.fontSize = size.fontSize;
    this.editorOptions.lineHeight = size.lineHeight;
    this.editorInstance.updateOptions(this.editorOptions);
  }

  customFileName = "";
  fileArray = [];
  //event fires when user clicks on particular file in a tree
  onNodeSelected(data, fromSocket = false, isClickedHeader = false) {
    this.activeFile = data;
    this.localPath = `${this.activeFile.path}/${this.activeFile.value}`;
    this.src.address = data["value"];
    if (data["path"] !== "/") {
      this.src.path = data["path"];
    } else {
      this.src.path = "";
    }
    this.src.isDirty = data["isDirty"];

    // if (data.settings && data.settings.templates) {
    //   let leafs = data.settings.templates.leaf.split('"');
    //   this.src.iconClass = leafs[1];
    // }
    let selectedModel: NgxEditorModel = {
      value: data["content"],
      uri: Math.ceil(Math.random() * 100000),
      language: data["language"]
    };

    // editor.model.onDidChangeContent((event) => {
    //   render();
    // });

    this.monocoEditors[this.selectedWindowIndex].model = selectedModel;
    this.monocoEditors[this.selectedWindowIndex].src = Object.assign(
      {},
      this.src
    );
    this.monocoEditors[this.selectedWindowIndex].currentCode =
      selectedModel.value;
    this.monocoEditors[this.selectedWindowIndex].activeFile = Object.assign(
      {},
      this.activeFile
    );

    this.isCodeLoaded = true;
    const projectName = this.route.snapshot.params["project"];

    if (projectName !== "input-output") {
      this.openedTabs = this.route.snapshot.queryParams["opened[]"] || [];

      if (!Array.isArray(this.openedTabs)) {
        this.openedTabs = [this.openedTabs];
      }
      // console.log(this.monocoEditors);

      // if (
      //   this.fileArray.filter(e => e.path === data.path && e.value === data.value)
      //     .length === 0
      // ) {
      //   this.fileArray.map(e => (e.isActive = false));
      //   data.isActive = true;
      //   data.isClicked = 0;
      //   this.fileArray.push(data);
      // }

      const indexOfClickedFile = this.fileArray.findIndex(
        e => e.path === data.path && e.value === data.value
      );

      this.fileArray.map((e, k) => {
        e.isActive = false;
        if (e.path === data.path && e.value === data.value) {
          e.isActive = true;
          e.isClicked = e.isClicked + 1;
          if (e.isClicked > 0) {
            const index = this.openedTabs.findIndex(
              f => f === this.pathJoin([data.path, data.value], "/")
            );

            if (index < 0) {
              this.openedTabs.push(this.pathJoin([data.path, data.value], "/"));
            }
          }
        }
        if (e.isActive === false && e.isClicked < 1 && indexOfClickedFile < 0) {
          this.fileArray.splice(k, 1);
        }
      });

      if (indexOfClickedFile < 0) {
        data.isActive = true;
        data.isClicked = 0;
        this.fileArray.push(data);
      }
    } else {
      this.fileArray = [];
      this.fileArray.push(data);
    }

    // if (isClickedHeader) {
    const { path, value, id } = data;

    // if (
    //   this.route.snapshot.queryParams["hideExplorer"] &&
    //   this.route.snapshot.queryParams["view"]
    // ) {
    //   const view = this.route.snapshot.queryParams["view"];
    //   this.router.navigate([], {
    //     queryParams: {
    //       file: `${path}/${value}`,
    //       "opened[]": this.openedTabs,
    //       hideExplorer: 1,
    //       view: `${view}`,
    //       isEmbedded: 1
    //     }
    //   });
    // } else if (this.route.snapshot.queryParams["hideExplorer"]) {
    //   this.router.navigate([], {
    //     queryParams: {
    //       file: `${path}/${value}`,
    //       "opened[]": this.openedTabs,
    //       hideExplorer: 1,
    //       isEmbedded: 1
    //     }
    //   });
    // } else if (this.route.snapshot.queryParams["view"]) {
    //   const view = this.route.snapshot.queryParams["view"];
    //   this.router.navigate([], {
    //     queryParams: {
    //       file: `${path}/${value}`,
    //       "opened[]": this.openedTabs,
    //       view: `${view}`,
    //       isEmbedded: 1
    //     }
    //   });
    // } else if (this.route.snapshot.queryParams["isLive"]) {
    //   const view = this.route.snapshot.queryParams["isLive"];

    //   this.router.navigate([], {
    //     queryParams: {
    //       file: `${path}/${value}`,
    //       "opened[]": this.openedTabs,
    //       isLive: this.liveSessionKey
    //     }
    //   });
    // } else if (this.route.snapshot.queryParams["codingProblem"]) {
    // const view = this.route.snapshot.queryParams["view"];
    // const hideExplorer = this.route.snapshot.queryParams["hideExplorer"];
    // const isEmbedded = this.route.snapshot.queryParams["isEmbedded"];
    // const codingProblem = this.route.snapshot.queryParams["codingProblem"];
    // const isNotEditable = this.route.snapshot.queryParamMap["isNotEditable"];
    const {
      view,
      hideExplorer,
      isEmbedded,
      codingProblem,
      isNotEditable,
      isProjectType
    } = this.route.snapshot.queryParams;
    const queryParams: any = {};
    queryParams.file = `${path}/${value}`;

    if (this.openedTabs.length > 0) {
      queryParams["opened[]"] = this.openedTabs;
    }

    if (isNotEditable) {
      this.isNotEditable = true;
      queryParams.isNotEditable = 1;
    }

    if (hideExplorer) {
      queryParams.hideExplorer = 1;
    }

    if (isProjectType) {
      queryParams.isProjectType = `${isProjectType}`;
    }

    if (view) {
      queryParams.view = `${view}`;
    }
    if (isEmbedded) {
      queryParams.isEmbedded = 1;
    }

    if (codingProblem) {
      queryParams.codingProblem = 1;
      this.codingProblem = true;
    }

    this.router.navigate([], {
      queryParams
    });

    // } else if (this.route.snapshot.queryParams["isEmbedded"]) {
    //   const view = this.route.snapshot.queryParams["isEmbedded"];
    //   const queryParams = { file: `${path}/${value}`, isEmbedded: 1 };
    //   if (this.openedTabs) {
    //     queryParams["opened[]"] = this.openedTabs;
    //   }
    //   this.router.navigate([], {
    //     queryParams: queryParams
    //   });
    // } else {
    //   const queryParams = { file: `${path}/${value}` };
    //   if (this.openedTabs.length > 0) {
    //     queryParams["opened[]"] = this.openedTabs;
    //   }
    //   this.router.navigate([], {
    //     queryParams: queryParams
    //   });
    // }

    // this.router.navigate([], {
    //   queryParams: {
    //     file: `${data.path}/${data.value}`,
    //     view: `editor`,
    //     isEmbedded: 1
    //   }
    // });
    // }

    if (!fromSocket) {
      this.projectService.sendToGetLatestFileContent({
        key: this.liveSessionKey,
        user: this.firebaseActiveUser,
        slug: this.projectId,
        file: data
      });
    }
    // this.monocoEditors.push(selectedModel);
    setTimeout(() => {
      this.editorOptions.fontSize = this.editorOptionsSizes.fontSize;
      this.editorOptions.lineHeight = this.editorOptionsSizes.lineHeight;
      this.editorOptions.tabSize = 10;
      this.editorOptions.fontFamily =
        "'Fira Code',Consolas, 'Courier New', monospace";
      if (this.editorInstance) {
        this.editorInstance.updateOptions(this.editorOptions);
      }
    }, 10);
  }

  pathJoin(parts, sep) {
    const separator = sep || "/";
    const replace = new RegExp(separator + "{1,}", "g");
    return parts.join(separator).replace(replace, separator);
  }

  setIcon(displayIcon) {
    if (displayIcon) {
      this.src.iconClass;
    }
  }

  closeTabs(file) {
    const indexOfClosedFile = this.fileArray.findIndex(
      e => e.path === file.path && e.value === file.value
    );

    this.openedTabs = this.route.snapshot.queryParams["opened[]"] || [];

    if (!Array.isArray(this.openedTabs)) {
      this.openedTabs = [this.openedTabs];
    }

    const indexOfOpenedTabs = this.openedTabs.findIndex(
      f => f === this.pathJoin([file.path, file.value], "/")
    );

    this.openedTabs.splice(indexOfOpenedTabs, 1);

    this.fileArray.splice(indexOfClosedFile, 1);

    if (this.fileArray[indexOfClosedFile - 1] !== undefined) {
      const redirectData = this.fileArray[indexOfClosedFile - 1];
      this.onNodeSelected(redirectData, false, true);
    } else if (this.fileArray[indexOfClosedFile] !== undefined) {
      const redirectData = this.fileArray[indexOfClosedFile];
      this.onNodeSelected(redirectData, false, true);
    }
  }

  closeTab() {
    this.monocoEditors[this.selectedWindowIndex].model = this.clear;
    this.src = "";
    const ele = this.srcHeader["nativeElement"];
  }

  //to capture any user entered data on editor binded to 'ngmodel'
  currentCode: any;

  //under save button[work in progress]
  contentSave(target) {
    if (target == true) {
      let f: NgxEditorModel = {
        value: this.monocoEditors[this.selectedWindowIndex].model.value,
        language: this.monocoEditors[this.selectedWindowIndex].model.language,
        uri: this.monocoEditors[this.selectedWindowIndex].model.uri
        // content:this.monocoEditors[0].currentCode
      };
      this.save.emit(f);
    }
  }

  //toggle button
  readFile() {}

  /**
   * Get current line number the user is on.
   */
  getCurrentLineNumber = () => {
    const currentLine = this.editorDiv.querySelector(".current-line");
    if (!currentLine) return "0";
    const parentNodeOfCurrentLine = currentLine.parentNode;
    if (!parentNodeOfCurrentLine) return "0";
    const lineNumbersDiv = parentNodeOfCurrentLine.querySelector(
      ".line-numbers"
    );
    if (!lineNumbersDiv) return "0";
    const lineNumber = lineNumbersDiv.textContent;
    if (!lineNumber) return "0";
    const lineNumberMatch = lineNumber.match(/(\(.*?\))?(\d+)/);
    if (lineNumberMatch < 1) return "0";
    return lineNumberMatch[2];
  };

  /**
   * TODO: Attach onclick
   */

  onKeyUp = e => {
    var evtobj = window.event ? event : e;
    if (
      e.key !== "Capslock" &&
      e.key !== "Escape" &&
      e.key !== "AltGraph" &&
      e.key !== "Alt" &&
      e.key !== "Meta" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "ArrowUp" &&
      e.key !== "ArrowDown" &&
      e.key !== "CapsLock" &&
      e.key !== "Shift"
    ) {
      if ((e.key === "c" || e.key === "C") && e.ctrlKey === true) {
        console.log("Control + C", e.key);
      }
      this.localPath = `${this.activeFile.path}/${this.activeFile.value}`;
      this.curCode = this.monocoEditors[this.selectedWindowIndex].currentCode;
      this.monocoEditors[
        this.selectedWindowIndex
      ].activeFile.content = this.curCode;
      this.monocoEditors[this.selectedWindowIndex].model.value = this.curCode;
      this.activeFile.isDirty = true;
      this.projectService.updateCodeFile(
        `${this.activeFile.path}${this.activeFile.value}`,
        this.monocoEditors[this.selectedWindowIndex].currentCode,
        this.activeFile
      );

      // this.subscriptionKeyUp = this.keyUp
      //   .pipe(
      //     map(event => event),
      //     debounceTime(1000),
      //     distinctUntilChanged()
      //   )
      //   .subscribe(res => {
      //     if (this.activeFile.isDirty) {
      //       this.save.emit();
      //     }
      //   });
      this.codeChange.emit(`${this.activeFile.path}/${this.activeFile.value}`);
      if (this.templateUid == this.firebaseActiveUser.uid) {
        this.projectService.sendRunOutputEventInSocket({
          key: this.liveSessionKey,
          user: this.firebaseActiveUser,
          slug: this.projectId,
          file: this.activeFile
        });
      }
      // );
    }
  };

  removeTab(monocoEditor, i) {
    if (this.monocoEditors.length !== 1) {
      ``;
      let ele = document.getElementById("da-iframe");
      monaco.editor.getModel(this.monocoEditors[i].model.uri).dispose();
      this.monocoEditors.splice(i, 1);
      i--;
      if (i < 0) {
        i = 0;
      }
      this.onSelectSplitWindow(this.monocoEditors[i], i);
    }
  }

  //code under 'format' button

  formatCode() {
    if (this.monocoEditors[this.selectedWindowIndex].currentCode != "") {
      let typeOfFile = this.monocoEditors[this.selectedWindowIndex][
        "activeFile"
      ].language;
      let formattedCode = {
        value: ""
      };
      console.log("codeFormatInProgress.........");
      if (typeOfFile !== "css") {
        this.editorInstance.trigger(
          "codeFormatInProgress",
          "editor.action.formatDocument"
        );
      } else {
        formattedCode.value = css_beautify(
          this.monocoEditors[this.selectedWindowIndex].currentCode,
          {
            eol: "\n",
            indent_size: "4",
            indent_char: " ",
            max_preserve_newlines: "-1",
            preserve_newlines: false,
            keep_array_indentation: false,
            break_chained_methods: false,
            indent_scripts: "separate",
            brace_style: "collapse",
            space_before_conditional: false,
            unescape_strings: false,
            jslint_happy: false,
            end_with_newline: false,
            wrap_line_length: "0",
            indent_inner_html: true,
            comma_first: false,
            es5: true,
            indent_empty_lines: false,
            templating: ["auto"],
            wrapped_attributes_per_line: "single",
            wrapped_attributes_end: "auto"
          }
        );
        this.currentCode = formattedCode.value;
        this.monocoEditors[this.selectedWindowIndex].content =
          formattedCode.value;
        this.monocoEditors[this.selectedWindowIndex].currentCode =
          formattedCode.value;
        this.monocoEditors[this.selectedWindowIndex].model.value =
          formattedCode.value;
        formattedCode.value = "";
      }
      this.activeFile.isDirty = true;
      this.projectService.updateCodeFile(
        `${this.activeFile.path}${this.activeFile.value}`,
        this.monocoEditors[this.selectedWindowIndex].currentCode,
        this.activeFile
      );
      this.codeChange.emit(`${this.activeFile.path}/${this.activeFile.value}`);
      setTimeout(() => {
        this.save.emit();
      }, 500);
    }
  }

  runCode() {
    this.codeRunning = true;
    this.onRunCode.emit({});
  }

  onSplitEditor(index) {
    if (this.monocoEditors.length < 2) {
      let monacoObj: any = {
        id: this.monocoEditors.length + 1,
        currentCode: this.monocoEditors[this.selectedWindowIndex].model.value,
        model: {
          value: this.monocoEditors[this.selectedWindowIndex].model.value,
          language: this.monocoEditors[this.selectedWindowIndex].model.language,
          uri: Math.ceil(Math.random() * 100000)
        },
        activeFile: this.monocoEditors[this.selectedWindowIndex].activeFile,
        src: this.monocoEditors[this.selectedWindowIndex].src,
        isActive: false
      };
      this.monocoEditors.push(monacoObj);
    }
  }

  onSelectSplitWindow(monocoEditor, i) {
    this.selectedWindowIndex = i;
    this.activeFile = monocoEditor.activeFile;
    if (!monocoEditor.isActive) {
      this.monocoEditors.forEach((monoco, m) => {
        if (m === i) {
          monoco.isActive = true;
        } else {
          monoco.isActive = false;
        }
      });
    }
  }

  getControl(event, type, navigateAlert) {
    this.filesToggle.emit(type);
  }

  onResize(event: ResizeEvent): void {
    this.style = {
      height: `${event.rectangle.height}px`
    };
    if (event.rectangle.height > 40) {
      this.isTerminalDivOpen = true;
    } else {
      this.isTerminalDivOpen = false;
    }
    this.editorStyle = {
      height: `calc(100vh - ${event.rectangle.height + 100}px)`
    };
  }

  onResizeEnd(event: ResizeEvent): void {
    if (this.isOpenTerminalTab === 1) {
      this.resizeEnd.emit();
    }
  }

  tabOpen(value) {
    this.isOpenTerminalTab = value;
    this.terminalOpen.emit(this.isTerminalOpen);
    if (this.isOpenTerminalTab > 0) {
      this.resizeEnd.emit();
    }

    if (!this.isTerminalDivOpen) {
      this.openCloseFooterPop();
    }
  }

  terminal(id) {
    this.isTerminalOpen = id;

    this.terminalOpen.emit(id);

    this.staticTabs.tabs[0].active = false;

    setTimeout(() => {
      this.staticTabs.tabs[0].active = true;
    }, 50);
  }

  addTerminals() {
    this.terminalId = this.terminalId + 1;
    this.isTerminalOpen = this.terminalId;
    this.terminalList.push({ id: this.terminalId });
    this.addTerminal.emit(this.terminalId);
  }

  async keyUpConsole(e) {
    // var evtobj = window.event ? event : e;
    let index = this.consoleCommands.length - 1;
    if (e.key === "ArrowUp") {
      let previousCommandIndex = this.consoleCommands.findIndex(
        l => l.isActive === true
      );

      if (previousCommandIndex === 0) {
        previousCommandIndex = 1;
      }

      if (previousCommandIndex === -1 && this.consoleCommands.length > 0) {
        previousCommandIndex = this.consoleCommands.length - 1;
      }

      if (previousCommandIndex > 0) {
        index = previousCommandIndex - 1;
        this.consoleCommands[previousCommandIndex].isActive = false;
        this.consoleEditor = this.consoleCommands[index].command;
        this.consoleCommands[index].isActive = true;
      }
    }

    if (e.key === "ArrowDown") {
      let previousCommandIndex = this.consoleCommands.findIndex(
        l => l.isActive === true
      );

      if (previousCommandIndex === -1 && this.consoleCommands.length > 0) {
        previousCommandIndex = this.consoleCommands.length - 1;
      }

      if (
        previousCommandIndex > -1 &&
        previousCommandIndex < this.consoleCommands.length - 1
      ) {
        index = previousCommandIndex + 1;
        this.consoleCommands[previousCommandIndex].isActive = false;
        this.consoleEditor = this.consoleCommands[index].command;
        this.consoleCommands[index].isActive = true;
      }
    }

    if (e.key === "Enter") {
      if (this.consoleEditor.includes("\n")) {
        this.consoleEditor = this.consoleEditor
          .replace(/\n/g, "")
          .replace(/\r/g, "")
          .replace(/\r\n/g, "");
        this.consoleCommands.push({
          command: this.consoleEditor,
          isActive: false
        });
        this.logMessage(this.consoleEditor);

        this.consoleEditor = "";
      }
    }
  }

  async logMessage(cmd) {
    try {
      const ee = eval(`${cmd}`);
    } catch (e) {
      console.error(e.message);
    }
  }

  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }

  openCloseFooterPop() {
    this.isTerminalDivOpen = !this.isTerminalDivOpen;
    if (this.isTerminalDivOpen) {
      this.style = {
        height: `250px`
      };
      this.editorStyle = {
        height: `calc(100vh - ${250 + 100}px)`
      };
    } else {
      this.style = {
        height: `0px`
      };
      this.editorStyle = {
        height: `calc(100vh - 130px)`
      };
    }
    this.staticTabs.tabs[1].active = true;
  }

  ngOnDestroy() {
    this.monocoEditors.forEach(monocoEditor => {
      monocoEditor.model = this.clear;
    });

    this.projectService.leaveProject({
      slug: this.projectId,
      user: this.firebaseActiveUser
    });
    this.subscriptionKeyUp.unsubscribe();
    this.subscriptionKeyUpForSave.unsubscribe();
  }
}
