import { ToastrService } from "ngx-toastr";
import { ChangeDetectorRef } from "@angular/core";

import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  Input,
  HostListener,
  TemplateRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProjectService } from "../project/project.service";
import { Router } from "@angular/router";
import { NgProgress } from "ngx-progressbar";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";
import "firebase/auth";
import { Observable } from "rxjs";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: "zl-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"]
})
export class MenuComponent implements OnInit {
  //variables
  hideFileExplorer: boolean = false;
  previewOnly: boolean = false;
  editorOnly: boolean = false;
  Close: string;
  oldSlug: any;
  addressBar: any;
  responseStatus: any;
  isFrameToggle: boolean = true;
  isEditorCode: boolean = true;
  // isCollapsed: boolean = true;
  isProfileCollapsed: boolean = false;
  changeButtonStatus: boolean = true;
  isAddressBar: boolean = true;
  @Output() shareChanges = new EventEmitter();
  @Output() onTestClick = new EventEmitter();
  //view child's
  @ViewChild("EditorButton")
  EditorButton: ElementRef;
  @ViewChild("iframeButton")
  iframeButton: ElementRef;
  @ViewChild("input")
  input: ElementRef;
  @ViewChild("navigateAlert") navAlert;
  //input's
  @Input()
  slug;
  @Input("isCollapsedOn") isCollapsed;
  @Input()
  set isSaveBtnEnabled(data) {
    this.enableSave = data;
  }

  @Input() isEmbedded;
  @Input() isLive;
  enableSave: boolean = true;
  cancelButtonClass = "btn btn-light text-bold mr-3 btn-min-w";
  //output's
  @Output()
  editToggle: EventEmitter<any> = new EventEmitter();
  @Output()
  frameToggle: EventEmitter<any> = new EventEmitter();
  @Output()
  save: EventEmitter<undefined> = new EventEmitter();
  @Output() togglePreview: EventEmitter<any> = new EventEmitter();

  @Output() run: EventEmitter<any> = new EventEmitter();

  @Output() test: EventEmitter<any> = new EventEmitter();

  address: any;
  @Input() projectType;

  @Input()
  userLineNumbersMap = []; // stores the user to line numbers map
  slugPattern = "^[a-z0-9_-]{8,15}$";

  @Input() set isTogglePreview(data) {
    this.isOpenPreview = data;
  }

  @Input() menuInputs;

  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  additinalGitUserData: any = {};
  firebaseActiveUser: any = {};
  constructor(
    private projectService: ProjectService,
    public ngProgress: NgProgress,
    private router: Router,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef
  ) {
    this.route.queryParams.subscribe(response => {
      let fData = JSON.parse(localStorage.getItem("firebase-active-user"));
      let fs = JSON.parse(localStorage.getItem("additional-fu-info"));
      if (fs == null) {
        this.additinalGitUserData = {};
      } else {
        this.additinalGitUserData = fs;
      }
      if (fData == null) {
        this.firebaseActiveUser = {};
        this.afAuth.auth.signInAnonymously().catch(error => {
          var errorMessage = error.message;
        });
      } else {
        this.firebaseActiveUser = fData;
        this.templateUid = JSON.parse(localStorage.getItem("template-uid"));
      }
    });

    const localUser = this.projectService.getUserInfo() as any;

    // this.user = this.afAuth.authState;
    // this.user.subscribe(async user => {
    //   if (user) {
    //     // console.log(user, "new");
    //     this.userDetails = user;
    //     this.firebaseActiveUser["isAnonymous"] = user.isAnonymous;
    //     this.firebaseActiveUser["uid"] = this.projectService.setOrGetId(user);
    //     this.firebaseActiveUser["displayName"] = user.displayName
    //       ? user.displayName
    //       : "";
    //     this.firebaseActiveUser["email"] = user.email
    //       ? user.email
    //       : this.additinalGitUserData["email"];
    //     this.firebaseActiveUser["photoURL"] = user.photoURL
    //       ? user.photoURL
    //       : "";
    //     this.firebaseActiveUser["name"] = this.additinalGitUserData["name"]
    //       ? this.additinalGitUserData["name"]
    //       : "";
    //     this.firebaseActiveUser["token"] = this.additinalGitUserData["token"]
    //       ? this.additinalGitUserData["token"]
    //       : "";
    //     // this.firebaseActiveUser["isVismaya"] = true;
    //     localStorage.setItem(
    //       "firebase-active-user",
    //       JSON.stringify(this.firebaseActiveUser)
    //     );
    //     this.templateUid = JSON.parse(localStorage.getItem("template-uid"));
    //     // try {
    //     //   this.projectService.assignUidToSocket();
    //     // } catch (error) {}
    //   } else {
    //     this.firebaseActiveUser["token"] = "";
    //     this.firebaseActiveUser["name"] = "";
    //     if (this.firebaseActiveUser) {
    //       this.afAuth.auth.signInAnonymously().catch(error => {
    //         var errorMessage = error.message;
    //       });
    //       // try {
    //       //   this.projectService.assignUidToSocket();
    //       // } catch (error) {}
    //     }
    //   }
    // });

    // this.projectService.getSocketLineInfo().subscribe(res => {
    //   if (res["uid"] != this.firebaseActiveUser.uid) {
    //     this.addUser(res);
    //   }
    // });

    // // COLLAB
    // this.projectService.getServerBroadcast().subscribe((res: any) => {
    //   console.error("shadow fiend", res);
    //   // USER = ADMIN
    //   // attach the line numbers
    //   if (this.userIsAdmin() && res.userLineNumbersMap) {
    //     this.userLineNumbersMap = res.userLineNumbersMap;
    //     // TODO: write the user data to the author's view.
    //   }
    // });

    // this.projectService.getDisconnectSocketInfo().subscribe(res => {
    //   this.activeSocketUsers = this.activeSocketUsers.filter(
    //     as => as.uid != res["uid"]
    //   );
    // });
  } // /constructor

  /**
   * Check if the user is admin
   */
  userIsAdmin() {
    const projectOwnerUid = JSON.parse(localStorage.getItem("template-uid"));
    const currentUser = JSON.parse(
      localStorage.getItem("firebase-active-user")
    );
    var currentUserUid = currentUser ? currentUser.uid : null;
    return projectOwnerUid === currentUserUid;
  }

  /* get keys */
  objKeys(obj) {
    return Object.keys(obj);
  }

  activeSocketUsers = [];
  addUser(userData) {
    let ind = this.activeSocketUsers.findIndex(a => a.uid == userData.uid);
    if (ind == -1) {
      this.activeSocketUsers.push(userData);
    } else {
      this.activeSocketUsers[ind].line = userData.line;
      this.activeSocketUsers[ind].localPath = userData.localPath;
    }
  }

  modalRef: BsModalRef;
  openAuthorsViewModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  finalPath: any;
  templateUid: any;
  ngOnInit() {
    this.isnavgativeAlertOpened = false;
    this.address = decodeURIComponent(this.router.url);
    if (window.location.hostname == "localhost") {
      this.finalPath = `http://${window.location.hostname}:${window.location.port}`;
    } else {
      this.finalPath = `http://${window.location.hostname}`;
    }
    this.address = `${this.finalPath}${this.address}&isEmbedded=1`;
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    //this is just commented for checking ctrl+s functionality added thru keybindings..in future it may be uncommented removing keybindings in code-editor component
    if (this.enableSave === true) {
      if (event.ctrlKey === true && (event.key === "s" || event.key === "S")) {
        event.preventDefault();
        // this.onSave();
      }
    }
    if ((event.key === "z" || event.key === "Z") && event.ctrlKey === true) {
      console.log("Control + z", event.key);
    }
  }

  @HostListener("window:beforeunload", ["$event"]) beforeUnloadHander(
    event: any
  ) {
    // var isFormDirty = document.getElementById('profileformStatus').innerText;
    // event.preventDefault();
    console.log(event);
    if (this.enableSave == true) {
      if (this.isnavgativeAlertOpened) {
        return true;
      }
      if (!!window.performance && window.performance.navigation.type == 2) {
        window.location.reload();
      }
      //  else {
      //   if(window.performance.navigation.type == 1)
      //   console.log("reloadddd",event);
      //   // return
      // }
      return false;
    } else {
      return true;
    }
  }

  getControl() {
    if (this.isFrameToggle) {
      if (this.isFrameToggle == true) {
        this.isFrameToggle = false;
        this.iframeButton.nativeElement.innerText = "Open";
      }
    } else {
      this.isFrameToggle = true;
      this.iframeButton.nativeElement.innerText = "Close";
    }
    this.frameToggle.emit(this.isFrameToggle);
  }

  getcontrolinfo() {
    console.log("after edit");
  }

  onRun() {
    this.run.emit();
  }

  onTestSubmit() {
    this.test.emit();
  }

  onSave() {
    this.save.emit();
  }
  onNavigate() {
    window.open("http://127.0.0.1:5500/src/app/project/iframe.html");
  }

  ProjectFork() {
    this.ngProgress.start();
    this.projectService.forkProjectFromProject(this.slug).subscribe(
      response => {
        if (response["status"] == 200) {
          localStorage.setItem(
            "template-uid",
            JSON.stringify(response["project"]["uid"])
          );
          let params = this.route.snapshot["queryParams"]["file"];
          this.projectType = response["project"]["name"];
          this.router.navigate(
            [`${response["project"]["name"]}/${response["project"]["id"]}`],
            { queryParams: { file: params } }
          );
          setTimeout(() => {
            window.location.reload();
            this.router.routeReuseStrategy.shouldReuseRoute = function() {
              return false;
            };
            this.ngProgress.done();
          });
        } else {
          this.ngProgress.done();
          window.alert(response["error"]);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  ProjectExport() {
    this.ngProgress.done();
    this.projectService.exportProject(this.slug).subscribe(
      response => {
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.ngProgress.done();
          this.downLoadFile(response);
        }, 3000);
      },
      err => {
        this.ngProgress.done();
      }
    );
  }

  ProjectImport(importGitProject) {
    this.modalRef = this.modalService.show(importGitProject, {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  downLoadFile(data: any) {
    var url = window.URL.createObjectURL(data);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = `${this.slug}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
  }

  checkedValue(checkedName, event: any) {
    this.address = decodeURIComponent(this.router.url);
    this.address = `${this.finalPath}${this.address}&isEmbedded=1`;
    let checkedPropertyValue = event["target"]["checked"];
    switch (checkedName) {
      case "hideExplorer":
        if (checkedPropertyValue == true) {
          this.hideFileExplorer = true;
          this.address += `&${checkedName}=1`;
        } else {
          this.address = this.address.replace(`&${checkedName}=1`, "");
          this.hideFileExplorer = false;
        }
        if (this.previewOnly) {
          this.address += `&view=preview`;
        } else if (this.editorOnly) {
          this.address += `&view=editor`;
        }
        break;
      case "previewOnly":
        if (checkedPropertyValue == true) {
          this.hideFileExplorer = this.hideFileExplorer;
          this.previewOnly = true;
          this.editorOnly = false;
          if (this.address.includes("&view=editor") == true) {
            this.address = this.address.replace(
              `&view=editor`,
              `&view=preview`
            );
          } else {
            this.address += `&view=preview`;
            this.previewOnly = true;
          }
        } else {
          this.address = this.address.replace(`&view=preview`, "");
          this.previewOnly = false;
        }
        if (this.hideFileExplorer) {
          this.address += "&hideExplorer=1";
        }
        break;
      case "editorOnly":
        if (checkedPropertyValue == true) {
          this.hideFileExplorer = this.hideFileExplorer;
          this.previewOnly = false;
          this.editorOnly = true;
          if (this.address.includes("&view=preview") == true) {
            this.address = this.address.replace(
              `&view=preview`,
              `&view=editor`
            );
          } else {
            this.address += `&view=editor`;
          }
        } else {
          this.address = this.address.replace(`&view=editor`, "");
          this.editorOnly = false;
        }
        if (this.hideFileExplorer) {
          this.address += "&hideExplorer=1";
        }
        break;
      default:
        console.log("default");
    }
  }

  onTest() {
    this.onTestClick.emit(this.slug);
  }

  gitHubLogin() {
    this.afAuth.auth
      .signInWithPopup(new auth.GithubAuthProvider())
      .then(res => {
        this.additinalGitUserData["token"] = res.credential["accessToken"];
        this.additinalGitUserData["name"] = res.additionalUserInfo.username;
        this.additinalGitUserData["email"] = res.user.providerData["0"].email;
        this.additinalGitUserData["bio"] =
          res.additionalUserInfo.profile["bio"];
        localStorage.setItem(
          "additional-fu-info",
          JSON.stringify(this.additinalGitUserData)
        );
      });
  }

  fromNav = "";
  navigateToProjects(navigateAlert) {
    this.router.navigate(["/projects"], {});
  }

  gitHubSignOut(navigateAlert?) {
    if (this.enableSave) {
      this.fromNav = "signout";
      navigateAlert.show();
    } else {
      this.fromNav = "";
      this.afAuth.auth.signOut().then(
        () => {
          localStorage.removeItem("firebase-active-user");
          localStorage.removeItem("template-uid");
          localStorage.removeItem("additional-fu-info");
          this.isProfileCollapsed = false;
          window.location.replace("/"); //navigate to home after logout
        },
        error => {
          // An error happened.
          console.log(error);
        }
      );
    }
  }

  isOpenPreview = true;
  onClickOpen() {
    this.isOpenPreview = true;
    this.togglePreview.emit(this.isOpenPreview);
  }
  onClickClose() {
    this.isOpenPreview = false;
    this.togglePreview.emit(this.isOpenPreview);
  }

  // copied from clip board
  copyClipBoard(value) {
    value.select();
    document.execCommand("copy");
    value.setSelectionRange(0, 0);
    this.toastr.success("Copied successfully", "", {
      timeOut: 5000,
      toastClass: "vismaya-toastr toast",
      positionClass: "toast-bottom-right"
    });
  }
  selectedProjectTypeToChange = "";

  createTemplate(type: string, navigateAnotherProject) {
    if (this.enableSave) {
      this.isnavgativeAlertOpened = true;
      this.selectedProjectTypeToChange = type;
      setTimeout(() => {
        navigateAnotherProject.show();
      });
      // window.location.reload();
    } else {
      this.ngProgress.start();
      this.projectService.forkProjectFromTemplate(type).subscribe(r => {
        let params: any = {};
        this.projectType = r["name"];
        localStorage.setItem("template-uid", JSON.stringify(r["uid"]));
        this.projectService.setProjectFileTree(r);
        let selectedFileName = "";
        if (this.projectType === "angular") {
          selectedFileName = "/src/app/app.component.ts";
        } else if (this.projectType === "javascript") {
          selectedFileName = "/src/index.js";
        } else if (this.projectType === "react") {
          selectedFileName = "/src/index.js";
        } else if (this.projectType === "html") {
          selectedFileName = "/public/index.html";
        } else if (this.projectType === "css") {
          selectedFileName = "/src/style.css";
        } else {
          selectedFileName = "/src/index.ts";
        }

        let pt = this.projectService.getProjectFileTree();
        pt.ptype = type;
        pt.info = r["info"];
        this.projectService.sendNewProjectCreationObject(pt, r["versions"]);
        this.router.navigate([`${r["name"]}/${r["id"]}`], {
          queryParams: { file: selectedFileName }
        });
        this.ngProgress.done();
      });
    }
  }

  isnavgativeAlertOpened = false;
  navigateToHome(navigateAlert) {
    // if (this.enableSave) {
    //   this.fromNav = "logo";
    //   navigateAlert.show();
    // } else {
    this.router.navigate(["/"]);
    // }
  }

  onConfirm(value?) {
    // this.onSave();
    if (this.fromNav == "projects") {
      this.router.navigate(["/projects"], {});
    } else if (this.fromNav == "signout") {
      this.afAuth.auth.signOut().then(
        () => {
          localStorage.removeItem("firebase-active-user");
          localStorage.removeItem("template-uid");
          localStorage.removeItem("additional-fu-info");
          this.isProfileCollapsed = false;
        },
        error => {
          console.log(error);
        }
      );
    } else if (value == "new-projects-confirm") {
      this.onConfirmForChangeProject();
    } else {
      if (this.fromNav == "logo") {
        this.router.navigate(["/"]);
      }
    }
  }

  performAction(action) {
    this.projectService.performAction.next(action);
  }

  onConfirmForChangeProject(navigateAlert?) {
    this.ngProgress.start();
    this.projectService
      .forkProjectFromTemplate(this.selectedProjectTypeToChange)
      .subscribe(r => {
        // this.enableSave = false;
        let params: any = {};
        this.projectType = r["name"];
        this.projectService.setProjectFileTree(r);
        let selectedFileName = "";
        if (this.projectType === "angular") {
          selectedFileName = "/src/app/app.component.ts";
        } else if (this.projectType === "javascript") {
          selectedFileName = "/src/index.js";
        } else if (this.projectType === "react") {
          selectedFileName = "/src/index.js";
        } else {
          selectedFileName = "/src/index.ts";
        }
        this.router.navigate([`${r["name"]}/${r["id"]}`], {
          queryParams: { file: selectedFileName }
        });
        localStorage.setItem("template-uid", JSON.stringify(r["uid"]));

        let pt = this.projectService.getProjectFileTree();
        pt.ptype = this.selectedProjectTypeToChange;
        pt.info = r["info"];
        this.projectService.sendNewProjectCreationObject(pt, r["versions"]);
        this.ngProgress.done();
      });
  }

  openShare() {
    this.isCollapsed = !this.isCollapsed;
    this.shareChanges.emit(this.isCollapsed);
  }
}
