import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MenuComponent } from './menu/menu.component';

@Injectable({
  providedIn: 'root'
})
export class YourComponentDeactiveService implements CanDeactivate<MenuComponent> {

  canDeactivate(component: MenuComponent): boolean {
    console.log("menu compo", component.isnavgativeAlertOpened);
      if (component.enableSave) {
          return confirm('Are you sure you want to discard your changes?');
      }
      return true;
  }
}