import {EditorContentManager} from "./EditorContentManager";
export class RemoteCursorWidget {

    private readonly _id: string;
    private readonly _editor;
    private readonly _domNode: HTMLDivElement;
    private readonly _tooltipNode: HTMLDivElement | null;
    private readonly _tooltipDuration: number;
    private readonly _scrollListener;
    private readonly _onDisposed;
    private readonly _contentManager;
    private _cursorLabel: any;
    private _position: any;
    private _offset: number;
    private _hideTimer: any;
    private _disposed: boolean;
  
    constructor(codeEditor,
                widgetId: string,
                color: string,
                label: string,
                tooltipEnabled: boolean,
                tooltipDuration: number,
                onDisposed, 
                background: string) {
      this._editor = codeEditor;
      this._tooltipDuration = tooltipDuration;
      this._id = `monaco-remote-cursor-${widgetId}`;
      this._onDisposed = onDisposed;
  
      // Create the main node for the cursor element.
      const {lineHeight} = this._editor.getConfiguration();
      this._domNode = document.createElement("div");
      this._domNode.className = "monaco-remote-cursor";
      this._domNode.style.background = background;
      this._domNode.style.height = `${lineHeight}px`;
  
      // Create the tooltip element if the tooltip is enabled.
      if (tooltipEnabled) {
        this._cursorLabel = label;
        this._tooltipNode = document.createElement("div");
        this._tooltipNode.className = "monaco-remote-cursor-tooltip";
        this._tooltipNode.style.background = background;
        this._tooltipNode.style.color = color;
        this._tooltipNode.innerHTML = this._cursorLabel;
        this._domNode.appendChild(this._tooltipNode);
  
        // we only need to listen to scroll positions to update the
        // tooltip location on scrolling.
        this._scrollListener = this._editor.onDidScrollChange(() => {
          this._updateTooltipPosition();
        });
      } else {
        this._tooltipNode = null;
        this._scrollListener = null;
      }
  
      this._contentManager = new EditorContentManager({
        editor: this._editor,
        onInsert: this._onInsert,
        onReplace: this._onReplace,
        onDelete: this._onDelete
      });
  
      this._hideTimer = null;
      this._editor.addContentWidget(this);
  
      this._offset = -1;
  
      this._disposed = false;
    }
  
    public hide(): void {
      this._domNode.style.display = "none";
    }
  
    public show(): void {
      this._domNode.style.display = "inherit";
    }
  
    public setOffset(offset: number): void {
      try {
        const position = this._editor.getModel().getPositionAt(offset);
        this.setPosition(position);
      } catch(e) {
        console.log(e)
      }
    }
  
    public setPosition(position): void {
      this._updatePosition(position);
  
      if (this._tooltipNode !== null) {
        setTimeout(() => this._showTooltip(), 0);
      }
    }
  
    public isDisposed(): boolean {
      return this._disposed;
    }
  
    public dispose(): void {
      if (this._disposed) {
        return;
      }
  
      this._editor.removeContentWidget(this);
      if (this._scrollListener !== null) {
        this._scrollListener.dispose();
      }
  
      this._contentManager.dispose();
  
      this._disposed = true;
  
      this._onDisposed();
    }
  
    public getId(): string {
      return this._id;
    }
  
    public getDomNode(): HTMLElement {
      return this._domNode;
    }
  
    public getPosition(){
      return this._position;
    }
  
    private _updatePosition(position): void {
      this._position = {
        position: {...position},
        preference: ['EXACT']
      };
  
      this._offset = this._editor.getModel().getOffsetAt(position);
  
      this._editor.layoutContentWidget(this);
    }
  
    private _showTooltip(): void {
      this._updateTooltipPosition();
  
      if (this._hideTimer !== null) {
        clearTimeout(this._hideTimer);
      } else {
        this._setTooltipVisible(true);
      }
  
      this._hideTimer = setTimeout(() => {
        this._setTooltipVisible(false);
        this._hideTimer = null;
      }, this._tooltipDuration);
    }
  
    private _updateTooltipPosition(): void {
      const distanceFromTop = this._domNode.offsetTop - this._editor.getScrollTop();
      if (distanceFromTop - this._tooltipNode.offsetHeight < 5) {
        this._tooltipNode.style.top = `${this._tooltipNode.offsetHeight + 2}px`;
      } else {
        this._tooltipNode.style.top = `-${this._tooltipNode.offsetHeight}px`;
      }
  
      this._tooltipNode.style.left = "0";
    }
  
    private _setTooltipVisible(visible: boolean): void {
      if (visible) {
        this._tooltipNode.style.opacity = "1.0";
        this._tooltipNode.style.fontSize = '15px';
        this._tooltipNode.style.whiteSpace = "nowrap";
        this._tooltipNode.style.position = 'absolute';
        this._tooltipNode.innerHTML = this._cursorLabel;
      } else {
        this._tooltipNode.innerHTML = '|';
        this._tooltipNode.style.fontSize = '0.5rem';
        this._tooltipNode.style.position = 'relative';
        this._tooltipNode.style.opacity = "0";
      }
    }
  
    private _onInsert = (index: number, text: string) => {
      if (this._position === null) {
        return;
      }
  
      const offset = this._offset;
      if (index <= offset) {
        const newOffset = offset + text.length;
        const position = this._editor.getModel().getPositionAt(newOffset);
        this._updatePosition(position);
      }
    }
  
    private _onReplace = (index: number, length: number, text: string) => {
      if (this._position === null) {
        return;
      }
  
      const offset = this._offset;
      if (index <= offset) {
        const newOffset = (offset - Math.min(offset - index, length)) + text.length;
        const position = this._editor.getModel().getPositionAt(newOffset);
        this._updatePosition(position);
      }
    }
  
    private _onDelete = (index: number, length: number) => {
      if (this._position === null) {
        return;
      }
  
      const offset = this._offset;
      if (index <= offset) {
        const newOffset = offset - Math.min(offset - index, length);
        const position = this._editor.getModel().getPositionAt(newOffset);
        this._updatePosition(position);
      }
    }
  }