import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ResizableModule } from 'angular-resizable-element';
import { AngularDraggableModule } from 'angular2-draggable';
import { BsDropdownModule, ButtonsModule, CollapseModule, TooltipModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MonacoEditorModule, NGX_MONACO_EDITOR_CONFIG, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { SocketIoConfig } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { MenuModule } from '../menu/menu.module';
import { ProjectExplorerModule } from '../project-explorer/project-explorer.module';
import { CodeEditorComponent } from './code-editor/code-editor.component';
import { ProjectComponent } from './project/project.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';

declare var BrowserFS: any;
const socketConfig: SocketIoConfig = {
  url: environment.socketURL,
  options: {}
};

// path: "/stage/socket.io"
export function monacoConfig(): NgxMonacoEditorConfig {
  return {
    defaultOptions: {
      scrollBeyondLastLine: true
    },
    onMonacoLoad: function() {
      monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: false
      });

      // compiler options
      monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES5,
        allowNonTsExtensions: true,
        experimentalDecorators: true
      });

      const fact = `/* React projects that don't include the DOM library need these interfaces to compile.
      React Native applications use React, but there is no DOM available. The JavaScript runtime
      is ES6/ES2015 only. These definitions allow such projects to compile with only
      */

      interface Event { }
      interface AnimationEvent extends Event { }
      interface ClipboardEvent extends Event { }
      interface CompositionEvent extends Event { }
      interface DragEvent extends Event { }
      interface FocusEvent extends Event { }
      interface KeyboardEvent extends Event { }
      interface MouseEvent extends Event { }
      interface TouchEvent extends Event { }
      interface PointerEvent extends Event { }
      interface TransitionEvent extends Event { }
      interface UIEvent extends Event { }
      interface WheelEvent extends Event { }

      interface EventTarget { }
      interface Document { }
      interface DataTransfer { }
      interface StyleMedia { }

      interface Element { }

      interface HTMLElement extends Element { }
      interface HTMLAnchorElement extends HTMLElement { }
      interface HTMLAreaElement extends HTMLElement { }
      interface HTMLAudioElement extends HTMLElement { }
      interface HTMLBaseElement extends HTMLElement { }
      interface HTMLBodyElement extends HTMLElement { }
      interface HTMLBRElement extends HTMLElement { }
      interface HTMLButtonElement extends HTMLElement { }
      interface HTMLCanvasElement extends HTMLElement { }
      interface HTMLDataElement extends HTMLElement { }
      interface HTMLDataListElement extends HTMLElement { }
      interface HTMLDialogElement extends HTMLElement { }
      interface HTMLDivElement extends HTMLElement { }
      interface HTMLDListElement extends HTMLElement { }
      interface HTMLEmbedElement extends HTMLElement { }
      interface HTMLFieldSetElement extends HTMLElement { }
      interface HTMLFormElement extends HTMLElement { }
      interface HTMLHeadingElement extends HTMLElement { }
      interface HTMLHeadElement extends HTMLElement { }
      interface HTMLHRElement extends HTMLElement { }
      interface HTMLHtmlElement extends HTMLElement { }
      interface HTMLIFrameElement extends HTMLElement { }
      interface HTMLImageElement extends HTMLElement { }
      interface HTMLInputElement extends HTMLElement { }
      interface HTMLModElement extends HTMLElement { }
      interface HTMLLabelElement extends HTMLElement { }
      interface HTMLLegendElement extends HTMLElement { }
      interface HTMLLIElement extends HTMLElement { }
      interface HTMLLinkElement extends HTMLElement { }
      interface HTMLMapElement extends HTMLElement { }
      interface HTMLMetaElement extends HTMLElement { }
      interface HTMLObjectElement extends HTMLElement { }
      interface HTMLOListElement extends HTMLElement { }
      interface HTMLOptGroupElement extends HTMLElement { }
      interface HTMLOptionElement extends HTMLElement { }
      interface HTMLParagraphElement extends HTMLElement { }
      interface HTMLParamElement extends HTMLElement { }
      interface HTMLPreElement extends HTMLElement { }
      interface HTMLProgressElement extends HTMLElement { }
      interface HTMLQuoteElement extends HTMLElement { }
      interface HTMLScriptElement extends HTMLElement { }
      interface HTMLSelectElement extends HTMLElement { }
      interface HTMLSourceElement extends HTMLElement { }
      interface HTMLSpanElement extends HTMLElement { }
      interface HTMLStyleElement extends HTMLElement { }
      interface HTMLTableElement extends HTMLElement { }
      interface HTMLTableColElement extends HTMLElement { }
      interface HTMLTableDataCellElement extends HTMLElement { }
      interface HTMLTableHeaderCellElement extends HTMLElement { }
      interface HTMLTableRowElement extends HTMLElement { }
      interface HTMLTableSectionElement extends HTMLElement { }
      interface HTMLTemplateElement extends HTMLElement { }
      interface HTMLTextAreaElement extends HTMLElement { }
      interface HTMLTitleElement extends HTMLElement { }
      interface HTMLTrackElement extends HTMLElement { }
      interface HTMLUListElement extends HTMLElement { }
      interface HTMLVideoElement extends HTMLElement { }
      interface HTMLWebViewElement extends HTMLElement { }

      interface SVGElement extends Element { }
      interface SVGSVGElement extends SVGElement { }
      interface SVGCircleElement extends SVGElement { }
      interface SVGClipPathElement extends SVGElement { }
      interface SVGDefsElement extends SVGElement { }
      interface SVGDescElement extends SVGElement { }
      interface SVGEllipseElement extends SVGElement { }
      interface SVGFEBlendElement extends SVGElement { }
      interface SVGFEColorMatrixElement extends SVGElement { }
      interface SVGFEComponentTransferElement extends SVGElement { }
      interface SVGFECompositeElement extends SVGElement { }
      interface SVGFEConvolveMatrixElement extends SVGElement { }
      interface SVGFEDiffuseLightingElement extends SVGElement { }
      interface SVGFEDisplacementMapElement extends SVGElement { }
      interface SVGFEDistantLightElement extends SVGElement { }
      interface SVGFEDropShadowElement extends SVGElement { }
      interface SVGFEFloodElement extends SVGElement { }
      interface SVGFEFuncAElement extends SVGElement { }
      interface SVGFEFuncBElement extends SVGElement { }
      interface SVGFEFuncGElement extends SVGElement { }
      interface SVGFEFuncRElement extends SVGElement { }
      interface SVGFEGaussianBlurElement extends SVGElement { }
      interface SVGFEImageElement extends SVGElement { }
      interface SVGFEMergeElement extends SVGElement { }
      interface SVGFEMergeNodeElement extends SVGElement { }
      interface SVGFEMorphologyElement extends SVGElement { }
      interface SVGFEOffsetElement extends SVGElement { }
      interface SVGFEPointLightElement extends SVGElement { }
      interface SVGFESpecularLightingElement extends SVGElement { }
      interface SVGFESpotLightElement extends SVGElement { }
      interface SVGFETileElement extends SVGElement { }
      interface SVGFETurbulenceElement extends SVGElement { }
      interface SVGFilterElement extends SVGElement { }
      interface SVGForeignObjectElement extends SVGElement { }
      interface SVGGElement extends SVGElement { }
      interface SVGImageElement extends SVGElement { }
      interface SVGLineElement extends SVGElement { }
      interface SVGLinearGradientElement extends SVGElement { }
      interface SVGMarkerElement extends SVGElement { }
      interface SVGMaskElement extends SVGElement { }
      interface SVGMetadataElement extends SVGElement { }
      interface SVGPathElement extends SVGElement { }
      interface SVGPatternElement extends SVGElement { }
      interface SVGPolygonElement extends SVGElement { }
      interface SVGPolylineElement extends SVGElement { }
      interface SVGRadialGradientElement extends SVGElement { }
      interface SVGRectElement extends SVGElement { }
      interface SVGStopElement extends SVGElement { }
      interface SVGSwitchElement extends SVGElement { }
      interface SVGSymbolElement extends SVGElement { }
      interface SVGTextElement extends SVGElement { }
      interface SVGTextPathElement extends SVGElement { }
      interface SVGTSpanElement extends SVGElement { }
      interface SVGUseElement extends SVGElement { }
      interface SVGViewElement extends SVGElement { }

      interface Text { }
      interface TouchList { }
      interface WebGLRenderingContext { }`;

      const factFilename = "myCustomNamespace1";

      monaco.languages.typescript.typescriptDefaults.addExtraLib(
        fact,
        factFilename
      );
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    MonacoEditorModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ProjectExplorerModule,
    MenuModule,
    ResizableModule,
    AngularDraggableModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: "modal-content",
      confirmButtonClass: "btn btn-primary btn-min-w",
      cancelButtonClass: "btn",
      position: "top"
    }),
    // SocketIoModule.forRoot(socketConfig),
    AccordionModule.forRoot(),
    TabsModule.forRoot()
  ],
  declarations: [
    CodeEditorComponent,
    ProjectComponent,
    SideNavigationComponent
  ],
  exports: [CodeEditorComponent],
  providers: [{ provide: NGX_MONACO_EDITOR_CONFIG, useValue: monacoConfig() }]
})
export class ProjectModule {
  constructor() {
    // BrowserFS.install(window);
    // BrowserFS.configure(
    //   {
    //     fs: "AsyncMirror",
    //     options: {
    //       async: { fs: "IndexedDB", options: {} },
    //       sync: { fs: "InMemory", options: {} }
    //     }
    //   },
    //   function(e) {
    //     if (e) {
    //       throw e;
    //     }
    //   }
    // );
    // BrowserFS.FileSystem.HTML5FS.Create(async (e, home) => {
    //   // console.log("browserFsError", e);
    //   BrowserFS.initialize(home);
    // });
  }
}
