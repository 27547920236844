import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input
} from "@angular/core";

import { ProjectService } from "src/app/project/project.service";
import swal from "sweetalert2";
import { Subscription } from "rxjs";

@Component({
  selector: "zl-dependencies",
  templateUrl: "./dependencies.component.html",
  styleUrls: ["./dependencies.component.css"]
})
export class DependenciesComponent implements OnInit {
  @ViewChild("dependenciesSwal") dependenciesSwal;
  @ViewChild("dependenciesErrorSwal") dependenciesErrorSwal;
  installDependenciesSubscription: Subscription;
  loaderStatus: any;
  isCollapsed = true;
  packageName = "";
  serverPackageName;
  @Input()
  externalResources = [];
  @Input()
  dependencies = []; // array for storing npm packages
  @Output()
  externalResourceAdded: EventEmitter<any> = new EventEmitter();
  @Output()
  externalResourceRemoved: EventEmitter<any> = new EventEmitter();
  @Output()
  npmPackageAdded: EventEmitter<any> = new EventEmitter();
  @Output()
  npmPackageRemoved: EventEmitter<any> = new EventEmitter();
  @Output() reloadProject: EventEmitter<any> = new EventEmitter();

  @Output()
  npmServerPackageAdded: EventEmitter<any> = new EventEmitter();

  @Output() reInstallProject = new EventEmitter();

  // view child
  @ViewChild("refExt")
  textInput: ElementRef;
  @Input()
  frontEnd;
  @Input()
  backEnd;

  constructor(private projectService: ProjectService) {}

  addResource(event: Event) {
    let referenceUrl = event.target["value"];

    if (this.externalResources.includes(referenceUrl)) {
      // to check whether the array includes referenceUrl
      this.textInput.nativeElement["value"] = "";
    } else {
      this.externalResources.push(referenceUrl);
      this.externalResourceAdded.emit(referenceUrl);
      this.textInput.nativeElement["value"] = "";
    }
  }
  onReferenceUrlRemoved(url) {
    this.externalResourceRemoved.emit(url);
    const index = this.externalResources.indexOf(url);
    this.externalResources.splice(index, 1);
  }

  ngOnInit() {
    this.installDependenciesSubscription = this.projectService
      .getInstallDependencies()
      .subscribe(res => {
        let packages: any = res;
        packages.forEach(packageName => {
          this.packageName = packageName;
          this.onPackageAdd(
            this.dependenciesSwal,
            this.dependenciesErrorSwal,
            true
          );
        });
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.installDependenciesSubscription.unsubscribe();
  }

  onPackageAdd(swal, errorSwal, dependencies?) {
    this.loaderStatus = "adding package";
    const input = {};
    let enteredPackage = this.packageName.split("@");
    if (enteredPackage[0] !== "") {
      input[this.packageName.split("@")[0]] =
        this.packageName.split("@").length === 2
          ? this.packageName.split("@")[1]
          : "*";
    } else {
      input[`@${this.packageName.split("@")[1]}`] =
        this.packageName.split("@").length === 3
          ? this.packageName.split("@")[2]
          : "*";
    }
    // this.installPackages(input,swal,errorSwal);
    if (dependencies) {
      this.installPackages(input, swal, errorSwal, dependencies);
    } else {
      this.installPackages(input, swal, errorSwal);
    }
  }

  onServerPackageAdd(swal, errorSwal, dependencies?) {
    this.loaderStatus = "adding package";
    const input = {};
    let enteredPackage = this.serverPackageName.split("@");
    if (enteredPackage[0] !== "") {
      input[this.serverPackageName.split("@")[0]] =
        this.serverPackageName.split("@").length === 2
          ? this.serverPackageName.split("@")[1]
          : "*";
    } else {
      input[`@${this.serverPackageName.split("@")[1]}`] =
        this.serverPackageName.split("@").length === 3
          ? this.serverPackageName.split("@")[2]
          : "*";
    }
    // this.installPackages(input,swal,errorSwal);
    this.npmServerPackageAdded.emit(enteredPackage);
    this.packageName = "";
  }

  warningMsg = "";
  installPackages(input, swal, errorSwal, dependencies?) {
    this.projectService.installPackages(input).subscribe(
      installInfo => {
        console.log({ installInfo });
        if (dependencies) {
          this.updatePackages(installInfo, dependencies);
        } else {
          this.updatePackages(installInfo);
        }
      },
      error => {
        const message = error.error.error.error;
        if (message) {
        } else {
        }
        switch (message) {
          case "MISSING_PEERS":
            const data = error.error.error.data;
            this.warningMsg = `Resolved Missing Dependencies`;
            // this.taostr.info(warningMsg);
            const missings = Object.keys(data);
            const versions = {};
            missings.map(missing => {
              this.warningMsg += `${Object.keys(data[missing]).join(
                " "
              )} requires ${missing}`;
              input[missing] = "*";
            });
            setTimeout(() => {
              swal.show();
            });
            this.installPackages(input, swal, errorSwal);
            break;
          case "PACKAGE_NOT_FOUND":
            this.warningMsg = "Package not found";
            setTimeout(() => {
              errorSwal.show();
            });
            break;
          default:
            this.warningMsg = error.error.error.error;
            if (error.error.error.statusCode == 500) {
              this.warningMsg = error.error.error.message;
            }
            setTimeout(() => {
              errorSwal.show();
            });
            break;
        }
      }
    );
  }
  updatePackages(installInfo, isReload?) {
    console.log({ installInfo });
    // this.dependencies = [];
    // for (const v of Object.keys(installInfo["versions"])) {
    //   this.dependencies.push({ [v]: installInfo["versions"][v] });
    // }
    this.projectService.setProjectDepsMeta(installInfo);
    this.npmPackageAdded.emit(isReload);
    this.packageName = null;
  }

  onPackageRemove(packageInfo) {
    this.dependencies = this.dependencies.filter(
      d => Object.keys(d)[0] !== Object.keys(packageInfo)[0]
    );
    this.npmPackageRemoved.emit(packageInfo);
  }

  installPack() {
    this.reInstallProject.emit();
  }
}
