import { Component, OnInit, EventEmitter, Input } from "@angular/core";
import { ProjectService } from "src/app/project/project.service";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "zl-import-github-project",
  templateUrl: "./import-github-project.component.html",
  styleUrls: ["./import-github-project.component.css"]
})
export class ImportGithubProjectComponent implements OnInit {
  errorMessageText = "Ok";
  import = { githubUrl: "" };

  @Input("isProjectImport") set(data){
    console.log("toggle", data);
  }
  projectType;
  constructor(private projectService: ProjectService, private router: Router) {
  }
  // isImportErr = false;
  importErr = "";
  filesToggle: EventEmitter<any> = new EventEmitter();
  importProjectFromUrl(form: NgForm, importErrorSwal) {
    if (form.valid) {
      let firebaseUser = JSON.parse(
        localStorage.getItem("firebase-active-user")
      );
      this.import["uid"] = firebaseUser["uid"];
      this.projectService.importProjectByUrl(this.import).subscribe(
        res => {
          this.importErr = "";
          let params: any = {};
          this.filesToggle.emit("file");
          this.projectType = res["name"] || "angular";
          if (this.projectType === "angular") {
            params.file = "/src/app/app.component.ts";
          } else if (this.projectType === "javascript") {
            params.file = "/src/index.js";
          } else if (this.projectType === "react") {
            params.file = "/src/index.js";
          } else if (this.projectType === "html") {
            params.file = "/public/index.html";
          } else if (this.projectType === "css") {
            params.file = "/src/style.css";
          } else {
            params.file = "/src/index.ts";
          }
          localStorage.setItem("template-uid", JSON.stringify(res["uid"]));
          let finalPath;
          let address;
          address = `${res["name"]}/${res["id"]}?file=${encodeURIComponent(params["file"])}`;
          if (window.location.hostname == "localhost") {
            finalPath = `http://${window.location.hostname}:${window.location.port}`;
          } else {
            finalPath = `https://${window.location.hostname}`;
          }
          let url = `${finalPath}/${address}`;
          window.open(url,'_blank');

          // setTimeout(() => {
          //   window.location.reload();
          // },50);
          
        },
        err => {
          if(err.error.error.statusCode == 422){
            this.importErr = err.error.error.message; 
            setTimeout(()=>{
              importErrorSwal.show();
            })
          }
          console.log(err.error.error);
        }
      );
    } else {

    }
  }

  ngOnInit() {}
  ngOnDestroy(){
    
  }
}
