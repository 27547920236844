import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "zl-external-resource",

  template: `<div class="link-files mt-1"><a class="npm-packges ellipsis-sm">{{referenceUrl | filename}}</a>
  <span class="info-value"><i class="fa fa-trash-o delete-icon mr-3" (click)="remove()"></i></span></div>`
  //template: `<div class="link-url">{{referenceUrl | filename}}</div><i class="far fa-trash-alt  delete-icons" (click)="remove()"></i>`
})
export class ExternalResourceComponent {
  @Input()
  referenceUrl;

  @Output()
  referenceUrlRemoved: EventEmitter<string> = new EventEmitter();

  remove() {
    this.referenceUrlRemoved.emit(this.referenceUrl);
  }
}
