import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filename"
})
export class FileNamePipe implements PipeTransform {
  transform(referenceUrl: string): string {
    const segments: string[] = referenceUrl.split("/");
    return segments[segments.length - 1];
  }
}
