/**
 * The EditorContentManager facilitates listening to local content changes and
 * the playback of remote content changes into the editor.
 */
export class EditorContentManager {

  /**
   * Option defaults.
   *
   * @internal
   */
  private static readonly _DEFAULTS = {
    onInsert: () => {
      // no-op
    },
    onReplace: () => {
      // no-op
    },
    onDelete: () => {
      // no-op
    },
    remoteSourceId: "remote"
  };

  /**
   * The options that configure the EditorContentManager.
   * @internal
   */
  private readonly _options;

  /**
   * A flag denoting if outgoing events should be suppressed.
   * @internal
   */
  private _suppress: boolean;

  /**
   * A callback to dispose of the content change listener.
   * @internal
   */
  private _disposer: any;

  /**
   * Constructs a new EditorContentManager using the supplied options.
   *
   * @param options
   *   The options that configure the EditorContentManager.
   */
  constructor(options) {
    this._options = { ...EditorContentManager._DEFAULTS, ...options };
    this._disposer = this._options.editor.onDidChangeModelContent(this._onContentChanged);
  }

  /**
   * Inserts text into the editor.
   *
   * @param index
   *   The index to insert text at.
   * @param text
   *   The text to insert.
   */
  public insert(index: number, text: string): void {
    try {
      this._suppress = true;
      const { editor: ed, remoteSourceId } = this._options;
      const position = ed.getModel().getPositionAt(index);

      ed.executeEdits(remoteSourceId, [{
        range: new monaco.Range(
          position.lineNumber,
          position.column,
          position.lineNumber,
          position.column
        ),
        text,
        forceMoveMarkers: true
      }]);
      this._suppress = false;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Replaces text in the editor.
   *
   * @param index
   *   The start index of the range to replace.
   * @param length
   *   The length of the  range to replace.
   * @param text
   *   The text to insert.
   */
  public replace(index: number, length: number, text: string): void {
    try {
      this._suppress = true;
      const { editor: ed, remoteSourceId } = this._options;
      const start = ed.getModel().getPositionAt(index);
      const end = ed.getModel().getPositionAt(index + length);

      ed.executeEdits(remoteSourceId, [{
        range: new monaco.Range(
          start.lineNumber,
          start.column,
          end.lineNumber,
          end.column
        ),
        text,
        forceMoveMarkers: true
      }]);
      this._suppress = false;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Deletes text in the editor.
   *
   * @param index
   *   The start index of the range to remove.
   * @param length
   *   The length of the  range to remove.
   */
  public delete(index: number, length: number): void {
    try {
      this._suppress = true;
      const { editor: ed, remoteSourceId } = this._options;
      const start = ed.getModel().getPositionAt(index);
      const end = ed.getModel().getPositionAt(index + length);

      ed.executeEdits(remoteSourceId, [{
        range: new monaco.Range(
          start.lineNumber,
          start.column,
          end.lineNumber,
          end.column
        ),
        text: "",
        forceMoveMarkers: true
      }]);
      this._suppress = false;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Disposes of the content manager, freeing any resources.
   */
  public dispose(): void {
    this._disposer.dispose();
  }

  /**
   * A helper method to process local changes from Monaco.
   *
   * @param e
   *   The event to process.
   * @private
   * @internal
   */
  private _onContentChanged = (e) => {
    if (!this._suppress) {
      e.changes.forEach((change) => this._processChange(change));
    }
  }

  /**
   * A helper method to process a single content change.
   *
   * @param change
   *   The change to process.
   * @private
   * @internal
   */
  private _processChange(change): void {
    const { rangeOffset, rangeLength, text } = change;
    if (text.length > 0 && rangeLength === 0) {
      this._options.onInsert(rangeOffset, text);
    } else if (text.length > 0 && rangeLength > 0) {
      this._options.onReplace(rangeOffset, rangeLength, text);
    } else if (text.length === 0 && rangeLength > 0) {
      this._options.onDelete(rangeOffset, rangeLength);
    } else {
      throw new Error("Unexpected change: " + JSON.stringify(change));
    }
  }
}
