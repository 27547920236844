import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";

@Component({
  selector: "zl-npm-packages",
  template: `
    <div class="link-files mt-1">
      <a class="npm-packges ellipsis-sm">{{ packageMeta["name"] }}</a>

      <span class="info-vals">
        <span class="ml-1"
          >{{ packageMeta["version"] }}

          <i class="fa fa-trash-o delete-icon" (click)="onRemove()"></i>
        </span>
      </span>
    </div>
  `
})
export class NpmPackagesComponent implements OnInit {
  packageMeta = {};
  @Input()
  package;
  @Output()
  remove: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.packageMeta["name"] = Object.keys(this.package)[0];
    this.packageMeta["version"] = this.package[Object.keys(this.package)[0]];
  }

  onRemove() {
    this.remove.emit(this.package);
    console.log("removing package from npm package component", this.package);
  }
}
