import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { TreeController, TreeModel } from 'ng2-tree';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgProgress } from 'ngx-progressbar';
import { ToastrService } from 'ngx-toastr';

import { ProjectService } from '../../project/project.service';

interface ImportedFileObject {
  value: String;
  path: String;
  content: String;
  language: String;
  id: Number;
}

interface IFrontEndSettings {
  entryPoint: String;
  entryFile: String;
}

interface IBackEndSettings {
  entryPoint: String;
  entryFile: String;
  packageInstallPoint: String;
  packageRun: String;
}
@Component({
  selector: "zl-files",
  templateUrl: "./files.component.html",
  styleUrls: ["./files.component.css"]
})
export class FilesComponent implements OnInit, AfterViewInit {
  @Input("files")
  public projectFileTree: TreeModel;
  @Input() projectInfo;
  @Input() isLiveSessionRunning;
  @Input() projectType;
  @Input() isEmbedded;
  @Input() proVersion;
  @Input() liveSessionKey;
  @Output()
  fileSelected: EventEmitter<any> = new EventEmitter();
  @Output() projectInfoChanges = new EventEmitter();
  @Input() visits;
  @Input() forks;
  @Output()
  updateCode: EventEmitter<any> = new EventEmitter();
  @Input() enableSave;
  @Input() isGratis;
  @Input() topicType;

  @Output() save = new EventEmitter();

  @Output() installDependencies = new EventEmitter();

  @Output()
  triggerSaveBtn: EventEmitter<any> = new EventEmitter();

  @Output()
  reInstallProject: EventEmitter<any> = new EventEmitter();

  @ViewChild("projectFilesTree")
  public projectFilesTree;

  isAddressBar: boolean = true;
  cancelButtonClass = "btn btn-light text-bold mr-3 btn-min-w";

  isCollapsed = false;
  duplicateError = false;
  isFilesCollapsed = false;
  newData: any;
  nodeid: any;
  activeNodeController: TreeController;
  lastAddedNodeRefId;
  newNode;
  duplicateErrorMsg: any;
  currentUploadedPath: String;

  importedFiles = [];

  cachedPackages = {};
  fileDropDependencies: any = {};
  fileUploadedStarted: Boolean = false;
  filesUploaded: Number = 0;
  totalFileLength: Number = 0;
  percentageUploaded: Number = 50;
  filesUploadFinished: Boolean = false;
  fileUploadedRef: BsModalRef;
  NoFilesRef: BsModalRef;
  droppedFiles: any = [];
  @ViewChild("fileUploadedSave") fileUploadedSave: ElementRef;
  @ViewChild("NoFiles") NoFiles: ElementRef;

  bodyElement: HTMLElement;

  isMainSite: Boolean = false;

  onDrop(event) {
    event.preventDefault();
    // console.log(event);
  }
  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    // console.log(event);
  }

  // angular snippets
  angularSnippets = [
    { action: 4, name: "Angular Module", cssClass: "angular" },
    { action: 4, name: "Angular Component", cssClass: "angular" },
    { action: 4, name: "Angular Service", cssClass: "angular" },
    { action: 4, name: "Angular Pipe", cssClass: "angular" },
    { action: 4, name: "Angular Directive", cssClass: "angular" },
    { action: 4, name: "Angular Guard", cssClass: "angular" },
    { action: 4, name: "Interface", cssClass: "new-tag" },
    { action: 4, name: "Class", cssClass: "new-tag" },
    { action: 4, name: "Enum", cssClass: "new-tag" }
  ];
  customNewNode: any;
  selectedMenuNode;
  modalRef: BsModalRef;
  firebaseActiveUser = JSON.parse(localStorage.getItem("firebase-active-user"));
  templateUid = JSON.parse(localStorage.getItem("template-uid"));
  fileId = 0;
  treeSettings = {
    isCollapsedOnInit: true
  };
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    public ngProgress: NgProgress,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {
    this.projectService.performAction$.subscribe(response => {
      if (response) {
        if (response === "newFile") {
          const newNode: TreeModel = {
            value: "Untitled.txt",
            children: null,
            language: "text"
          };
          // let index = this.projectService.project["files"].findIndex(
          //   f => f.path + f.value == event.node.node.path + event.node.node.value
          // );
          if (this.activeNodeController) {
            this.activeNodeController.addChild(newNode);
          } else {
            const mainFOlderChildren = this.projectFileTree.children;
            const mainFolderId = this.projectFileTree.children[
              mainFOlderChildren.length - 1
            ].id;
            this.activeNodeController = this.projectFilesTree.getControllerByNodeId(
              mainFolderId
            );

            this.activeNodeController.addChild(newNode);
          }
        } else if (response === "newFolder") {
          const newNodeFolder: TreeModel = {
            value: "New Folder",
            children: []
          };
          // let index = this.projectService.project["files"].findIndex(
          //   f => f.path + f.value == event.node.node.path + event.node.node.value
          // );

          if (this.activeNodeController) {
            this.activeNodeController.addChild(newNodeFolder);
          } else {
            const mainFOlderChildren = this.projectFileTree.children;
            const mainFolderId = this.projectFileTree.children[
              mainFOlderChildren.length - 1
            ].id;
            this.activeNodeController = this.projectFilesTree.getControllerByNodeId(
              mainFolderId
            );

            this.activeNodeController.addChild(newNodeFolder);
          }
        }
      }
    });
    this.projectService.performUntitledFileSave$.subscribe(response => {
      if (response) {
        if (response === "Untitled.txt") {
          try {
            this.activeNodeController.startRenaming();
          } catch {
            console.log("activeNodeController not yet");
          }
        }
      }
    });
    const target = document.documentElement;
    this.bodyElement = document.body;
    const embedded = this.route.snapshot.queryParams["isEmbedded"];

    if (embedded === undefined) {
      const dropAreaLoaded = setInterval(() => {
        const dropArea = document.getElementById("drop-area");

        if (dropArea) {
          dropArea.addEventListener("dragover", e => {
            e.preventDefault();
            e.stopPropagation();
            if (!this.filesUploadFinished) {
              this.bodyElement.classList.add("dragging");
            }
          });

          dropArea.addEventListener("dragleave", e => {
            e.preventDefault();
            this.bodyElement.classList.remove("dragging");
          });
          const angular = this;
          dropArea.ondrop = async evt => {
            this.droppedFiles = [];
            evt.preventDefault();
            const items = evt.dataTransfer.items;

            angular.getFilesWebkitDataTransferItems(items).then(files => {
              this.droppedFiles = files;
              // tslint:disable-next-line: max-line-length
              this.fileUploadedRef = this.modalService.show(
                this.fileUploadedSave,
                {
                  backdrop: true,
                  ignoreBackdropClick: false,
                  keyboard: false,
                  class: "modal-sm"
                }
              );
            });
          };
          clearInterval(dropAreaLoaded);
        }
      }, 1000);

      target.addEventListener("dragover", e => {
        e.preventDefault();
        this.bodyElement.classList.add("dragging");
      });
    }
  }

  uploadFiles(condition) {
    if (condition) {
      this.loadFiles(this.droppedFiles, true);
      this.fileUploadedRef.hide();
      // this.bodyElement.classList.remove("dragging");
    } else {
      this.fileUploadedRef.hide();
      this.bodyElement.classList.remove("dragging");
    }
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  selectedFileName: any;
  ngOnInit() {
    //     let submenu = document.getElementsByClassName("value-container");

    // for (var i = 0; i < submenu.length; i++) {
    //     submenu[i].addEventListener('click', menus, false);
    // }

    // function menus() {
    //   var menu = this.querySelector('.submenu');
    //   menu.classList.toggle("hidden");
    // };

    this.slug = "";
    this.slug = this.route.snapshot.params.type;
    let tempSelectedFile =
      this.route.snapshot.queryParams["file"] &&
      this.route.snapshot.queryParams["file"].split("/");
    if (tempSelectedFile) {
      this.selectedFileName = tempSelectedFile[tempSelectedFile.length - 1];
    } else {
      this.selectedFileName = tempSelectedFile;
    }

    //new file, rename, delete and angular snippets
    this.projectService.ProTree$.subscribe(res => {
      setTimeout(() => {
        this.projectType = res["name"];
        let files: any = res["files"];
        this.fileId = files.length - 1;
        this.projectFileTree = this.projectService.getProjectFileTree();

        if (this.projectType === "angular") {
          this.projectFileTree.children.forEach(child => {
            this.setExtraMenu(child);
          });
        }
        this.selectedFileName = "";
        this.slug = "";
        this.slug = this.route.snapshot.params.type;
        let tempSelectedFile = this.route.snapshot.queryParams["file"].split(
          "/"
        );
        if (tempSelectedFile) {
          this.selectedFileName = tempSelectedFile[tempSelectedFile.length - 1];
        } else {
          this.selectedFileName = tempSelectedFile;
        }

        if (this.selectedItem === "Angular Component") {
          let len = res["files"].length - 3;
          this.selectedFileName = res["files"][len]["value"];
        } else if (
          this.selectedItem === "Angular Module" ||
          this.selectedItem === "Class" ||
          this.selectedItem === "Enum" ||
          this.selectedItem === "Angular Service" ||
          this.selectedItem === "Interface" ||
          this.selectedItem === "Angular Pipe" ||
          this.selectedItem === "Angular Guard" ||
          this.selectedItem === "Angular Directive"
        ) {
          let len = res["files"].length - 1;
          this.selectedFileName = res["files"][len]["value"];
        } else {
          let len = res["files"].length - 1;
          this.selectedFileName = res["files"][len]["value"];
        }
        let nodeObj = this.getNodeId(this.projectFileTree);
        if (nodeObj == undefined || nodeObj == -1 || nodeObj == false) {
          if (this.renameNodeSelectedId) {
            this.activeNodeController = this.projectFilesTree.getControllerByNodeId(
              this.renameNodeSelectedId
            );
          } else {
            this.renameNodeSelectedId = 0;
            this.activeNodeController = this.projectFilesTree.getControllerByNodeId(
              1
            );
          }
          if (this.activeNodeController) {
            this.activeNodeController.select();
          }
        } else {
          this.selectFileByRoute(nodeObj);
        }
      }, 1500);
    });

    // this.projectService
    //   .onFileSwitchFromSocket()
    //   .subscribe(({ slug, localPath }) => {
    //     if (slug == this.slug) {
    //       try {
    //         let tempSelectedFile = localPath.split("/");
    //         if (
    //           this.selectedFileName !=
    //           tempSelectedFile[tempSelectedFile.length - 1]
    //         ) {
    //           this.selectedFileName =
    //             tempSelectedFile[tempSelectedFile.length - 1];
    //           let nodeObj = this.getNodeId(this.projectFileTree);
    //           this.selectFileByRoute(nodeObj);
    //         }
    //       } catch (e) {
    //         // console.log(e);
    //       }
    //     }
    //   });

    // this.projectService
    //   .onProjectSavedFromSocket()
    //   .subscribe(({ slug, user, localPath }) => {
    //     setTimeout(() => {
    //       if (this.slug == slug) {
    //         try {
    //           let tempSelectedFile = localPath.split("/");
    //           if (
    //             this.selectedFileName !=
    //             tempSelectedFile[tempSelectedFile.length - 1]
    //           ) {
    //             this.selectedFileName =
    //               tempSelectedFile[tempSelectedFile.length - 1];
    //             let nodeObj = this.getNodeId(this.projectFileTree);
    //             this.selectFileByRoute(nodeObj);
    //           }
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       }
    //     }, 1000);
    //   });

    //new project from menu drop down
    this.projectService.newProjectChange$.subscribe((res: any) => {
      this.projectFileTree = res;
      this.projectType = res["ptype"];
      setTimeout(() => {
        this.selectedFileName = "";
        this.slug = "";
        this.slug = this.route.snapshot.params.type;
        let tempSelectedFile = this.route.snapshot.queryParams["file"].split(
          "/"
        );
        if (tempSelectedFile) {
          this.selectedFileName = tempSelectedFile[tempSelectedFile.length - 1];
        } else {
          this.selectedFileName = tempSelectedFile;
        }
        let nodeObj = this.getNodeId(this.projectFileTree);
        this.selectFileByRoute(nodeObj);
      }, 1800);

      setTimeout(() => {
        this.sortByFileName(this.projectFileTree);
        if (this.projectType === "angular") {
          this.projectFileTree.children.forEach(child => {
            this.setExtraMenu(child);
          });
        }
        let nodeObj = this.getNodeId(this.projectFileTree);
        this.selectFileByRoute(nodeObj);
      }, 1800);
    });

    //on load of this page
    setTimeout(() => {
      this.projectFileTree = this.projectService.getProjectFileTree();
      this.sortByFileName(this.projectFileTree);
      if (this.projectType === "angular") {
        this.projectFileTree.children.forEach(child => {
          this.setExtraMenu(child);
        });
      }
      this.console(this.projectFileTree);
      let nodeObj = this.getNodeId(this.projectFileTree);
      this.selectFileByRoute(nodeObj);
    }, 1800);
  }

  console(data) {
    console.log(data);
  }

  getNodeId(data) {
    if (data && data.value === this.selectedFileName) {
      return data;
    } else if (data && data.children) {
      let found = false;
      for (let i = 0; i < data.children.length; i++) {
        let tempNode = this.getNodeId(data.children[i]);
        if (tempNode != -1 && tempNode && tempNode.id) {
          found = tempNode;
          return found;
        }
      }
      return found ? found : -1;
    }
    return -1;
  }

  setExtraMenu(child) {
    // if(this.projectType == "angular"){
    if (child.settings.menuItems.length === 4) {
      this.angularSnippets.forEach(snippet => {
        child.settings.menuItems.push(snippet);
      });
      if (!child.children || child.children.length != 0) {
        child.children.forEach(ch => {
          this.setExtraMenu(ch);
        });
      }
    }
    // }
  }

  onSave() {
    this.save.emit();
  }

  onTreeComponentChanges(event) {
    //console.log(event);
  }

  sortByFileName(node) {
    if (node && node.children) {
      node.children = _.orderBy(node.children, "value");
      let temp = [];
      let onlyFiles = node.children.filter(child => {
        if (child.children) {
          temp.push(child);
          return false;
        }
        return true;
      });
      node.children = [...temp, ...onlyFiles];
      node.children.forEach(child => this.sortByFileName(child));
    }
  }

  ngAfterViewInit() {
    // this.activeNodeController = this.treeComponent;
    // console.log(this.treeComponent.treeModel.children);
    // let indexNode = this.treeComponent.treeModel.children.find(
    //   c => c.value == "index.js"
    // );
    // console.log(indexNode);
    // this.treeComponent.getControllerByNodeId(indexNode.id).select();
  }

  selectFileByRoute(nodeid: number) {
    this.activeNodeController = this.projectFilesTree.getControllerByNodeId(
      nodeid["id"]
    );

    if (this.activeNodeController) {
      this.activeNodeController.select();
    }
  }

  onSelectedNodeId: any;
  // event fires when user clicks on selected file in projectFileTree
  onNodeSelected(projectFileTree) {
    try {
      if (projectFileTree && projectFileTree.node) {
        this.activeNodeController = this.projectFilesTree.getControllerByNodeId(
          projectFileTree.node.id
        );
        this.onSelectedNodeId = projectFileTree.node.id;
        // const treeController = this.projectFilesTree.getControllerByNodeId(
        //   projectFileTree.node.id
        // );
        if (
          this.activeNodeController &&
          (typeof this.activeNodeController.collapse === "function" ||
            typeof this.activeNodeController.expand === "function")
        ) {
          if (this.activeNodeController.isCollapsed()) {
            this.activeNodeController.expand();
          } else if (this.activeNodeController.isExpanded()) {
            this.activeNodeController.collapse();
          }
        }
        const { path, value, id } = projectFileTree.node.node;

        let opened = this.route.snapshot.queryParams["opened[]"] || [];

        if (!Array.isArray(opened)) {
          opened = [opened];
        }

        // const index = opened.findIndex(
        //   f => f === this.pathJoin([path, value], "/")
        // );

        // if (index < 0) {
        //   opened.push(this.pathJoin([path, value], "/"));
        // }

        // if (
        //   this.route.snapshot.queryParams["hideExplorer"] &&
        //   this.route.snapshot.queryParams["view"]
        // ) {
        //   if (projectFileTree && !projectFileTree.node.children) {
        //     let view = this.route.snapshot.queryParams["view"];
        //     this.router.navigate([], {
        //       queryParams: {
        //         file: `${path}/${value}`,
        //         "opened[]": opened,
        //         hideExplorer: 1,
        //         view: `${view}`,
        //         isEmbedded: 1
        //       }
        //     });
        //   }
        // } else if (this.route.snapshot.queryParams["hideExplorer"]) {
        //   if (projectFileTree && !projectFileTree.node.children) {
        //     this.router.navigate([], {
        //       queryParams: {
        //         file: `${path}/${value}`,
        //         "opened[]": opened,
        //         hideExplorer: 1,
        //         isEmbedded: 1
        //       }
        //     });
        //   }

        //   // [this.currentUser.id ? "gratisUserId" : "socialUserId"]: this.currentUser.providerData
        //   // ? `${this.currentUser.providerData[0].providerId}_${this.currentUser.providerData[0].uid}`
        //   // : this.currentUser.userId
        // } else if (this.route.snapshot.queryParams["view"]) {
        //   if (projectFileTree && !projectFileTree.node.children) {
        //     let view = this.route.snapshot.queryParams["view"];
        //     this.router.navigate([], {
        //       queryParams: {
        //         file: `${path}/${value}`,
        //         view: `${view}`,
        //         "opened[]": opened,
        //         isEmbedded: 1
        //       }
        //     });
        //   }
        // } else if (this.route.snapshot.queryParams["isLive"]) {
        //   if (projectFileTree && !projectFileTree.node.children) {
        //     let view = this.route.snapshot.queryParams["isLive"];
        //     this.router.navigate([], {
        //       queryParams: {
        //         file: `${path}/${value}`,
        //         isLive: this.liveSessionKey
        //       }
        //     });
        //   }
        // } else if (this.route.snapshot.queryParams["isEmbedded"]) {
        //   if (projectFileTree && !projectFileTree.node.children) {
        //     let view = this.route.snapshot.queryParams["isEmbedded"];
        //     this.router.navigate([], {
        //       queryParams: {
        //         file: `${path}/${value}`,
        //         "opened[]": opened,
        //         isEmbedded: 1
        //       }
        //     });
        //   }
        // } else {
        //   if (projectFileTree && !projectFileTree.node.children) {
        //     this.router.navigate([], {
        //       queryParams: { file: `${path}/${value}`, "opened[]": opened }
        //     });
        //   }
        // }
        if (this.userIsAdmin()) {
          this.projectService.sendFileSwitchEvent({
            key: this.liveSessionKey,
            slug: this.slug,
            localPath: `${path}/${value}`
          });
        }
        this.projectService.setCode(projectFileTree.node);
      }
    } catch (e) {
      console.log(e);
    }
  }

  addChildToTree(event, node: TreeModel) {
    console.log("addChildFFS==>", event, node);
  }

  userIsAdmin() {
    const projectOwnerUid = JSON.parse(localStorage.getItem("template-uid"));
    const currentUser = JSON.parse(
      localStorage.getItem("firebase-active-user")
    );
    var currentUserUid = currentUser ? currentUser.uid : null;
    return projectOwnerUid === currentUserUid;
  }

  // angular snippets code starts here
  selectedItem;
  currentPath;
  onMenuItemSelected(event, createExtraSnippet, deleteFileAlert) {
    if (event.selectedItem === "Delete") {
      this.selectedMenuNode = event.node;
      let isDeleteConfirm = false;
      isDeleteConfirm = confirm(
        `are you sure you want to delete ${event.node.node.value} ?`
      );
      if (isDeleteConfirm) {
        this.onDeleteFileConfirm();
      } else {
        console.log(isDeleteConfirm);
      }
    } else if (event.selectedItem === "Import") {
      this.selectedMenuNode = event.node;
      this.selectedItem = event.selectedItem;
      this.currentUploadedPath = this.selectedMenuNode.node.path;
      document.getElementById("importFiles").click();
    } else {
      this.selectedMenuNode = event.node;
      this.selectedItem = event.selectedItem;
      this.currentPath = this.selectedMenuNode.node.path;
      this.snippet.snippetName = "";
      this.modalRef = this.modalService.show(createExtraSnippet, {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      });
    }
  }

  snippet = { snippetName: "" };
  // module content here
  moduleContent() {
    let convertedClassNameRef = this.convertClassName(this.snippet.snippetName);
    return `import { NgModule } from '@angular/core';
    import { CommonModule } from '@angular/common';

    @NgModule({
       imports: [CommonModule],
       declarations: []
    })
    export class ${convertedClassNameRef}Module { }`;
  }

  // service content here
  serviceContent() {
    let convertedClassNameRef = this.convertClassName(this.snippet.snippetName);
    return `import { Injectable } from '@angular/core';

    @Injectable()
    export class ${convertedClassNameRef}Service {
      constructor() { }
    }`;
  }

  // guard content here
  guardContent() {
    let convertedClassNameRef = this.convertClassName(this.snippet.snippetName);
    return `import { Injectable } from '@angular/core';
    import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
    import { Observable } from 'rxjs';

    @Injectable()
    export class ${convertedClassNameRef}Guard implements CanActivate {
      canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return true;
      }
    }`;
  }

  // component content here
  componentContent() {
    let convertedClassNameRef = this.convertClassName(this.snippet.snippetName);
    return `import { Component, OnInit } from '@angular/core';

    @Component({
      selector: 'app-${this.snippet.snippetName.toLowerCase()}',
      templateUrl: './${this.snippet.snippetName.toLowerCase()}.component.html',
      styleUrls: ['./${this.snippet.snippetName.toLowerCase()}.component.css']
    })
    export class ${convertedClassNameRef}Component implements OnInit {

      constructor() { }

      ngOnInit() {
      }

    }`;
  }

  // directive content
  directiveContent() {
    let convertedClassNameRef = this.convertClassName(this.snippet.snippetName);
    return `import { Directive } from '@angular/core';

    @Directive({
      selector: '[app${convertedClassNameRef}]'
    })
    export class ${convertedClassNameRef}Directive {

      constructor() { }

    }`;
  }

  // pipe content here
  pipeContent() {
    let convertedClassNameRef = this.convertClassName(this.snippet.snippetName);
    return `import { Pipe, PipeTransform } from '@angular/core';

    @Pipe({
      name: '${convertedClassNameRef}'
    })
    export class ${convertedClassNameRef}Pipe implements PipeTransform {

      transform(value: any, args?: any): any {
        return null;
      }

    }`;
  }
  isSpecialChar: boolean = false;
  // On create angular snippets
  createCustomSnippets(createCustomFile: NgForm) {
    //special characters to restrict
    let customRegExp = new RegExp(".*[ /\\\\].*");
    console.log(customRegExp.test(this.snippet.snippetName));
    if (customRegExp.test(this.snippet.snippetName)) {
      this.isSpecialChar = true;
      return;
    } else {
      this.isSpecialChar = false;
    }
    //special characters restriction code ends here

    this.newFilePathSegments = [];
    if (createCustomFile.valid && !this.duplicateError) {
      let fileName = this.snippet.snippetName.toLowerCase();
      let fileSettings = this.projectService.prepareSettings("typescript");
      // angular module
      if (this.selectedItem === "Angular Module") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments
          .reverse()
          .join("/")}/${fileName}`;
        // let filePath = `${this.selectedMenuNode.value}/${this.snippet.snippetName}`;
        this.checkDuplicateAngularSnippet(fileName, "module");
        if (this.duplicateError === true) {
          return;
        }
        this.projectService.addFile(
          `${fileName}.module.ts`,
          "typescript",
          filePath,
          fileSettings,
          this.moduleContent()
        );
      } else if (this.selectedItem === "Angular Service") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments.reverse().join("/")}`;
        this.checkDuplicateAngularSnippet(fileName, "service");
        if (this.duplicateError === true) {
          return;
        }
        this.projectService.addFile(
          `${fileName}.service.ts`,
          "typescript",
          filePath,
          fileSettings,
          this.serviceContent()
        );
      } else if (this.selectedItem === "Angular Component") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments
          .reverse()
          .join("/")}/${fileName}`;

        let fileSettingsHtml = this.projectService.prepareSettings("html");
        let cssSettings = this.projectService.prepareSettings("css");
        this.checkDuplicateAngularSnippet(fileName, "component");
        if (this.duplicateError === true) {
          return;
        }
        this.projectService.addFile(
          `${fileName}.component.ts`,
          "typescript",
          filePath,
          fileSettings,
          this.componentContent()
        );
        this.projectService.addFile(
          `${fileName}.component.html`,
          "html",
          filePath,
          fileSettingsHtml,
          `<p> ${fileName} works.</p>`
        );
        this.projectService.addFile(
          `${fileName}.component.css`,
          "css",
          filePath,
          cssSettings
        );
      } else if (this.selectedItem === "Angular Pipe") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments.reverse().join("/")}`;
        this.checkDuplicateAngularSnippet(fileName, "pipe");
        if (this.duplicateError === true) {
          return;
        }
        this.projectService.addFile(
          `${fileName}.pipe.ts`,
          "typescript",
          filePath,
          fileSettings,
          this.pipeContent()
        );
      } else if (this.selectedItem === "Angular Guard") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments.reverse().join("/")}`;
        this.checkDuplicateAngularSnippet(fileName, "guard");
        if (this.duplicateError === true) {
          return;
        }
        this.projectService.addFile(
          `${fileName}.guard.ts`,
          "typescript",
          filePath,
          fileSettings,
          this.guardContent()
        );
      } else if (this.selectedItem === "Angular Directive") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments.reverse().join("/")}`;
        this.checkDuplicateAngularSnippet(fileName, "directive");
        if (this.duplicateError === true) {
          return;
        }
        this.projectService.addFile(
          `${fileName}.directive.ts`,
          "typescript",
          filePath,
          fileSettings,
          this.directiveContent()
        );
      } else if (this.selectedItem === "Interface") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments.reverse().join("/")}`;
        this.checkDuplicateAngularSnippet(fileName, "interface");
        if (this.duplicateError === true) {
          return;
        }
        let convertedInterfaceRef = this.convertClassName(
          this.snippet.snippetName
        );
        this.projectService.addFile(
          `${fileName}.ts`,
          "typescript",
          filePath,
          fileSettings,
          `export interface ${convertedInterfaceRef} {
          }`
        );
      } else if (this.selectedItem === "Enum") {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments.reverse().join("/")}`;
        this.checkDuplicateAngularSnippet(fileName, "enum");
        if (this.duplicateError === true) {
          return;
        }
        let convertedEnumRef = this.convertClassName(this.snippet.snippetName);
        this.projectService.addFile(
          `${fileName}.enum.ts`,
          "typescript",
          filePath,
          fileSettings,
          `export enum ${convertedEnumRef} {
        }`
        );
      } else {
        this.makePath(this.selectedMenuNode);
        let filePath = `/${this.newFilePathSegments.reverse().join("/")}`;
        this.checkDuplicateAngularSnippet(fileName, "class");
        if (this.duplicateError === true) {
          return;
        }
        let convertedClassRef = this.convertClassName(this.snippet.snippetName);
        this.projectService.addFile(
          `${fileName}.ts`,
          "typescript",
          filePath,
          fileSettings,
          `export class ${convertedClassRef} {
          }`
        );
      }
      setTimeout(() => {
        if (this.projectFilesTree.getControllerByNodeId(this.fileId)) {
          this.projectFilesTree.getControllerByNodeId(this.fileId).select();
        }
        this.triggerSaveBtn.emit(true);
        this.modalRef.hide();
        // this.save.emit();
      });
      this.save.emit();
    }
  }
  fileIndex;
  pathIndex;

  checkDuplicateAngularSnippet(fileName, type) {
    if (type === "interface" || type === "class") {
      this.pathIndex = this.projectService.project["files"].findIndex(
        file => file.path + file.value == this.currentPath + `${fileName}.ts`
      );
      if (this.pathIndex !== -1) {
        this.duplicateError = true;
        this.duplicateErrorMsg = fileName;
        this.duplicateErrorMsg = `A ${type} with name ${fileName} already exists`;
      } else {
        this.duplicateError = false;
        this.duplicateErrorMsg = "";
      }
      return;
    } else if (type === "component" || type === "module") {
      this.pathIndex = this.projectService.project["files"].findIndex(
        file =>
          file.path + file.value ==
          this.currentPath + "/" + `${fileName}` + `${fileName}.${type}.ts`
      );
      if (this.pathIndex !== -1) {
        this.duplicateError = true;
        this.duplicateErrorMsg = fileName;
        this.duplicateErrorMsg = `A ${type} with name ${fileName} already exists`;
      } else {
        this.duplicateError = false;
        this.duplicateErrorMsg = "";
      }
      return;
    } else {
      this.pathIndex = this.projectService.project["files"].findIndex(
        file =>
          file.path + file.value == this.currentPath + `${fileName}.${type}.ts`
      );
      if (this.pathIndex !== -1) {
        this.duplicateError = true;
        this.duplicateErrorMsg = fileName;
        this.duplicateErrorMsg = `A ${type} with name ${fileName} already exists`;
      } else {
        this.duplicateError = false;
        this.duplicateErrorMsg = "";
      }
      return;
    }
  }
  convertClassName(className) {
    let finalClassName: any;
    if (className.includes("-")) {
      let splittedWords = [];
      let modifiedFileName = className.split("-");
      modifiedFileName.forEach(word => {
        splittedWords.push(this.titleCaseWord(word));
      });
      finalClassName = splittedWords.join(",");
      finalClassName = finalClassName.replace(/,/g, "");
    } else {
      finalClassName = this.titleCaseWord(className);
    }
    return finalClassName;
  }
  // angular snippets code ends here
  folderStructure = [];
  //event fires when user creates on file or folder in projectFileTree
  onNodeCreated(event) {
    this.newFilePathSegments = [];
    this.makePath(event.node);
    event.node.node.path = `/${this.newFilePathSegments
      .splice(1)
      .reverse()
      .join("/")}`;

    const projectFiles = this.projectService.project["files"];

    const index = projectFiles.findIndex(
      f => f.path + f.value === event.node.node.path + event.node.node.value
    );
    const id = Math.max(...projectFiles.map(o => o.id), 0) + 1;

    if (index !== -1) {
      this.duplicateError = true;
      this.duplicateErrorMsg = event.node.node.value;
      alert(`${this.duplicateErrorMsg}` + " is already existed");
      // since duplicate file then to reload tree i.e, handled at service
      this.projectService.reloadTreeWithoutAnyUpdate();
      return;
    } else {
      this.duplicateError = false;
    }
    if (event.node.children === null) {
      let extension = event.node.node.value.substring(
        event.node.node.value.lastIndexOf(".") + 1,
        event.node.node.value.length
      );
      //let [, extension, extension1] = event.node.node.value.split(".");

      extension = extension;
      switch (extension) {
        case "ts":
          event.node.node.settings = this.projectService.prepareSettings(
            "typescript"
          );
          event.node.node.settings.templates = this.projectService.getIconByLanguage(
            "typescript"
          );
          event.node.node.language = "typescript";
          break;
        case "json":
          event.node.node.settings = this.projectService.prepareSettings(
            "json"
          );
          event.node.node.settings.templates = this.projectService.getIconByLanguage(
            "json"
          );
          event.node.node.language = "json";
          break;
        case "jsx":
          event.node.node.settings = this.projectService.prepareSettings("jsx");
          event.node.node.language = "jsx";
          event.node.node.settings.templates = this.projectService.getIconByLanguage(
            "jsx"
          );
          break;
        case "js":
          event.node.node.settings = this.projectService.prepareSettings(
            "javascript"
          );
          event.node.node.language = "javascript";
          event.node.node.settings.templates = this.projectService.getIconByLanguage(
            "javascript"
          );
          break;

        case "css":
          event.node.node.settings = this.projectService.prepareSettings("css");
          event.node.node.language = "css";
          event.node.node.settings.templates = this.projectService.getIconByLanguage(
            "css"
          );
          break;

        case "html":
          event.node.node.settings = this.projectService.prepareSettings(
            "html"
          );
          event.node.node.language = "html";
          event.node.node.settings.templates = this.projectService.getIconByLanguage(
            "html"
          );
          break;

        case "java":
          event.node.node.settings = this.projectService.prepareSettings(
            "java"
          );
          event.node.node.language = "java";
          event.node.node.settings.templates = this.projectService.getIconByLanguage(
            "java"
          );
          break;
        default:
          let fileIcon =
            '<img src="../../assets/images/normal-file.svg" height="16" width="16" class="file-type-icon">';
          event.node.node.settings.templates = { leaf: fileIcon };
      }
      // setTimeout(() => {
      this.projectService.addFile(
        event.node.value,
        event.node.node.language,
        event.node.node.path,
        event.node.node.settings,
        null,
        null,
        null,
        null,
        id
      );
      // });
      this.save.emit();
    } else {
      const folderPath = this.projectService.pathJoin(
        [event.node.node.path, event.node.value],
        "/"
      );
      const indexOfPath = projectFiles.findIndex(
        f => f.path + f.value === folderPath + f.value
      );

      if (indexOfPath !== -1) {
        alert("folder already exists");
        this.projectService.reloadTreeWithoutAnyUpdate();
        return;
      } else {
        event.node.value = "empty.empty";
        event.node.node.language = "html";
        event.node.node.path = folderPath;
        event.node.node.settings = this.projectService.prepareSettings("html");
        this.projectService.addFile(
          event.node.value,
          event.node.node.language,
          event.node.node.path,
          event.node.node.settings
        );
        this.save.emit();
      }
    }

    // else{
    // //assuming if its a folder
    //  console.log('its path===>',event.node.node.path)
    //  let index = this.projectService.project["files"].findIndex(
    //   f => f.path == event.node.node.path +'/'+ event.node.node.value
    // );
    // console.log('index===>',index)
    // if (index !== -1) {
    //   this.duplicateError = true;
    //   this.duplicateErrorMsg = event.node.node.value;
    //   alert(`${this.duplicateErrorMsg}` + " is already existed");
    //   this.projectService.addFile(event.node.node.value,'nolanguage',event.node.node.path,event.node.node.settings)
    // } else {
    //   this.duplicateError = false;
    // }
    // }
    try {
      this.projectFilesTree.getControllerByNodeId(event.node.node.id).select();
    } catch {
      this.activeNodeController.select();
    }
    this.triggerSaveBtn.emit(true);
    this.onSave();
  }

  removedItem: any;
  handleRemoved(event, deleteFileAlert) {
    this.removedItem = event;
    // console.log("eventt", event.node.node.settings.menuItems[1].action == 3);
    // if( event.node.node.settings.menuItems[1].action == 3){
    //   deleteFileAlert.show();
    // } else {
    //   this.triggerSaveBtn.emit(true);
    if (event.node.children) {
      setTimeout(() => {
        if (this.isMoved === false) {
          this.projectService.removeFile(event.node);
        }
      }, 300);
    } else {
      this.projectService.removeFile(event.node.node);
    }
    // this.projectService.removeFile(event.node.node);
    // this.onSave();
    // this.onFileMoved.next(true);
    // }
  }

  onDeleteFileConfirm() {
    const treeController = this.projectFilesTree.getControllerByNodeId(
      this.selectedMenuNode.id
    );
    if (treeController && typeof treeController["remove"] === "function") {
      treeController["remove"]();
      if (this.selectedMenuNode.children) {
        setTimeout(() => {
          if (this.isMoved === false) {
            this.projectService.removeFile(this.selectedMenuNode);
          }
        }, 300);
      } else {
        this.projectService.removeFile(this.selectedMenuNode.node);
      }
      // this.projectService.removeFile(this.selectedMenuNode.node);
      this.onSave();
      this.onFileMoved.next(true);
    } else {
      console.log(
        "There isn`t a controller for a node with id - " +
          this.selectedMenuNode.id
      );
    }
  }

  isMoved = false;

  @Output() onFileMoved = new EventEmitter();
  onNodeMoved(event) {
    this.isMoved = true;
    const {
      node: {
        node: { value, language, settings, content, id, path }
      }
    } = this.removedItem;

    const {
      node: {
        parent: {
          node: { path: eventPath }
        }
      }
    } = event;

    this.projectService.addFile(
      value,
      language,
      eventPath,
      settings,
      content,
      id,
      path,
      this.isMoved
    );
    this.triggerSaveBtn.emit(true);
    this.onFileMoved.next(true);
  }

  renameNodeSelectedId: number;
  handleRenamed(event) {
    this.renameNodeSelectedId = event.node.node.id;
    this.triggerSaveBtn.emit(true);
    if (event.node.children == null) {
      if (event.oldValue !== event.newValue) {
        let index = this.projectService.project["files"].findIndex(
          f => f.path + f.value == event.node.node.path + event.node.node.value
        );
        if (index !== -1) {
          this.duplicateErrorMsg = event.node.node.value;
          alert(`${this.duplicateErrorMsg}` + "  is already existed");
          // if(event.newValue === 'Untitled'){
          //  event.oldValue = 'Untitled' + `${(2)}`
          // }
          //here parameters are given same, inorder not to override previous filename and handled in projectservice within if-else
          this.projectService.renameFile(
            event.node.node,
            event.oldValue,
            event.oldValue
          );
        } else {
          this.projectService.renameFile(
            event.node.node,
            event.oldValue,
            event.newValue
          );
        }
        this.save.emit();
        this.onFileMoved.next(true);
      }
    } else if (event.node.children) {
      //if it is a folder
      if (event.oldValue !== event.newValue) {
        console.log("old path===>", event.node.node.path);
        let orgPath = event.node.node.path;
        orgPath = orgPath.replace(event.oldValue, event.newValue);

        event.node.node.path = orgPath;
        let pathIndex = this.projectService.project["files"].findIndex(
          f => f.path == event.node.node.path
        );
        if (pathIndex !== -1) {
          this.duplicateErrorMsg = event.node.node.value;
          alert(`${this.duplicateErrorMsg}` + "  is already existed");
          //here parameters are given same, inorder not to override previous filename and handled in projectservice within if-else
          this.projectService.renameFile(
            event.node,
            event.oldValue,
            event.oldValue
          );
        } else {
          this.projectService.renameFile(
            event.node,
            event.oldValue,
            event.newValue
          );
        }
        this.save.emit();
        this.onFileMoved.next(true);
      }
    }
  }

  newFilePathSegments = [];
  makePath(node) {
    if (node.parent) {
      // if(this.newFilePathSegments.length ===0){
      this.newFilePathSegments.push(node.node.value);
      // } else {
      //   if(this.newFilePathSegments.indexOf(node.node.value) === -1){
      //     this.newFilePathSegments.push(node.node.value);
      //   }
      // }
      this.makePath(node.parent);
    }
  }

  onProjectInfoChanges() {
    this.projectInfoChanges.emit(this.projectInfo);
  }

  slug;
  ProjectExport() {
    this.ngProgress.done();
    this.projectService.exportProject(this.slug).subscribe(
      response => {
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.ngProgress.done();
          this.downLoadFile(response);
        }, 3000);
      },
      err => {
        this.ngProgress.done();
      }
    );
  }

  downLoadFile(data: any) {
    var url = window.URL.createObjectURL(data);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = `${this.slug}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
  }

  editAddress() {
    this.oldSlug = this.input.nativeElement["value"];
    this.input["nativeElement"].autofocus = true;
    if (this.isAddressBar == true) {
      this.isAddressBar = false;
    }
  }

  onUpdateSlugKey(event) {
    if (event.key === "Escape") {
      this.input["nativeElement"]["value"] = this.oldSlug;
      this.makeAddressChange(event);
    }
  }

  onEnterAddress = false;
  onBlurAddress = false;

  onBlurMakeAddressChange(event) {
    this.isAddressBar = true;
    if (!this.onEnterAddress) {
      this.makeAddressChange(event);
    } else {
      this.onEnterAddress = false;
    }
  }

  onEnterMakeAddressChange(event) {
    this.onEnterAddress = true;
    this.makeAddressChange(event);
  }

  onMakeAddressChange(event) {
    if (!this.onEnterAddress) {
      this.makeAddressChange(event);
    } else {
      this.onEnterAddress = false;
    }
  }

  @ViewChild("input")
  input: ElementRef;
  oldSlug: any;

  slugUpdatedEvent = new EventEmitter();
  makeAddressChange(event) {
    let updatedSlug = this.input["nativeElement"]["value"];
    let reg = new RegExp("^[_A-z0-9]*((-|s)*[_A-z0-9])*$");
    if (reg.test(updatedSlug)) {
      if (this.oldSlug == updatedSlug) {
        this.isAddressBar = true;
        return this.oldSlug;
      } else {
        this.ngProgress.start();
        this.isAddressBar = true;
        this.projectService.updateSlug(this.oldSlug, updatedSlug).subscribe(
          response => {
            // if (response["status"] == 200) {
            this.ngProgress.done();
            this.isAddressBar = true;
            this.slug = updatedSlug;
            this.projectType = response["name"];
            let params = this.route.snapshot["queryParams"];
            setTimeout(() => {
              this.router.navigate([`${response["name"]}/${response["id"]}`], {
                queryParams: { file: params["file"] }
              });
              this.projectService.sendUpdateSlugEvent(response); // return this.slug;
            });
          },
          err => {
            this.ngProgress.done();
            this.input["nativeElement"]["value"] = this.oldSlug;
            if (err.error.error.statusCode === 400) {
              if (
                err.error.error.message ===
                "Project with given `newid` already exists."
              ) {
                window.alert(`The project with the given name already exists.`);
              } else {
                window.alert(`${err.error.error.message}`);
              }
            } else {
              window.alert("Something went wrong...");
            }
            // window.alert("Something went wrong");
          }
        );
      }
      this.input["nativeElement"].autofocus = false;
    } else {
      this.input["nativeElement"]["value"] = this.route.snapshot.params.type;
      this.toastr.warning("please check name once which contains(/,;,. )", "", {
        timeOut: 5000,
        toastClass: "vismaya-toastr toast",
        positionClass: "toast-bottom-right"
      });
    }
  }
  nameChange(fileName, event) {
    if (fileName.length !== 0 && event.key !== "Enter") {
      if (this.snippet.snippetName.length !== 0 && this.duplicateError) {
        this.duplicateError = false;
        this.duplicateErrorMsg = "";
      } else if (this.snippet.snippetName.length !== 0 && this.isSpecialChar) {
        this.isSpecialChar = false;
      }
    }
  }

  async onImportFiles(e) {
    this.importedFiles = [];
    // const files = e.target.files;
    const filesList = e.target.files;
    const files = Object.keys(filesList).map(e => {
      const fileObject = filesList[e];
      fileObject.filepath = filesList[e].webkitRelativePath;
      return fileObject;
    });

    this.loadFiles(files, false);
  }

  async loadFiles(files, isFullUpload?) {
    const totalFileLength = await files.filter(
      file =>
        !file.filepath.includes("node_modules") &&
        !file.filepath.includes("/dist")
    );

    this.totalFileLength = totalFileLength.length;

    if (this.totalFileLength === 0) {
      this.bodyElement.classList.remove("dragging");
      this.NoFilesRef = this.modalService.show(this.NoFiles, {
        class: "modal-sm"
      });
      return;
    }

    this.filesUploaded = 0;
    this.fileUploadedStarted = true;
    const project: any = await this.projectService.getProjectData();

    this.importedFiles = [];
    let id = 0;
    if (!isFullUpload) {
      project.files = [];
      id = Math.max.apply(
        Math,
        project.files.map(function(o) {
          return o.id;
        })
      );
    } else {
      id = 0;
      this.projectService.clearProjectFiles();
    }

    // console.log(files);

    // files.sort();
    files.sort((a, b) =>
      a.filepath.localeCompare(b.filepath, undefined, {
        numeric: true,
        sensitivity: "base"
      })
    );

    let fileId = 1000;
    const folderFiles = files
      .filter(file => {
        const { filepath } = file;
        if (filepath.includes("/")) {
          id = id + 1;
          file.id = id;
        }
        return filepath.includes("/");
      })
      .sort((a, b) =>
        a.filepath.localeCompare(b.filepath, undefined, {
          numeric: true,
          sensitivity: "base"
        })
      );

    const noFolderFiles = files
      .filter(file => {
        const { filepath } = file;
        if (!filepath.includes("/")) {
          fileId = fileId + 1;
          file.id = fileId;
        }
        return !filepath.includes("/");
      })
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base"
        })
      );

    const allFiles = folderFiles.concat(noFolderFiles);

    const arrayObject = allFiles.map(async file => {
      const uploadedFilePath = file.filepath.substring(
        0,
        file.filepath.lastIndexOf("/")
      );
      if (
        !uploadedFilePath.includes("node_modules") &&
        !uploadedFilePath.includes("/dist")
      ) {
        await this.traverseImportFiles(file, id, this.currentUploadedPath);

        this.percentageUploaded =
          (Number(this.filesUploaded) / Number(this.totalFileLength)) * 100;
      }
    });

    await arrayObject.reduce((m, o) => m.then(() => o), Promise.resolve());

    project.packages =
      this.fileDropDependencies && this.fileDropDependencies.packages;
    project.versions =
      this.fileDropDependencies && this.fileDropDependencies.versions;
    project.map = this.fileDropDependencies && this.fileDropDependencies.maps;
    project.frontEndSettings =
      this.fileDropDependencies && this.fileDropDependencies.frontEndSettings;
    const projectDeps = [];
    if (this.fileDropDependencies && this.fileDropDependencies.dependencies) {
      await Object.keys(this.fileDropDependencies.dependencies).map(dep => {
        if (project.versions && project.versions[dep]) {
          const newDepObj = {};
          newDepObj[dep] = project.versions[dep];
          projectDeps.push(newDepObj);
        }
      });
    }

    project.dependencies = projectDeps;

    this.installDependencies.emit();

    const importedFIleArray = await Promise.all(this.importedFiles).then(
      translateResults => {
        return translateResults;
      }
    );

    const newProjectFiles = [...importedFIleArray, ...project.files];

    project.files = newProjectFiles;

    this.projectService.setProjectFileTree(project);

    this.filesUploadFinished = false;

    this.fileUploadedStarted = false;

    this.percentageUploaded = 0;

    this.bodyElement.classList.remove("dragging");

    this.reInstallProject.emit();

    this.save.emit();

    this.projectFileTree = this.projectService.getProjectFileTree();
  }

  async traverseImportFiles(file, id, uploadedPath) {
    id = file.id;
    await new Promise((resolve, reject) => {
      const name = file.name;
      const filePath = file.webkitRelativePath || file.filepath;
      const reader: FileReader = new FileReader();
      const angularThis = this;
      reader.onload = async (e: any) => {
        const content = e.target.result;
        const fileObject = {} as ImportedFileObject;
        fileObject.value = name;
        const pathUrl = this.pathJoin([uploadedPath, filePath], "/");
        fileObject.path = pathUrl.substring(0, pathUrl.lastIndexOf("/"));
        fileObject.content = content as String;
        // const dependencies = JSON.parse(content).dependencies;
        if (name === "package.json") {
          const dependencies = JSON.parse(content).dependencies;

          if (dependencies && dependencies["react-scripts"]) {
            delete dependencies["react-scripts"];
          }

          if (dependencies && dependencies["typescript"]) {
            delete dependencies["typescript"];
          }

          if (dependencies) {
            this.fileDropDependencies =
              (await angularThis.projectService.getDevDepFromCdn(
                dependencies
              )) || {};

            this.fileDropDependencies.dependencies = dependencies;
          }
          const parsedContent = JSON.parse(content);
          const frontEndSettings = {} as IFrontEndSettings;
          frontEndSettings.entryPoint =
            parsedContent.frontEndSettings &&
            parsedContent.frontEndSettings.entryPoint;
          frontEndSettings.entryFile =
            parsedContent.frontEndSettings &&
            parsedContent.frontEndSettings.entryFile;

          this.fileDropDependencies.frontEndSettings = frontEndSettings;

          // this.fileDropDependencies.frontEndSettings = {
          //   entryFile: "/src/index.html",
          //   entryPoint: "/src/index.js"
          // };

          const backEndSettings = {} as IBackEndSettings;
          if (parsedContent.backEndSetting) {
            backEndSettings.entryPoint =
              parsedContent.backEndSettings.entryPoint;
            backEndSettings.entryFile = parsedContent.backEndSettings.entryFile;
            backEndSettings.packageInstallPoint = JSON.parse(
              content
            ).backEndSettings.packageInstallPoint;
            backEndSettings.packageRun = "npm start";
          }
          if (frontEndSettings.entryFile) {
            this.router.navigate([], {
              queryParams: {
                file: frontEndSettings.entryFile
              }
            });
          }
        }
        fileObject.language = await this.projectService.getFileLanguage(name);
        fileObject.id = id;
        angularThis.importedFiles.push(fileObject);
        this.filesUploaded = Number(this.filesUploaded) + 1;

        // console.log(fileObject);
        resolve(fileObject);
      };
      reader.readAsText(file, "UTF-8");
    });
  }

  pathJoin(parts, sep) {
    const separator = sep || "/";
    const replace = new RegExp(separator + "{1,}", "g");
    return parts.join(separator).replace(replace, separator);
  }

  async getFilesWebkitDataTransferItems(dataTransferItems) {
    const files = [];
    const angular = this;
    function traverseFileTreePromise(item, path = "") {
      return new Promise(resolve => {
        if (item && item.isFile) {
          item.file(file => {
            file.filepath = path + file.name;
            files.push(file);
            resolve(file);
          });
        } else if (item && item.isDirectory) {
          const dirReader = item.createReader();
          dirReader.readEntries(entries => {
            const entriesPromises = [];
            for (const entr of entries) {
              entriesPromises.push(
                traverseFileTreePromise(entr, path + item.name + "/")
              );
            }
            resolve(Promise.all(entriesPromises));
          });
        } else {
          angular.bodyElement.classList.remove("dragging");
        }
      });
    }
    return new Promise((resolve, reject) => {
      const entriesPromises = [];
      for (const it of dataTransferItems) {
        entriesPromises.push(traverseFileTreePromise(it.webkitGetAsEntry()));
      }
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }
}
