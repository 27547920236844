import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { ProjectService } from "../project/project.service";
import { NgProgress } from "ngx-progressbar";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";
import "firebase/auth";
import { Subject, Observable } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { NgForm } from "@angular/forms";

import { DomSanitizer, Title } from "@angular/platform-browser";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "zl-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  isCollapsed: boolean = false;
  sanitizedUrl: any;
  slug;
  firebaseActiveUser: any = {};
  modalRef: BsModalRef;
  constructor(
    private router: Router,
    private projectService: ProjectService,
    public ngProgress: NgProgress,
    public afAuth: AngularFireAuth,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    title: Title
  ) {
    this.user = this.afAuth.authState;
    this.user.subscribe(async user => {
      let fs = JSON.parse(localStorage.getItem("additional-fu-info"));
      if (fs == null) {
        this.additinalGitUserData = {};
      } else {
        this.additinalGitUserData = fs;
      }
      const localUser = this.projectService.getUserInfo() as any;
      if (user) {
        this.userDetails = user;
        this.firebaseActiveUser["isAnonymous"] = user.isAnonymous;
        this.firebaseActiveUser["uid"] = this.projectService.setOrGetId(user);
        this.firebaseActiveUser["displayName"] = user.displayName
          ? user.displayName
          : "";
        this.firebaseActiveUser["email"] = user.email
          ? user.email
          : this.additinalGitUserData["email"];
        this.firebaseActiveUser["photoURL"] = user.photoURL
          ? user.photoURL
          : "";
        this.firebaseActiveUser["name"] = this.additinalGitUserData["name"]
          ? this.additinalGitUserData["name"]
          : "";
        this.firebaseActiveUser["token"] = this.additinalGitUserData["token"]
          ? this.additinalGitUserData["token"]
          : "";
        // this.firebaseActiveUser["isVismaya"] = true;

        localStorage.setItem(
          "firebase-active-user",
          JSON.stringify(this.firebaseActiveUser)
        );
      } else {
        this.firebaseActiveUser["token"] = "";
        this.firebaseActiveUser["name"] = "";
        if (this.firebaseActiveUser) {
          this.afAuth.auth.signInAnonymously().catch(error => {
            var errorMessage = error.message;
          });
        }
      }
    });
    this.sanitizedUrl = sanitizer.bypassSecurityTrustResourceUrl(
      `${this.getPreviewUrl()}`
    );
  }

  getPreviewUrl() {
    return `https://vismaya.io/javascript/vismaya-intro?file=%2Fsrc%2Findex.js&isEmbedded=1`;
  }

  additinalGitUserData = {};
  ngOnInit() {
    localStorage.removeItem("template-uid");
    let fData = JSON.parse(localStorage.getItem("firebase-active-user"));
    if (fData == null) {
      this.firebaseActiveUser = {};
      this.afAuth.auth.signInAnonymously().catch(error => {
        var errorMessage = error.message;
      });
    } else {
      this.firebaseActiveUser = fData;
    }
  }

  createTemplate(type: string) {
    this.ngProgress.start();
    this.projectService.forkProjectFromTemplate(type).subscribe(r => {
      localStorage.setItem("template-uid", JSON.stringify(r["uid"]));
      this.projectService.setProjectFileTree(r);
      let selectedFileName = "";
      if (r["frontEndSettings"] && r["frontEndSettings"]["entryFile"]) {
        selectedFileName = r["frontEndSettings"]["entryFile"];
      } else if (r["backEndSettings"] && r["backEndSettings"]["entryFile"]) {
        selectedFileName = r["backEndSettings"]["entryFile"];
      } else if (type === "angular") {
        selectedFileName = "/src/app/app.component.ts";
      } else if (type === "javascript") {
        selectedFileName = "/src/index.js";
      } else if (type === "react") {
        selectedFileName = "/src/index.js";
      } else if (type === "html") {
        selectedFileName = "/public/index.html";
      } else if (type === "css") {
        selectedFileName = "/src/style.css";
      } else if (type === "mean") {
        selectedFileName = "/client/src/app/app.component.ts";
      } else {
        selectedFileName = "/src/index.ts";
      }

      this.router.navigate([`${r["name"]}/${r["id"]}`], {
        queryParams: { file: `${selectedFileName}` }
      });
      this.ngProgress.done();
    });
  }

  import = { githubUrl: "" };

  ProjectImport(importGitProject) {
    this.modalRef = this.modalService.show(importGitProject, {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  // importProjectFromUrl(form: NgForm) {
  //   if (form.valid) {
  //     let firebaseUser = JSON.parse(localStorage.getItem("firebase-active-user"));
  //     this.import["uid"] = firebaseUser["uid"];
  //     this.projectService.importProjectByUrl(this.import).subscribe(res => {
  //       this.modalRef.hide();
  //       let params: any = {};
  //       params.file = "/public/index.html";
  //       this.router.navigate(
  //         [`${res["name"]}/${res["id"]}`],
  //         { queryParams: { file: params["file"] } }
  //       );
  //       localStorage.setItem('template-uid', JSON.stringify(res['uid']));
  //       this.projectService.setProjectFileTree(res);
  //       let pt = this.projectService.getProjectFileTree();
  //       pt.ptype = res["name"];
  //       this.projectService.sendNewProjectCreationObject(pt);

  //     }, err => {
  //       console.log(err);
  //     })
  //   }
  // }

  gitHubLogin() {
    this.afAuth.auth
      .signInWithPopup(new auth.GithubAuthProvider())
      .then(res => {
        this.additinalGitUserData["token"] = res.credential["accessToken"];
        this.additinalGitUserData["name"] = res.additionalUserInfo.username;
        this.additinalGitUserData["email"] = res.user.providerData["0"].email;
        this.additinalGitUserData["bio"] =
          res.additionalUserInfo.profile["bio"];
        localStorage.setItem(
          "additional-fu-info",
          JSON.stringify(this.additinalGitUserData)
        );
      });
  }

  gitHubSignOut() {
    this.afAuth.auth.signOut().then(
      () => {
        localStorage.removeItem("firebase-active-user");
        localStorage.removeItem("template-uid");
        localStorage.removeItem("additional-fu-info");
        this.isCollapsed = false;
      },
      error => {
        // An error happened.
        console.log(error);
      }
    );
  }
}
