import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/project/project.service';

@Component({
  selector: "zl-collaborative",
  templateUrl: "./collaborative.component.html",
  styleUrls: ["./collaborative.component.css"],
})
export class CollaborativeComponent implements OnInit {
  isCollapsed = true;
  address: any = {};
  sharedUrl: any;
  @Input()
  projectId;
  @Input()
  isUserOwner;
  @Input()
  isLiveSessionRunning;
  @Input()
  liveOwnerDetails;
  @Input()
  liveSessionUrl;
  @Input()
  liveSessionKey;
  isChatEnabled = false;
  isHideNotifications = false;
  templateUid;
  firebaseActiveUser: any = {};
  allLiveUsers: any = [];
  allChatMessages: any = [];
  userMessage = "";
  constructor(
    private afAuth: AngularFireAuth,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.route.params.subscribe((params) => {
      this.address["slug"] = params.type;
    });
    let fData = JSON.parse(localStorage.getItem("firebase-active-user"));
    if (fData == null) {
      this.firebaseActiveUser = {};
      this.afAuth.auth.signInAnonymously().catch((error) => {
        var errorMessage = error.message;
      });
    } else {
      this.firebaseActiveUser = fData;
      this.templateUid = JSON.parse(localStorage.getItem("template-uid"));
      if (this.isLiveSessionRunning && this.liveSessionKey) {
        this.projectService.getChatMessages({
          slug: this.address.slug,
          user: this.firebaseActiveUser,
          key: this.liveSessionKey,
        });
      }
    }
  }
  @Output()
  onStartLiveSession: EventEmitter<any> = new EventEmitter();
  onStartLive() {
    this.onStartLiveSession.emit();
  }

  @Output()
  onStopLiveSession: EventEmitter<any> = new EventEmitter();
  onStopLive() {
    this.onStopLiveSession.emit();
    this.allLiveUsers = [];
    this.allChatMessages = [];
  }

  ngOnInit() {
    // this.projectService.onOnlineUsers().subscribe((online_users:any) => {
    //   this.allLiveUsers = [];
    //   online_users.map(user => {
    //     const puser = JSON.parse(user);
    //     if(this.liveOwnerDetails && puser['uid'] != this.liveOwnerDetails['uid']) {
    //       this.allLiveUsers.push({...puser, color: this.getRandomColor()});
    //     }
    //   });
    // });
    // this.projectService.getMessagesFromLiveUsers().subscribe((messages:any) => {
    //   const allChatMessages = [];
    //   let previous;
    //   let gms = [];
    //   messages.forEach(am => {
    //     const message = JSON.parse(am);
    //     if(previous && previous.uid != message.user.uid) {
    //       allChatMessages.push({user: previous, leftSide: this.firebaseActiveUser.uid!=previous.uid, messages: gms});
    //       previous = message.user;
    //       gms = [];
    //     } else {
    //       previous = message.user;
    //     }
    //     gms.push({message: message.message, date: message.date});
    //   });
    //   if(gms.length > 0) {
    //     allChatMessages.push({user: previous, leftSide: this.firebaseActiveUser.uid!=previous.uid, messages: gms});
    //   }
    //   this.allChatMessages = allChatMessages;
    // });
  }
  ngAfterViewInit() {
    console.log({ isUserOwner: this.isUserOwner });
  }
  // addMessage(message){

  // }

  getRandomColor() {
    return (
      "hsl(" +
      360 * Math.random() +
      "," +
      (25 + 70 * Math.random()) +
      "%," +
      (85 + 10 * Math.random()) +
      "%)"
    );
  }

  onChatEnabled() {
    this.isChatEnabled = !this.isChatEnabled;
  }

  onHideNotifications() {
    this.isHideNotifications = !this.isHideNotifications;
  }

  onMessageSend() {
    let slug = this.address.slug;
    if (this.userMessage.length > 0) {
      this.projectService.sendMessagesToLiveUsers(
        slug,
        this.firebaseActiveUser,
        this.userMessage,
        this.liveSessionKey
      );
      this.userMessage = "";
      document.getElementById("userMessage").scrollIntoView();
    }
  }

  copyClipBoard(value) {
    // debugger;
    value.select();
    document.execCommand("copy");
    value.setSelectionRange(0, 0);
    this.toastr.success("Copied successfully", "", {
      timeOut: 5000,
      toastClass: "vismaya-toastr toast",
      positionClass: "toast-bottom-right",
    });
  }

  myOwnStyles() {
    let styles = {
      "font-weight": "600",
      "margin-bottom": "0.25rem",
      "margin-top": "0.5rem",
    };
    return styles;
  }
}
