import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "zl-side-navigation",
  templateUrl: "./side-navigation.component.html",
  styleUrls: ["./side-navigation.component.css"]
})
export class SideNavigationComponent implements OnInit {
  cancelButtonClass = "btn btn-light text-bold mr-3 btn-min-w";
  constructor() {}
  isFileToggle: boolean = false;
  isSettingsToggle: boolean = false;
  isActive: boolean = false;
  isSearchToggle: boolean = false;
  isFilesControl = true;
  isSettings = false;
  isCollaborative = false;
  isSearch = false;
  isProjectFromGitHub = false
  height = window.screen.height;
  @Output()
  filesToggle: EventEmitter<any> = new EventEmitter();
  @Input() isEmbedded;
  @Input() isLive;
  @Input() isUserOwner;
  enableSave = false;
  @Input()
  set isSaveBtnEnabled(data) {
    this.enableSave = data;
  }

  getControl(event, type, navigateAlert) {
    if (type == "file") {
      this.isFilesControl = true;
      this.isSearch = false;
      this.isSettings = false;
      this.isCollaborative = false;
      this.isProjectFromGitHub = false;
    } else if (type == "search") {
      this.isSearch = true;
      this.isFilesControl = false;
      this.isSettings = false;
      this.isCollaborative = false;
      this.isProjectFromGitHub = false;
    } else if (type == "gitHubImport") {
      if(this.enableSave){
        setTimeout(()=>{
          navigateAlert.show();
        })
      } else {
        this.isProjectFromGitHub = true;
        this.isSearch = false;
        this.isCollaborative = false;
        this.isFilesControl = false;
        this.isSettings = false;
      }
    } else if(type === 'collaborative') {
      this.isSettings = false;
      this.isFilesControl = false;
      this.isCollaborative = true;
      this.isSearch = false;
      this.isProjectFromGitHub = false;
    } else {
      this.isSettings = false;
      this.isFilesControl = false;
      this.isSearch = false;
      this.isProjectFromGitHub = false;
    }
    if(!this.enableSave && type == "gitHubImport"){
    this.filesToggle.emit(type);
    } else {
      if(type != "gitHubImport"){
      this.filesToggle.emit(type);
      }
    }
  }

  onConfirm(){
    this.isProjectFromGitHub = true;
    this.isSearch = false;
    this.isFilesControl = false;
    this.isSettings = false;
    this.filesToggle.emit("gitHubImport");
  }

  ngOnInit() {}
}
